import React from 'react'
import { Container, Row, Col, Visible, Hidden } from 'react-grid-system';
import UILIB from '../../../common-objects/Lib'
import bgImage from '../../../assetts/images/frontPage/bg6.jpg'
import axios from '../../../includes/axios';
import Utils from '../../../config/utils';
import { getFormData } from './defaults';
import moment from 'moment';

export default class CancelEngineer extends React.Component {
    constructor(props) {
        super(props);

        this.state = getFormData(this.props);
        this.getWork = this.getWork.bind(this);
        this.updateField = this.updateField.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.selectJob = this.selectJob.bind(this);
    }

    componentDidMount() {
        var newState = this.state;
        newState.formData.emailAddress = this.props.account.emailAddress;
        newState.formData.yourName = this.props.account.firstName + " " + this.props.account.lastName;
        newState.formData.companyName = this.props.currCustomer.Name;
        this.setState(newState, this.getWork);
    }

    getWork() {
        axios.get(`/work?unComplete=true&includeEquipment=true`).then(_work => {
            this.setState({ work: _work.data.workUnits, pageLoaded: true })
        })
    }

    updateField(event) {
        var state = this.state;
        var value = event.target.value;
        if (event.target.type == "checkbox") {
            value = event.target.checked;
        }
        state.formData[event.target.name] = value;
        this.setState(state)
    }

    selectJob(jobId) {
        var formData = this.state.formData;
        formData.selectedJobId = jobId;
        this.setState({ formData })
    }


    submitForm() {
        var failed = false;
        var errorData = {
            yourName: "",
            emailAddress: "",
            contactNumber: "",
            companyName: "",
            department: "",
            generalError: ""
        }


        if (this.state.formData.yourName.length < 5) {
            errorData.yourName = "Please enter a valid name";
            failed = true;
        }
        if (this.state.formData.emailAddress.length < 5 || !this.state.formData.emailAddress.indexOf('@') || !this.state.formData.emailAddress.indexOf('.')) {
            errorData.emailAddress = "Please enter a valid email address";
            failed = true;
        }
        if (this.state.formData.contactNumber.length < 5) {
            errorData.contactNumber = "Please enter a valid number";
            failed = true;
        }
        if (this.state.formData.companyName.length < 3) {
            errorData.companyName = "Please enter name";
            failed = true;
        }

        if (this.state.formData.telephoneNo && this.state.formData.telephoneNo.length < 3) {
            errorData.telephoneNo = "Please enter a valid telephone number";
            failed = true;
        }

        if (failed) {
            errorData.generalError = "Required field(s) missing. Please check your entries above";
        }

        if (failed) {
            this.setState({ errorData: errorData })
            return
        }

        this.setState({ errorData: errorData, submittingForm: true, submittedForm: false })
        var self = this;
        var ourWork = this.state.work.find(wI => wI.id == this.state.formData.selectedJobId)
        var formData = JSON.parse(JSON.stringify(this.state.formData))
        formData.deviceModel = ourWork.Description;
        formData.serialNumber = ourWork.SerialNumber;
        formData.referenceNo = ourWork.Reference;

        axios.post(`/utilities/cancelEngineer`, formData).then(_result => {
            this.setState({ 
              ...getFormData(this.props), 
              submittingForm: false, 
              submittedForm: true, 
              pageLoaded: true })
        }).catch(err => {
            var theState = self.state;
            theState.errorData.generalError = "There was an issue submitting the form, please try again"
            theState.submittingForm = false
            self.setState({ theState })
        })
    }


    render() {
        if (!this.state.pageLoaded || this.state.submittingForm) return <div style={{ textAlign: "center" }}><UILIB.LoadingIcon /></div>

        var hideAsset = true;
        {
            this.state.work.forEach(work => {
                if (work.AssetNumber) hideAsset = false;
            })
        }
        return <div>
            <Row className="mar-t15">
                <Col xs={12} sm={12} md={12} lg={12} style={{ marginBottom: "20px" }}>
                    <div className="overViewHeader" style={{ backgroundImage: `url(${bgImage})` }}>
                        <div className="overViewHeaderText">
                            <h1>Cancel an Engineer</h1>
                            If you are have an engineer visit booked and would like to cancel it, please complete the following form...
                        </div>
                    </div>
                </Col>
            </Row>
            {this.state.errorData.generalError &&
                <Col xs={12} style={{ marginBottom: "20px" }}>
                    <div style={{
                        backgroundColor: "red",
                        color: "white",
                        padding: "10px",
                        textAlign: "center"
                    }}>
                        {this.state.errorData.generalError}
                    </div>
                </Col>
            }


            {this.state.submittedForm && <Row className="mar-t15">
                <Col xs={12}>
                    <div className="paper">
                        <h2>Request Submitted</h2>
                        <p>Thank you for submitting your Request.</p>
                        <p>A member of staff should be back in contact with you on the contact details you provided shortly.</p>
                        <UILIB.Button value="Continue" onClick={() => { this.setState({ submittedForm: false }) }} />
                    </div>
                </Col>
            </Row>
            }
            {
                (!this.state.submittingForm && !this.state.submittedForm) && <Row className="mar-t15">
                    <Col xs={12}>
                        <div className="paper">
                            <h2>Your Contact Details</h2>
                            <Row>
                                <Col xs={12} sm={6} md={4}>
                                    <label>Your Name</label>
                                    <UILIB.Input type="text" name="yourName" value={this.state.formData.yourName} onChange={this.updateField} error={this.state.errorData.yourName} />
                                </Col>
                                <Col xs={12} sm={6} md={4}>
                                    <label>Your Email</label>
                                    <UILIB.Input type="email" name="emailAddress" value={this.state.formData.emailAddress} onChange={this.updateField} error={this.state.errorData.emailAddress} />
                                </Col>
                                <Col xs={12} sm={6} md={4}>
                                    <label>Contact Number</label>
                                    <UILIB.Input type="text" name="contactNumber" value={this.state.formData.contactNumber} onChange={this.updateField} error={this.state.errorData.contactNumber} />
                                </Col>
                                <Col xs={12} sm={6} md={4}>
                                    <label>Company Name</label>
                                    <UILIB.Input type="text" name="companyName" value={this.state.formData.companyName} onChange={this.updateField} error={this.state.errorData.companyName} />
                                </Col>
                                <Col xs={12} sm={6} md={4}>
                                    <label>Department</label>
                                    <UILIB.Input type="text" name="department" value={this.state.formData.department} onChange={this.updateField} error={this.state.errorData.department} />
                                </Col>
                            </Row>
                        </div>
                    </Col>

                    <Col xs={12}>
                        <div className="paper">
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <h2>Open Jobs</h2>
                            </div>
                            <div className="standardTable">
                                <table width="100%" cellPadding="10" cellSpacing="0">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>
                                                Ref No
                                            </th>
                                            <th>
                                                Your Ref
                                            </th>
                                            <th>
                                                Serial
                                            </th>
                                            {!hideAsset && <th>
                                                Asset No
                                            </th>}
                                            <th >
                                                Location
                                            </th>
                                            <th >
                                                Date
                                            </th>

                                        </tr>
                                    </thead>
                                    {(this.state.work.length <= 0) && <tr><td colSpan="6" align="center">
                                        You currently have no jobs scheduled
                                        </td></tr>}
                                    {(this.state.work.length > 0) && this.state.work.map((workItem, index) => {

                                        return <tr key={"eq_" + index}  >
                                            <td valign="top" width="10" align="center">
                                                {(this.state.formData.selectedJobId == workItem.id) ? <span className="icon-check" /> : <UILIB.Button value="Select" onClick={() => { this.selectJob(workItem.id) }} />}
                                            </td>
                                            <td valign="top">
                                                {workItem.Reference}
                                            </td>
                                            <td valign="top">
                                                {workItem.ProjectReference}
                                            </td>
                                            <td valign="top">
                                                {workItem.SerialNumber}
                                            </td>
                                            {!hideAsset && <td valign="top">
                                                {workItem.AssetNumber}
                                            </td>}
                                            <td valign="top">
                                                {workItem.Location}
                                            </td>
                                            <td valign="top">
                                                {Utils.formatDateTime(workItem.LoggedDate, 2)}
                                            </td>
                                        </tr>
                                    })
                                    }
                                </table>
                            </div>
                        </div>
                    </Col>
                    {this.state.errorData.generalError && <Col xs={12}>
                        <div className="errorTextHolder mar-b15">{this.state.errorData.generalError}</div>
                    </Col>}
                    <Col xs={12}>
                        <UILIB.Button value="Submit Request" onClick={this.submitForm} />
                    </Col>

                </Row>
            }
        </div >

    }
}
