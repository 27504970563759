import React from 'react'

export default class ProgressBar extends React.Component {
    constructor(props) {
        super(props);

    }

    componentDidMount() {

    }

    render() {

        var outerStyle = {
            height: "20px",
            width: "100%",
            backgroundColor: "#EEEEEE",
            border: "1px solid #CCCCCC",
            boxSizing: "border-box"
        }

        var innerStyle = {
            width: "0%",
            height: "100%",
            backgroundColor: "green",
            boxSizing: "border-box"
        }

        var theWidth = 0;
        if (this.props.max && this.props.value) {
            theWidth = (this.props.max / 100) * this.props.value;
            innerStyle.width = theWidth + "%"
        }
        if (this.props.color) {
            innerStyle.backgroundColor = this.props.color;
        }

        if (this.props.outerStyle) {
            outerStyle = { ...outerStyle, ...this.props.outerStyle }
        }
        if (this.props.innerStyle) {
            innerStyle = { ...innerStyle, ...this.props.innerStyle }
        }



        return <div style={outerStyle} alt={this.props.alt} title={this.props.alt}>
            <div style={innerStyle} />
        </div>


    }
}
