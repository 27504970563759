


import React, { Component } from 'react';

class Toggle extends Component {
    constructor(props) {
        super(props);
        let timer;
        this.state = {
        }

    }




    render() {

        var props = JSON.parse(JSON.stringify(this.props));
        var outerStyle = {};
        if (props.style) {
            outerStyle = JSON.parse(JSON.stringify(props.style));
            props.style = undefined;
        }



        return (
            <label className="switch" style={outerStyle}>
                <input type="checkbox" name={this.props.name} id={this.props.id} checked={this.props.checked} onChange={this.props.onChange} disabled={this.props.disabled ?? false} />
                <span className="slider round"></span>
                {!!this.props.label && <div className="label" style={{ paddingTop: 4 }}>{this.props.label}</div>}
            </label>
        )
    }
}

export default Toggle;