import React from 'react';
import { Row, Col, Hidden } from 'react-grid-system';
import WhiteLogo from '../../assetts/images/logos/logoWhite.png'
import UILIB from '../../common-objects/Lib'
import SessionHelpers from '../../session/index'
import Config from '../../config/connections'
import GroupLogo from '../../assetts/images/logos/agilicoWhiteLogo.png';

export default class EventTemplate extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            actAs: false,
            actAsAdmin: false,
            toggleAccountMenu: false,
            currSelected: 0,
            logoSrc: WhiteLogo
        }
        this.goChangePage = this.goChangePage.bind(this);
        this.toggleAccountMenu = this.toggleAccountMenu.bind(this);
        this.changeCustomer = this.changeCustomer.bind(this);
        this.updateNav = this.updateNav.bind(this);
        this.checkForLogo = this.checkForLogo.bind(this);
    }


    componentDidMount() {
        if(this.props.account.actAs) {
          const isPortalAdmin = this.props.account?.adUser?.groups?.includes('Customer Portal Admin');
          const actAs = this.props.account.actAs;
          this.setState({ ...this.state, actAs: actAs, actAsAdmin: Boolean(actAs & isPortalAdmin) });
        }
        this.checkForLogo();
    }

    checkForLogo() {
        if (!this.props.currPlace || !this.props.currPlace.user_def9) {
            this.updateNav();
            return;
        }
        this.setState({ logoSrc: Config.apiUrl + `/assets/placeImage/` + this.props.currPlace.user_def9 }, this.updateNav());
    }
    goChangePage(url) {
        this.props.history.push(url)
        this.updateNav();
    }

    updateNav() {
        var currSelected = this.state.currSelected;
        if (this.props.history.location.pathname) {
            var currPath = this.props.history.location.pathname;
            if (currPath.indexOf('/overview') > -1) {
                currSelected = 0;
            }
            if (currPath.indexOf('/dashboard') > -1) {
                currSelected = 1;
            }
            if (currPath.indexOf('/support') > -1) {
                currSelected = 2;
            }
            if (currPath.indexOf('/guides') > -1) {
                currSelected = 3;
            }
            if (currPath.indexOf('/kpax') > -1) {
                currSelected = 4;
            }
            if (currPath.indexOf('/support/downloads') > -1) {
                currSelected = 5;
            }
            if (currPath.indexOf('/telecomsandit') > -1) {
                currSelected = 6;
            }

        }
        this.setState({ currSelected })
    }

    toggleAccountMenu() {
        var toggleAccountMenu = this.state.toggleAccountMenu;
        if (toggleAccountMenu == true) {
            toggleAccountMenu = false;
        } else {
            toggleAccountMenu = true;
        }
        this.setState({ toggleAccountMenu: toggleAccountMenu })
    }

    changeCustomer(ev) {
        this.props.changeCustomer(ev.target.value)
    }

    render() {

        var hStyle = {};

        var hasDashboard = false;
        var hasGuides = false;
        var hasSupport = true;
        var hasKpax = false;

        const canViewAdmin = (Boolean(this.state.actAsAdmin || (this.props.account.isAdmin & !this.state.actAs)));
        const canViewProfile = (Boolean(this.state.actAsAdmin || !this.state.actAs));

        if (this.props.account.permissions.dashboard) hasDashboard = true;
        if (this.props.account.permissions.guides) hasGuides = true;
        if (this.props.account.permissions.kpax) hasKpax = true;


        if (this.props.account.isAdmin && Number(this.props.account.isAdmin) == 1) {
            hasDashboard = true;
            hasGuides = true;
            hasKpax = true;
        }

        return <div>
            <Row align="center" className="headerHolder" style={hStyle}>
                <Col xs={12} sm={6}>
                    <Hidden xs>
                        {(this.props.currCustomer.ServiceRegionId != "2") && <table width="100%" cellPadding="0" cellSpacing="0">
                            <tr>
                                <td>
                                    <img src={GroupLogo} style={{ width: "100%", maxWidth: "130px" }} />
                                </td>

                            </tr>
                        </table>}
                        {(this.props.currCustomer.ServiceRegionId == "2") && <table width="100%" cellPadding="0" cellSpacing="0">
                            <tr>
                                <td>

                                    <img src={CbcLogo} style={{ width: "100%", maxWidth: "60px" }} />
                                </td>

                            </tr>
                        </table>}
                    </Hidden>
                </Col>
                <Col xs={12} sm={6}>
                    <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>

                        <div className="placeHolder" style={{ display: "block" }}>
                            <div style={{ fontSize: "12px", color: "white", paddingLeft: "9px" }}>Group Account</div>
                            <UILIB.Select data={this.props.customers.map(customer => {
                                let customerAddress = customer.Address1 ? customer.Address1 + "," : "";
                                customerAddress += customer.Town ? customer.Town + "," : "";
                                customerAddress += customer.postCode ? customer.postCode + "," : "";

                                return {
                                    label: `${customer.AltName}`, value: customer.id
                                }
                            })} value={this.props.currCustomer.id} onChange={this.changeCustomer} />
                        </div>

                        <div className="accountHolder" onClick={this.toggleAccountMenu}>
                            {this.props.account.firstName + " " + this.props.account.lastName}
                            <div className="closeButton">
                                <div className="icon icon-chevron-down" />
                            </div>
                            {this.state.toggleAccountMenu && <div className="popAccountMenu">
                                {Boolean(canViewAdmin) && <div><a onClick={() => { this.goChangePage("/admin") }}>Admin Area</a></div>}
                                {Boolean(canViewProfile) && <div><a onClick={() => { this.goChangePage("/account") }}>My Profile</a></div>}
                                <div><a onClick={() => { SessionHelpers.logout(sessionStorage.getItem("customerContext")) }}>Logout</a></div>
                            </div>}
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
              {(this.props.account.migrationStatusId === 2) 
                && <UILIB.MigrationContainer account={this.props.account} />}
            </Row>
            <Row>
                <Col xs={12} className="headerNav">
                    <ul>
                        <li className={this.state.currSelected == 0 ? "selected" : ""} onClick={() => { this.goChangePage('/overview') }}>Overview</li>
                        {hasSupport && <li className={this.state.currSelected == 2 ? "selected" : ""} onClick={() => { this.goChangePage('/support') }}>Support</li>}
                        {hasDashboard && <li className={this.state.currSelected == 1 ? "selected" : ""} onClick={() => { this.goChangePage('/dashboard') }}>Dashboard</li>}
                        {hasGuides && <li className={this.state.currSelected == 3 ? "selected" : ""} onClick={() => { this.goChangePage('/guides') }}>Guides</li>}
                        {hasKpax && <li className={this.state.currSelected == 4 ? "selected" : ""} onClick={() => { this.goChangePage('/kpax') }}>Fleet Management</li>}
                        <li className={this.state.currSelected == 6 ? "selected" : ""} onClick={() => { this.goChangePage('/telecomsandit') }}>Telecoms &amp; IT</li>
                        <li className={this.state.currSelected == 5 ? "selected" : ""} onClick={() => { this.goChangePage('/support/downloads') }}>Downloads</li>
                    </ul>
                </Col>
            </Row>
        </div >
    }
}