import React from 'react'
import { Container, Row, Col, Visible, Hidden } from 'react-grid-system';
import UILIB from '../../../../common-objects/Lib'
import axios from '../../../../includes/axios';
import Utils from '../../../../config/utils';

export default class ConsumablesOnOrder extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            tableData: [],
            pageLoaded: false,
            subSites: [],
            subsiteFilter: -1
        }

        this.getData = this.getData.bind(this)
    }

    componentWillMount() {
        var subSites = this.props.subSites.map(site => {
            return { value: site.id, label: site.altName }
        })

        subSites.unshift({ value: -1, label: "All Sites" })
        this.setState({ subSites }, this.getData());
    }

    getData() {
        axios.get(`/consumable?daysSinceToday=90&getOrderDetails=1&onlyAwaitingShipped=true`).then(_response => {
            this.setState({ tableData: _response.data.consumables, pageLoaded: true })
        })
    }

    render() {
        if (!this.state.pageLoaded) return <div style={{ textAlign: "center" }}><UILIB.LoadingIcon /></div>
        return <div className="paper">

            <div className="standardTable">
                <table width="100%" cellPadding="10" cellSpacing="0">
                    <thead>
                        <tr>
                            <th>Serial</th>
                            <th>Asset No</th>
                            <th>Device Name</th>
                            <th>Location</th>
                            <th>Date Ordered</th>
                            <th>Order Number</th>
                            <th>Description</th>
                        </tr>
                    </thead>
                    {this.state.tableData.map((item, index) => {



                        return <tr key={"tr_" + index}>

                            <td>{item.SerialNumber}</td>
                            <td>{item.AssetNumber}</td>
                            <td>{item.Description}</td>
                            <td>{item.Location}</td>
                            <td>{Utils.formatDateTime(item.CreatedDate)}</td>
                            <td>{item.Reference}</td>
                            <td>{item.OrderNotes}</td>

                        </tr>
                    })}
                </table>
            </div>
        </div>
    }
}
