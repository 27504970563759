import React from 'react'
import { Container, Row, Col, Visible, Hidden } from 'react-grid-system';
import UILIB from '../../common-objects/Lib'
import bgImage from '../../assetts/images/frontPage/bg6.jpg'
export default class Support extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            subPages: [{
                icon: "icon-wrench",
                title: "Open a Support Ticket",
                desc: "Having problems? Get in direct contact with the service team.",
                link: "/support/requestengineer",
                perm: "requestEngineer"
            }, {
                icon: "icon-map-marker-crossed",
                title: "Cancel an Engineer",
                desc: "Need to cancel an engineer visit?",
                link: "/support/cancelengineer",
                perm: "cancelEngineer"
            }, {
                icon: "icon-printer",
                title: "Request Consumables (Toner)",
                desc: "Need a top-up? Order consumables including toner, ink and other parts here.",
                link: "/support/requestconsumables",
                perm: "requestConsumables"
            }, {
                icon: "icon-recycle",
                title: "Request Recycling Box",
                desc: "Need a recycling box? Order one here...",
                link: "/support/requestrecycling",
                perm: "requestRecycling"
            }, {
                icon: "icon-bubble-question",
                title: "Request other information",
                desc: "Arrange an account manager visit, a reprinted invoice, and much more.",
                link: "/support/requestinformation",
                perm: "ANY"
            }, {
                icon: "icon-history",
                title: "Service History",
                desc: "View your Agilico Service history, including engineer visits, consumable despatches and more...",
                link: "/support/servicehistory",
                disabled: false,
                perm: "serviceHistory"
            },
            {
                icon: "icon-wrench",
                title: "Machine Move Request",
                desc: "Submit a request to have one of your devices moved from one location to another",
                link: "/support/machinemoverequest",
                disabled: false,
                perm: "machineMoveRequest"
            },
            // {
            //     icon: "icon-cash-pound",
            //     title: "Standard Rate Card",
            //     desc: "Download the current Standard Rate Card.",
            //     link: "https://portalarchive.dmcplc.co.uk/support/dmcStandardRateCard.1.1.pdf",
            //     external: true,
            //     perm: "rateCard"
            // },
            {
                icon: "icon-bubble-dots",
                title: "Suggestions",
                desc: "Have an idea for your customer portal, want to see something added that's not here at the moment? Let us know here...",
                link: "/support/suggestions",
                perm: "ANY"
            },
            {
                icon: "icon-wrench",
                title: "Downloads",
                desc: "Guides, Manuals, useful tools and more...",
                link: "/support/downloads",
                disabled: false,
                perm: "ANY"
            }
            ]
        }

        this.changePage = this.changePage.bind(this);
    }

    changePage(subPage) {
        if (subPage.external) {
            window.open(subPage.link, '_blank');
        }
        else {
            this.props.history.push(subPage.link)
        }
    }
    render() {

        return <Row className="mar-t15">
            <Col xs={12} sm={12} md={12} lg={12} style={{ marginBottom: "20px" }}>
                <div className="overViewHeader" style={{ backgroundImage: `url(${bgImage})` }}>
                    <div className="overViewHeaderText">
                        <h1>Support</h1>
                        Use the following options to get support for your products, reach out for help or check your service history.
                    </div>
                </div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={9}>
                <Row>
                    {this.state.subPages.map((subPage, index) => {
                        if (this.props.account.isAdmin != 1 && subPage.perm && subPage.perm != 'ANY' && (this.props.account.permissions[subPage.perm] != 1 || !this.props.account.permissions[subPage.perm])) {
                            return;
                        }
                        return <Col xs={12} sm={12} md={12} lg={6}>
                            <div className="paper" key={"subPage_" + index} style={{ minHeight: "130px" }}>
                                <Row style={{ marginBottom: "-15px" }}>
                                    <Col xs={12} sm={12} md={1} lg={1} className="text-center mar-b15">
                                        <div className={"icon " + subPage.icon} style={{ fontSize: "30px" }} />
                                    </Col>
                                    <Col xs={12} sm={12} md={7} lg={6} className="mar-b15">
                                        <div style={{ fontWeight: "bold", marginBottom: "10px" }}>
                                            {(subPage.disabled) && subPage.title}
                                            {(!subPage.disabled) && <a onClick={() => { this.changePage(subPage) }}>{subPage.title}</a>}
                                        </div>
                                        <div>{subPage.desc}</div>
                                    </Col>
                                    <Col xs={12} sm={12} md={4} lg={5} className="text-right">
                                        <UILIB.Button value="Click Here" onClick={() => { this.changePage(subPage) }} disabled={subPage.disabled} className={subPage.disabled ? "disabled" : ""} />
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    })}
                </Row>

            </Col>
            <Col xs={12} sm={12} md={12} lg={3}>
                <div className="paper secondary">
                    <h2>Welcome to Service</h2>
                    <p>As a Agilico Group Customer you can use this area to get in direct contact with the award winning service team, access your service account, order consumables and much more.</p>
                    <p>Please use the options located on this page get started.</p>
                </div>
            </Col>

        </Row>
    }
}
