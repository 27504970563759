import React, { Component } from 'react';

class Button extends Component {
    constructor(props) {
        super(props);
        let timer;
        this.state = {
        }

    }


    render() {

        var className = "button";
        if (this.props.disabled) className += " disabled"
        if (this.props.className) className += " " + this.props.className

        return <input type="button" {...this.props} className={className} />


    }
}

export default Button;