import React from 'react'
import { Container, Row, Col, Visible, Hidden } from 'react-grid-system';
import UILIB from '../../common-objects/Lib'
import Axios from '../../includes/axios';
import Utils from '../../config/utils'
import moment from 'moment';

import Icon1 from '../../assetts/images/kpax/icon1.png';
import Icon2 from '../../assetts/images/kpax/icon2.png';
import Icon3 from '../../assetts/images/kpax/icon3.png';
import Icon4 from '../../assetts/images/kpax/icon4.png';
import Icon5 from '../../assetts/images/kpax/icon5.png';
import Icon6 from '../../assetts/images/kpax/icon6.png';
export default class KpaxSignup extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

        }
    }

    componentDidMount() {

    }

    render() {

        return <Row style={{ marginTop: "15px" }}>
            <Col xs={12}>
                <div className="paper">
                    <h2>What is it?</h2>

                    KPAX delivers complete visibility over your Print Network.
                    <br /><br />
                    Simply install the software on any networked computer and KPAX will automatically detect all of your network print devices and start reporting on how much they are being used, who is using them and how much they are costing.
                    <br /><br />
                    And on top of this, KPAX will even automatically re-order toner for you before you get low, send in your meter readings, and much, much more...

                </div>
            </Col>
            <Col xs={12} sm={8}>
                <div className="paper">
                    <h2>KPAX Features</h2>

                    <Row className="mar-b15">
                        <Col xs={12} sm={12} style={{ display: "flex" }}>
                            <img src={Icon1} style={{ width: "100%", maxWidth: "100px", marginRight: "20px" }} />
                            <div style={{ width: "100%" }}>
                                <div style={{ fontWeight: "bold" }}>Automatic Consumable Replenishment</div>
                                Never worry about running out of toner, ink, etc again. KPAX can automatically let Agilico Group know when you are getting low so we can send replenishments.
                            </div>
                        </Col>
                    </Row>
                    <Row className="mar-b15">
                        <Col xs={12} sm={12} style={{ display: "flex" }}>
                            <img src={Icon2} style={{ width: "100%", maxWidth: "100px", marginRight: "20px" }} />
                            <div style={{ width: "100%" }}>
                                <div style={{ fontWeight: "bold" }}>Auto Meter Readings</div>
                                No need to phone or email in your meter readings any more. KPAX will automatically collect and send your readings each month.
                            </div>
                        </Col>

                    </Row>
                    <Row className="mar-b15">
                        <Col xs={12} sm={12} style={{ display: "flex" }}>
                            <img src={Icon3} style={{ width: "100%", maxWidth: "100px", marginRight: "20px" }} />
                            <div style={{ width: "100%" }}>
                                <div style={{ fontWeight: "bold" }}>Real-time costing and reports</div>
                                Find out exactly which printers are being used, which aren't and how much it's all costing. Plus get advice steps you can take to reduce your print costs.
                            </div>
                        </Col>

                    </Row>
                    <Row className="mar-b15">
                        <Col xs={12} sm={12} style={{ display: "flex" }}>
                            <img src={Icon4} style={{ width: "100%", maxWidth: "100px", marginRight: "20px" }} />
                            <div style={{ width: "100%" }}>
                                <div style={{ fontWeight: "bold" }}>Alerts and Monitoring</div>
                                Setup alerts customised to your needs. Be informed as soon as your printers produce an error, from low toner alerts to full blown device errors.
                            </div>
                        </Col>

                    </Row>
                    <Row className="mar-b15">
                        <Col xs={12} sm={12} style={{ display: "flex" }}>
                            <img src={Icon5} style={{ width: "100%", maxWidth: "100px", marginRight: "20px" }} />
                            <div style={{ width: "100%" }}>
                                <div style={{ fontWeight: "bold" }}>Cloud Interface</div>
                                KPAX is hosted on the cloud, which means you can get access to it's easy to use interface whenever, wherever!
                            </div>
                        </Col>

                    </Row>
                    <Row className="mar-b15">
                        <Col xs={12} sm={12} style={{ display: "flex" }}>
                            <img src={Icon6} style={{ width: "100%", maxWidth: "100px", marginRight: "20px" }} />
                            <div style={{ width: "100%" }}>
                                <div style={{ fontWeight: "bold" }}>And lots more...</div>
                                KPAX will change the way you think about and use your print devices. Get started today, it's free for Agilico Group customers!
                                KPAX delivers complete visibility over your Print Network.
                            </div>
                        </Col>

                    </Row>
                </div>
            </Col>
            <Col xs={12} sm={4}>
                <div className="paper primary">
                    <h1>Get started - It's FREE</h1>
                    As a Agilico Group Customer you can use KPAX at no cost.<br /><br />
                    Simply enter your details and we will send you an email with instructions on how to get setup.
                    <br /><br />
                    <UILIB.Button value="Book your Install" onClick={() => { window.open('https://www.agilico.co.uk/kpax/', '_blank') }} />
                </div>
            </Col>

        </Row>


    }
}
