/*TABLES*/
import DataTable1 from './Tables/DataTable1/DataTable1'
import PagingBlock from './Tables/DataTablePaging/DataTablePaging'
import TableSortLabel from './Tables/TableSortLabel/TableSortLabel'
import ProgressBar from './ProgressBar/ProgressBar'
import DropDown from './DropDowns/DropDown'
import LoadingIcon from './LoadingIcons/loadingIcon1'
import ImageLoader from './ImageLoader/imageLoader'
import Avatar from './Avatar/avatar';

import EventCard from './EventCard/eventCard'
import Toggle from './Toggle';
import Input from './Forms/input'
import TextArea from './Forms/textArea'
import Button from './Forms/button'
import Select from './Forms/select'
import CheckBox from './Forms/checkBox'
import RadioButton from './Forms/checkBox'
import MigrationContainer from './Migration/MigrationContainer'
import Link from './Link/Link'

import LineGraph from './LineGraph/LineGraph'
export default {

    DataTable1,
    PagingBlock,
    TableSortLabel,
    ProgressBar,
    DropDown,
    LoadingIcon,
    ImageLoader,
    Input,
    Avatar,
    Button,
    EventCard,
    Select,
    TextArea,
    CheckBox,
    RadioButton,
    LineGraph,
    Toggle,
    MigrationContainer,
    Link
}