import React from 'react';
import { BrowserRouter as Router, Route, Link, Switch, Redirect, BrowserHistory } from "react-router-dom";

import UILIB from '../../common-objects/Lib'

import OverViewPage from './support'
import RequestEngineer from './requestEngineer/requestEngineer'
import RequestRecycling from './requestRecycling/requestRecycling'
import RequestConsumables from './requestConsumables/requestConsumables'
import RequestInformation from './requestInformation/requestInformation'
import Suggestions from './suggestions/suggestions'
import MachineMoveRequest from './machineMoveRequest/machineMoveRequest'
import CancelEngineer from './cancelEngineer/cancelEngineer'
import ServiceHistory from './serviceHistory/serviceHistory'
import ServiceDownloads from './downloads/downloads'
export default class EventTemplate extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return <Switch>
            <Route exact path="/support" render={(props) => { return <OverViewPage {...props} account={this.props.account} history={this.props.history} currCustomer={this.props.currCustomer} /> }} />
            <Route path="/support/requestengineer" render={(props) => { return <RequestEngineer {...props} account={this.props.account} history={this.props.history} currCustomer={this.props.currCustomer} subSites={this.props.subSites} /> }} />
            <Route path="/support/requestconsumables" render={(props) => { return <RequestConsumables {...props} account={this.props.account} history={this.props.history} currCustomer={this.props.currCustomer} subSites={this.props.subSites} /> }} />
            <Route path="/support/requestinformation" render={(props) => { return <RequestInformation {...props} account={this.props.account} history={this.props.history} currCustomer={this.props.currCustomer} subSites={this.props.subSites} /> }} />
            <Route path="/support/suggestions" render={(props) => { return <Suggestions {...props} account={this.props.account} history={this.props.history} currCustomer={this.props.currCustomer} subSites={this.props.subSites} /> }} />
            <Route path="/support/machinemoverequest" render={(props) => { return <MachineMoveRequest {...props} account={this.props.account} history={this.props.history} currCustomer={this.props.currCustomer} subSites={this.props.subSites} /> }} />
            <Route path="/support/cancelengineer" render={(props) => { return <CancelEngineer {...props} account={this.props.account} history={this.props.history} currCustomer={this.props.currCustomer} subSites={this.props.subSites} /> }} />
            <Route path="/support/servicehistory" render={(props) => { return <ServiceHistory {...props} account={this.props.account} history={this.props.history} currCustomer={this.props.currCustomer} subSites={this.props.subSites} /> }} />
            <Route path="/support/downloads" render={(props) => { return <ServiceDownloads {...props} account={this.props.account} history={this.props.history} currCustomer={this.props.currCustomer} subSites={this.props.subSites} /> }} />
            <Route path="/support/requestrecycling" render={(props) => { return <RequestRecycling {...props} account={this.props.account} history={this.props.history} currCustomer={this.props.currCustomer} subSites={this.props.subSites} /> }} />

            <Redirect to="/support" push />
        </Switch>
    }
}