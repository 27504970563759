export function getFormData() {
  return {

    pageLoaded: true,
    submittingForm: false,
    submittedForm: false,
    formData: {
        yourName: "",
        emailAddress: "",
        contactNumber: "",
        deliveryAddress: "",
        quantity: 1,
        quantityCollect: 0
    },
    errorData: {
        yourName: "",
        emailAddress: "",
        contactNumber: "",
        deliveryAddress: "",
        quantity: "",
        quantityCollect: ""
    },
    confirmed: false
  }
}