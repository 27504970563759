import React from 'react'
import { Container, Row, Col, Visible, Hidden } from 'react-grid-system';
import UILIB from '../../../common-objects/Lib'
import bgImage from '../../../assetts/images/frontPage/bg6.jpg'
import axios from '../../../includes/axios';
import { getFormData } from './defaults';


export default class RequestEngineer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ...getFormData(),
            faultDescCharCount: 0
        };
        this.getEquipment = this.getEquipment.bind(this);
        this.updateField = this.updateField.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.equipmentClick = this.equipmentClick.bind(this);
        this.fileAdded = this.fileAdded.bind(this);
        this.toggleUpdateLocation = this.toggleUpdateLocation.bind(this);
        this.updateLocationText = this.updateLocationText.bind(this);
        this.updateSearch = this.updateSearch.bind(this);
    }

    componentDidMount() {
        var newState = this.state;
        newState.formData.emailAddress = this.props.account.emailAddress;
        newState.formData.yourName = this.props.account.firstName + " " + this.props.account.lastName;
        newState.formData.companyName = this.props.currCustomer.Name;

        var subSites = this.props.subSites.map(site => {
            return { value: site.id, label: site.altName }
        })

        subSites = subSites.sort((a, b) => {
            var nameA = (a.label);
            var nameB = (b.label);
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;

            }
            // names must be equal
            return 0;

        })

        subSites.unshift({ value: -1, label: "All Sites" })

        newState.subSites = subSites;
        this.setState(newState, this.getEquipment);
    }

    getEquipment() {
        axios.get(`/equipment?includeJobs=true`).then(_equipment => {
            this.setState({ equipment: _equipment.data.equipment, pageLoaded: true })
        })
    }

    updateField(event) {
        const { name, value } = event.target;
        this.setState(prevState => ({
            formData: {
                ...prevState.formData,
                [name]: value
            }
        }));
    
        if (name === 'faultDesc') {
            this.setState({ faultDescCharCount: value.length });
        }
    }

    submitForm() {
        var failed = false;
        var errorData = {
            yourName: "",
            emailAddress: "",
            contactNumber: "",
            companyName: "",
            department: "",
            referenceNo: "",
            endUser: "",
            faultDesc: "",
            bestContactNo: "",
            bestContactTime: "",
            faultType: "",
            faultTypeSW: "",
            faultTypeHW: "",
            selectedEquipmentId: "",
            generalError: "",
            faultTypeSWOther: ""
        }


        if (this.state.formData.yourName.length < 5) {
            errorData.yourName = "Please enter a valid name";
            failed = true;
        }
        if (this.state.formData.emailAddress.length < 5 || !this.state.formData.emailAddress.indexOf('@') || !this.state.formData.emailAddress.indexOf('.')) {
            errorData.emailAddress = "Please enter a valid email address";
            failed = true;
        }
        if (this.state.formData.companyName.length < 3) {
            errorData.companyName = "Please enter name";
            failed = true;
        }

        if (this.state.formData.endUser.length < 3) {
            errorData.endUser = "Please enter a name";
            failed = true;
        }
        if (this.state.formData.bestContactNo.length < 3) {
            errorData.bestContactNo = "Please enter a number";
            failed = true;
        }
        if (this.state.formData.bestContactTime.length < 1) {
            errorData.bestContactTime = "Please enter a time";
            failed = true;
        }

        if (this.state.formData.faultType == -1) {
            errorData.faultType = "Please select a fault type";
            failed = true;
        }
        else {
            if (this.state.formData.faultType == "Software" && this.state.formData.faultTypeSW == -1) {
                errorData.faultTypeSW = "Please select a fault type";
                failed = true;
            }
            if (this.state.formData.faultType == "Hardware" && this.state.formData.faultTypeHW == -1) {
                errorData.faultTypeHW = "Please select a fault type";
                failed = true;
            }
            if (this.state.formData.faultType == "Other" && this.state.formData.faultTypeSWOther.length < 3) {
                errorData.faultTypeSWOther = "Please enter a fault type";
                failed = true;
            }
        }

        if (this.state.formData.selectedEquipmentId == -1) {
            errorData.selectedEquipmentId = "Please select a device that this issue relates to";
            failed = true;
        }

        this.setState({ errorData: errorData })
        if (failed) {
            return
        }
        this.setState({ submittingForm: true })

        var self = this;
        var data = new FormData();

        Object.keys(this.state.formData).forEach((formElement, index) => {
            if (formElement == "attachments") {
                data.append('file', this.state.formData.attachments[0])
            }
            else {

                data.append(formElement, this.state.formData[formElement])
            }
        })

        var deviceMake = "";
        var deviceModel = "";
        var deviceSerial = "";
        var ourMachine = this.state.equipment.find(eq => eq.id + "" == this.state.formData.selectedEquipmentId + "")

        if (ourMachine) {
            deviceModel = ourMachine.description;
            deviceSerial = ourMachine.serialnumber;
        }

        data.append('deviceMake', deviceMake)
        data.append('deviceModel', deviceModel)
        data.append('deviceSerial', deviceSerial)

        axios.post(`/utilities/engineerRequest`, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(_result => {
            this.setState({ 
              ...getFormData(), 
              equipment: this.state.equipment,
              subSites: this.state.subSites,
              submittingForm: false, 
              submittedForm: true, 
              pageLoaded: true })
        }).catch(err => {
            var generalError = "There was an issue submitting the form, please try again"
            if(err.data && err.data.error && err.data.error === 'Request Already Exists') {
              generalError = "An unprocessed request with this serial number already exists in the system"
            }
            var theState = self.state;
            theState.errorData.generalError = generalError
            theState.submittingForm = false
            this.setState({ theState })
        })

    }

    equipmentClick(id) {
        var state = this.state;
        state.formData.selectedEquipmentId = id
        this.setState(state)
    }

    fileAdded(e) {
        let files;
        if (e.preventDefault) {
            e.preventDefault();
            files = e.target.files;
        }
        else {
            files = e;
        }

        var thisState = this.state;
        thisState.formData.attachments = files;
        this.setState(thisState);
    }

    toggleUpdateLocation(line) {
        var editLocationText = line.Location;
        var editLocationId = line.id;
        if (this.state.editLocationId == line.id) {
            editLocationId = 0;
            editLocationText = "";
        }
        this.setState({ editLocationId: editLocationId, editLocationText: editLocationText })
    }

    updateLocationText(event) {
        this.setState({ [event.target.name]: event.target.value })
    }
    saveUpdateLocation(line) {
        if (line.Location == this.state.editLocationText) {
            this.setState({ editLocationId: 0, editLocationText: "" })
            return;
        }

        axios.post("/utilities/deviceLocationRequest", { ...line, newLocation: this.state.editLocationText }).then(() => {
            line.Location = this.state.editLocationText;
            this.setState({ editLocationId: 0, editLocationText: "", editLocation: line })
        }).catch(err => {
            this.setState({ editLocationId: 0, editLocationText: "" })
        })

    }

    updateSearch(event) {
        var searches = this.state.searches;
        searches[event.target.name] = event.target.value;
        this.setState({ searches })
    }
    render() {
        if (!this.state.pageLoaded || this.state.submittingForm) return <div style={{ textAlign: "center" }}><UILIB.LoadingIcon iconType="2" /></div>


        var faultTypeData = [
            { value: "Hardware", label: "Hardware" },
            { value: "Software", label: "Software" },
            { value: "Other", label: "Other" }


        ]

        var softwareFaults = [
            { value: "Slow print", label: "Slow print" },
            { value: "No Print - single device", label: "No Print - single device" },
            { value: "No print - all devices", label: "No print - all devices" },
            { value: "Can't release jobs - single user", label: "Can't release jobs - single user" },
            { value: "Can't release jobs - all users", label: "Can't release jobs - all users" },
            { value: "Scanning fault", label: "Scanning fault" },
            { value: "Email fault", label: "Email fault" },
            { value: "Printing fault", label: "Printing fault" },
            { value: "Lost connection", label: "Lost connection" },
            { value: "Device locked", label: "Device locked" },
            { value: "Authentication problem", label: "Authentication problem" },
            { value: "Printer offline", label: "Printer offline" },
            { value: "Driver problem", label: "Driver problem" },
            { value: "Prints disappear", label: "Prints disappear" },
            { value: "Can't print in colour", label: "Can't print in colour" },
            { value: "Budget problem", label: "Budget problem" },
            { value: "Card reader problem", label: "Card reader problem" },
            { value: "Booklet printing problem", label: "Booklet printing problem" },
            { value: "License problem", label: "License problem" },
            { value: "Memory full", label: "Memory full" },
            { value: "Reports problem", label: "Reports problem" },
            { value: "Password problem", label: "Password problem" }
        ]

        var hardwareFaults = [
            { value: "Broken Part", label: "Broken Part" },
            { value: "Copitrak", label: "Copitrak" },
            { value: "Creasing", label: "Creasing" },
            { value: "eCopy", label: "eCopy" },
            { value: "Error Code", label: "Error Code" },
            { value: "Jamming", label: "Jamming" },
            { value: "No Power/Dead", label: "No Power/Dead" },
            { value: "Noise", label: "Noise" },
            { value: "Poor Copy Quality", label: "Poor Copy Quality" },
            { value: "Safecom", label: "Safecom" },
            { value: "Scanning", label: "Scanning" },
            { value: "Skewing", label: "Skewing" },
            { value: "Slow Printing", label: "Slow Printing" },
            { value: "Unable to Print", label: "Unable to Print" },
            { value: "uniFLOW", label: "uniFLOW" },
            { value: "Waste Toner Full", label: "Waste Toner Full" }
        ]


        var equipmentFinal = this.state.equipment;
        equipmentFinal = equipmentFinal.filter(eq => {
            var passedFilter = true;
            if (this.state.searches.serial && this.state.searches.serial.length > 1 && eq.serialnumber.toLowerCase().indexOf(this.state.searches.serial.toLowerCase()) < 0) passedFilter = false;
            if (this.state.searches.deviceName && this.state.searches.deviceName.length > 1 && eq.description.toLowerCase().indexOf(this.state.searches.deviceName.toLowerCase()) < 0) passedFilter = false;
            if (this.state.searches.location && this.state.searches.location.length > 1 && eq.Location.toLowerCase().indexOf(this.state.searches.location.toLowerCase()) < 0) passedFilter = false;
            if (this.state.searches.assetNo && this.state.searches.assetNo.length > 1 && eq.AssetNumber.toLowerCase().indexOf(this.state.searches.assetNo.toLowerCase()) < 0) passedFilter = false;
            if (this.state.searches.address && this.state.searches.address.length > 1 && eq.address1.toLowerCase().indexOf(this.state.searches.address.toLowerCase()) < 0) passedFilter = false;
            return passedFilter;
        })

        var hasAssetNos = false;
        equipmentFinal.forEach(eq => {
            if (eq.AssetNumber && eq.AssetNumber.length > 1) {
                hasAssetNos = true;
            }
        })
        return <Row className="mar-t15">
            <Col xs={12} sm={12} md={12} lg={12} style={{ marginBottom: "20px" }}>
                <div className="overViewHeader" style={{ backgroundImage: `url(${bgImage})` }}>
                    <div className="overViewHeaderText">
                        <h1>Open a Support Ticket</h1>
                        Have an issue with a device, or something else? Please let us know below.
                    </div>
                </div>
            </Col>

            {this.state.errorData.generalError &&
                <Col xs={12} style={{ marginBottom: "20px" }}>
                    <div style={{
                        backgroundColor: "red",
                        color: "white",
                        padding: "10px",
                        textAlign: "center"
                    }}>
                        {this.state.errorData.generalError}
                    </div>
                </Col>
            }

            {
                this.state.submittedForm && <Col xs={12}>
                    <div className="paper">
                        <h2>Request Submitted</h2>
                        <p>Thank you for submitting your Request.</p>
                        <p>A member of staff should be back in contact with you on the contact details you provided shortly.</p>
                        <UILIB.Button value="Continue" onClick={() => { this.setState({ submittedForm: false }) }} />
                    </div>
                </Col>
            }
            {
                (!this.state.submittingForm && !this.state.submittedForm) && <div>
                    <Col xs={12}>
                        <div className="paper">
                            <h2>Your Contact Details</h2>
                            <Row>
                                <Col xs={12} sm={6} md={4}>
                                    <label>Your Name</label>
                                    <UILIB.Input type="text" name="yourName" value={this.state.formData.yourName} onChange={this.updateField} error={this.state.errorData.yourName} />
                                </Col>
                                <Col xs={12} sm={6} md={4}>
                                    <label>Your Email</label>
                                    <UILIB.Input type="email" name="emailAddress" value={this.state.formData.emailAddress} onChange={this.updateField} error={this.state.errorData.emailAddress} />
                                </Col>
                                <Col xs={12} sm={6} md={4}>
                                    <label>Contact Number</label>
                                    <UILIB.Input type="text" name="contactNumber" value={this.state.formData.contactNumber} onChange={this.updateField} error={this.state.errorData.contactNumber} />
                                </Col>
                                <Col xs={12} sm={6} md={4}>
                                    <label>Company Name</label>
                                    <UILIB.Input type="text" name="companyName" value={this.state.formData.companyName} onChange={this.updateField} error={this.state.errorData.companyName} />
                                </Col>
                                <Col xs={12} sm={6} md={4}>
                                    <label>Department</label>
                                    <UILIB.Input type="text" name="department" value={this.state.formData.department} onChange={this.updateField} error={this.state.errorData.department} />
                                </Col>
                            </Row>
                        </div>
                    </Col>

                    <Col xs={12}>
                        <div className="paper" style={this.state.errorData.selectedEquipmentId ? { border: "2px solid red" } : {}}>
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <h2>Device Affected</h2>
                                <UILIB.Select data={this.state.subSites}
                                    onChange={(ev) => { this.setState({ subsiteFilter: ev.target.value }) }} />
                            </div>
                            <table width="100%" cellPadding="5" cellSpacing="2" className={this.state.errorData.selectedEquipmentId ? "mar-b15" : ""}>
                                <tr>
                                    <td>
                                    </td>
                                    <td>
                                        <div>Serial</div>
                                        <div style={{ marginTop: "10px", width: "100%", maxWidth: "200px" }}>
                                            <UILIB.Input name="serial" value={this.state.searches.serial} onChange={this.updateSearch} type="text" placeholder="Search" outerStyle={{ margin: "0" }} style={{ fontSize: "10px", padding: "5px" }} />
                                        </div>
                                    </td>
                                    <td>
                                        <div>Device Name</div>
                                        <div style={{ marginTop: "10px", width: "100%", maxWidth: "200px" }}>
                                            <UILIB.Input name="deviceName" value={this.state.searches.deviceName} onChange={this.updateSearch} type="text" placeholder="Search" outerStyle={{ margin: "0" }} style={{ fontSize: "10px", padding: "5px" }} />
                                        </div>
                                    </td>
                                    <td>
                                        <div>Address</div>
                                        <div style={{ marginTop: "10px", width: "100%", maxWidth: "200px" }}>
                                            <UILIB.Input name="address" value={this.state.searches.address} onChange={this.updateSearch} type="text" placeholder="Search" outerStyle={{ margin: "0" }} style={{ fontSize: "10px", padding: "5px" }} />
                                        </div>
                                    </td>
                                    <td>
                                        <div>Location</div>
                                        <div style={{ marginTop: "10px", width: "100%", maxWidth: "200px" }}>
                                            <UILIB.Input name="location" value={this.state.searches.location} onChange={this.updateSearch} type="text" placeholder="Search" outerStyle={{ margin: "0" }} style={{ fontSize: "10px", padding: "5px" }} />
                                        </div>
                                    </td>
                                    {hasAssetNos && <td>
                                        <div>Asset No</div>
                                        <div style={{ marginTop: "10px", width: "100%", maxWidth: "200px" }}>
                                            <UILIB.Input name="assetNo" value={this.state.searches.assetNo} onChange={this.updateSearch} type="text" placeholder="Search" outerStyle={{ margin: "0" }} style={{ fontSize: "10px", padding: "5px" }} />
                                        </div>
                                    </td>}
                                </tr>
                                <tr>
                                    <td colSpan="20"><hr style={{ border: "0px", backgroundColor: "white", height: "1px" }} /></td>
                                </tr>
                                {equipmentFinal.map((eq, index) => {
                                    if (this.state.subsiteFilter != -1 && this.state.subsiteFilter != eq.customerId) return;
                                    var style = {};
                                    var isSelected = this.state.formData.selectedEquipmentId == eq.id;

                                    return <tr key={"eq_" + index}  >
                                        <td align={"center"} width="150">
                                            {isSelected && <span className="icon-check" style={{ cursor: "pointer" }} onClick={() => { this.equipmentClick(-1) }} />}
                                            {!isSelected && <UILIB.Button value="Select" onClick={() => { this.equipmentClick(eq.id) }} style={{ border: "2px solid white" }} />}

                                        </td>
                                        <td>
                                            {String(eq.serialnumber)}
                                            {(Boolean(eq.jobsOpen) && Number(eq.jobsOpen) != 0) && <div style={{ color: "red" }}>{eq.jobsOpen} jobs already open</div>}
                                        </td>
                                        <td >
                                            {eq.description}
                                        </td>

                                        <td>
                                            {eq.address1 + (eq.address2 ? " " + eq.address2 : "")}
                                        </td>
                                        <td>
                                            {(this.state.editLocationId == 0 || this.state.editLocationId != eq.id) && <div>{eq.Location} <span className="icon-pencil" alt="Update Location" title="Update Location" style={{ cursor: "pointer", fontSize: "12px" }} onClick={() => { this.toggleUpdateLocation(eq) }} /></div>}
                                            {(this.state.editLocationId != 0 && this.state.editLocationId == eq.id) && <div>
                                                <div style={{ fontSize: "12px" }}>
                                                    Request to change this device location name<br />and one of our staff will update:
                                                </div>
                                                <div>
                                                    <UILIB.Input type="text" name="editLocationText" value={this.state.editLocationText} onChange={(ev) => this.updateLocationText(ev)} outerStyle={{ width: "200px", display: "inline-block", margin: "0px" }} />
                                                    <span className="icon-check" alt="Save Location" title="Save Location" style={{ cursor: "pointer", fontSize: "12px" }} onClick={() => { this.saveUpdateLocation(eq) }} />
                                                </div>

                                            </div>}

                                        </td>


                                        {hasAssetNos && <td>
                                            {eq.AssetNumber}
                                        </td>}

                                    </tr>
                                })
                                }
                            </table>

                            {
                                this.state.errorData.selectedEquipmentId && <div className="errorTextHolder" style={{
                                    backgroundColor: "red",
                                    color: "white",
                                    padding: "10px",
                                    display: "inline"
                                }}>{this.state.errorData.selectedEquipmentId}</div>
                            }

                        </div>

                    </Col>

                    <Col xs={12}>
                        <div className="paper">
                            <h2>Fault Details</h2>
                            <Row>

                                <Col xs={12} sm={6} md={4}>
                                    <label>Your Reference No</label>
                                    <UILIB.Input type="text" name="referenceNo" value={this.state.formData.referenceNo} onChange={this.updateField} error={this.state.errorData.referenceNo} />
                                </Col>
                                <Col xs={12} sm={6} md={4}>
                                    <label>Fault Type</label>
                                    <UILIB.Select name="faultType" data={faultTypeData} placeholder="Select a Fault Type" onChange={this.updateField} value={this.state.formData.faultType} error={this.state.errorData.faultType} />
                                    {this.state.formData.faultType == "Software" && <UILIB.Select name="faultTypeSW" placeholder="Select a Software Type" data={softwareFaults} onChange={this.updateField} value={this.state.formData.faultTypeSW} error={this.state.errorData.faultTypeSW} />}
                                    {this.state.formData.faultType == "Hardware" && <UILIB.Select name="faultTypeHW" placeholder="Select a Hardware Type" data={hardwareFaults} onChange={this.updateField} value={this.state.formData.faultTypeHW} error={this.state.errorData.faultTypeHW} />}
                                    {this.state.formData.faultType == "Other" && <div>
                                        <label>Describe the fault</label>
                                        <UILIB.Input type="text" name="faultTypeSWOther" value={this.state.formData.faultTypeSWOther} onChange={this.updateField} error={this.state.errorData.faultTypeSWOther} /></div>}
                                </Col>
                                <Col xs={12} sm={6} md={4}>
                                    <label>End User who has the Fault</label>
                                    <UILIB.Input type="text" name="endUser" value={this.state.formData.endUser} onChange={this.updateField} error={this.state.errorData.endUser} />
                                </Col>
                                <Col xs={12}>
                                    <label>Fault Description ({this.state.faultDescCharCount}/2000)</label>
                                    <UILIB.TextArea type="text" name="faultDesc" maxlength="2000" onChange={this.updateField} error={this.state.errorData.faultDesc}>{this.state.formData.faultDesc}</UILIB.TextArea>
                                </Col>
                                <Col xs={12} sm={6} md={4}>
                                    <label>Best Contact No</label>
                                    <UILIB.Input type="text" name="bestContactNo" value={this.state.formData.bestContactNo} onChange={this.updateField} error={this.state.errorData.bestContactNo} />
                                </Col>
                                <Col xs={12} sm={6} md={4}>
                                    <label>Best Contact Time</label>
                                    <UILIB.Input type="text" name="bestContactTime" value={this.state.formData.bestContactTime} onChange={this.updateField} error={this.state.errorData.bestContactTime} />
                                </Col>
                                <Col xs={12} className="mar-b15">
                                    <label>Attachment</label>
                                    <div>If you would like to attach a relevant file (ie a PDF, jpeg, etc) relating to this incident, please use the following option:</div>
                                    <UILIB.Input type="file" name="attachments" onChange={this.fileAdded} error={this.state.errorData.attachment} />
                                </Col>
                                <Col xs={12}>
                                    <UILIB.Button value="Submit Request" onClick={this.submitForm} />
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </div>
            }
        </Row >
    }
}
