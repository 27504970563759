import React from 'react'
import { Container, Row, Col, Visible, Hidden } from 'react-grid-system';
import UILIB from '../../../common-objects/Lib'
import bgImage from '../../../assetts/images/frontPage/bg6.jpg'
import axios from '../../../includes/axios';
export default class RequestInformation extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            formData: {
                yourName: "",
                emailAddress: "",
                companyName: "",
                requestBody: ""
            },
            errorData: {
                yourName: "",
                emailAddress: "",
                companyName: "",
                requestBody: "",
                generalError: ""
            },
            submittingForm: false,
            submittedForm: false
        }

        this.updateField = this.updateField.bind(this);
        this.submitForm = this.submitForm.bind(this);
    }

    componentWillMount() {

        var newState = this.state;
        newState.formData.emailAddress = this.props.account.emailAddress;
        newState.formData.yourName = this.props.account.firstName + " " + this.props.account.lastName;
        newState.formData.companyName = this.props.currCustomer.Name;
        this.setState(newState)
    }


    updateField(event) {
        var state = this.state;
        state.formData[event.target.name] = event.target.value;
        this.setState(state)
    }

    submitForm() {
        var failed = false;
        var errorData = {
            yourName: "",
            emailAddress: "",
            companyName: "",
            requestBody: "",
            generalError: ""
        }


        if (this.state.formData.yourName.length < 5) {
            errorData.yourName = "Please enter a valid name";
            failed = true;
        }
        if (this.state.formData.emailAddress.length < 5 || this.state.formData.emailAddress.indexOf('@') < 0 || this.state.formData.emailAddress.indexOf('.') < 0) {
            errorData.emailAddress = "Please enter a valid email address";
            failed = true;
        }
        if (this.state.formData.companyName.length < 3) {
            errorData.companyName = "Please enter name";
            failed = true;
        }
        if (this.state.formData.requestBody.length < 5) {
            errorData.requestBody = "Please enter a Suggestion";
            failed = true;
        }

        if (failed) {
            this.setState({ errorData: errorData })
            return
        }
        this.setState({ submittingForm: true })
        var self = this;

        var postBody = {
            ContactName: this.state.formData.yourName,
            emailAddress: this.state.formData.emailAddress,
            companyName: this.state.formData.companyName,
            requestBody: this.state.formData.requestBody
        }
        axios.post(`/utilities/suggestion`, postBody).then(_result => {
            var formData = {
                yourName: "",
                emailAddress: "",
                companyName: "",
                requestBody: ""
            }
            this.setState({ submittingForm: false, submittedForm: true, formData })
        }).catch(err => {
            var theState = self.state;
            theState.errorData.generalError = "There was an issue submitting the form, please try again"
            theState.submittingForm = false
            this.setState({ theState })
        })

    }


    render() {


        return <Row className="mar-t15">
            <Col xs={12} sm={12} md={12} lg={12} style={{ marginBottom: "20px" }}>
                <div className="overViewHeader" style={{ backgroundImage: `url(${bgImage})`, minHeight: "160px" }}>
                    <div className="overViewHeaderText">
                        <h1>Make a Suggestion</h1>
                        <p style={{ fontSize: "12px" }}>We very much welcome your input and suggestions into making this portal more user friendly as well as providing you as much information as possible. Please complete the form below detailing your suggestion, and we'll see what we can do!</p>
                    </div>
                </div>
            </Col>

            {this.state.submittingForm && <UILIB.LoadingIcon iconType="2" />}
            {this.state.submittedForm && <Col xs={12}>
                <div className="paper">
                    <h2>Suggestion Sent</h2>
                    <p>Thank you for submitting your Suggestio.</p>
                    <p>A member of staff should be back in contact with you on the contact details you provided shortly.</p>
                    <UILIB.Button value="Continue" onClick={() => { this.setState({ submittedForm: false }) }} />
                </div>
            </Col>}
            {(!this.state.submittingForm && !this.state.submittedForm) && <div>
                <Col xs={12}>
                    <div className="paper">
                        <h2>Your Contact Details</h2>
                        <Row>
                            <Col xs={12} sm={6} md={4}>
                                <label>Your Name</label>
                                <UILIB.Input type="text" name="yourName" value={this.state.formData.yourName} onChange={this.updateField} error={this.state.errorData.yourName} />
                            </Col>
                            <Col xs={12} sm={6} md={4}>
                                <label>Your Email</label>
                                <UILIB.Input type="email" name="emailAddress" value={this.state.formData.emailAddress} onChange={this.updateField} error={this.state.errorData.emailAddress} />
                            </Col>
                            <Col xs={12} sm={6} md={4}>
                                <label>Company Name</label>
                                <UILIB.Input type="text" name="companyName" value={this.state.formData.companyName} onChange={this.updateField} error={this.state.errorData.companyName} />
                            </Col>
                            <Col xs={12} sm={12} md={12}>
                                <label>Your Suggestion</label>

                                <UILIB.TextArea name="requestBody" style={{ height: "200px" }} onChange={this.updateField} error={this.state.errorData.requestBody}>{this.state.formData.requestBody}</UILIB.TextArea>
                            </Col>
                            {this.state.errorData.generalError && <Col xs={12}>
                                <div className="paper red">
                                    {this.state.errorData.generalError}
                                </div>
                            </Col>}
                            <Col xs={12}>
                                <UILIB.Button value="Submit Suggestion" onClick={this.submitForm} />
                            </Col>
                        </Row>
                    </div>
                </Col>
            </div>}
        </Row>
    }
}
