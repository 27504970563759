import React from 'react'
import { Container, Row, Col, Visible, Hidden } from 'react-grid-system';
import UILIB from '../../common-objects/Lib'
import Axios from '../../includes/axios';
import Utils from '../../config/utils'
import moment from 'moment';

export default class KpaxMeterReadings extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pageLoaded: false,
            devices: []
        }

        this.getDevices = this.getDevices.bind(this)
        this.exportCSV = this.exportCSV.bind(this);
    }

    componentDidMount() {
        this.getDevices()
    }

    getDevices() {
        Axios.get('/kpax/devices').then(data => {
            this.setState({ devices: data.data.devices, pageLoaded: true })
        })
    }

    exportCSV() {

        var csv = 'Device,Serial,101,103,105,106,108,109,112,113,122,123,124,Total,Mono,Colour,Last Reading\n';

        this.state.devices.forEach(device => {
            var reading = undefined;
            if (device.Meters) reading = device.Meters
            csv += device.Model + "," + device.SerialNumber + ",";
            csv += (reading && reading.Canon101 ? reading.Canon101[0] : "") + ",";
            csv += (reading && reading.Canon103 ? reading.Canon103[0] : "") + ",";
            csv += (reading && reading.Canon105 ? reading.Canon105[0] : "") + ",";
            csv += (reading && reading.Canon106 ? reading.Canon106[0] : "") + ",";
            csv += (reading && reading.Canon108 ? reading.Canon108[0] : "") + ",";
            csv += (reading && reading.Canon109 ? reading.Canon109[0] : "") + ",";
            csv += (reading && reading.Canon112 ? reading.Canon112[0] : "") + ",";
            csv += (reading && reading.Canon113 ? reading.Canon113[0] : "") + ",";
            csv += (reading && reading.Canon122 ? reading.Canon122[0] : "") + ",";
            csv += (reading && reading.Canon123 ? reading.Canon123[0] : "") + ",";
            csv += (reading && reading.Canon124 ? reading.Canon124[0] : "") + ",";
            csv += (reading && reading.EngineCount ? reading.EngineCount[0] : "") + ",";
            csv += (reading && reading.TotalMono ? reading.TotalMono[0] : "") + ",";
            csv += (reading && reading.TotalColor ? reading.TotalColor[0] : "") + ",";
            csv += (device.LastCheck ? Utils.formatDateTime(device.LastCheck, 2) : "") + ",";

            csv += "\n";
        });

        console.log(csv);
        var hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
        hiddenElement.target = '_blank';
        hiddenElement.download = 'people.csv';
        hiddenElement.click();

    }
    render() {
        if (!this.state.pageLoaded) return <div style={{ textAlign: "center" }}>
            <UILIB.LoadingIcon />
            <div>Loading Data</div>
        </div>

        return <div>
            <div style={{ marginTop: "5px", marginBottom: "5px", textAlign: "right" }}>{this.state.devices.length + " devices"}</div>
            <div className="paper mar-b10">
                <div className="standardTable" style={{ height: "50vh", minHeight: "300px" }}><table width="100%" cellPadding="5" cellSpacing="2">
                    <thead>
                        <tr>
                            <th>Last Reading Date</th>
                            <th>Serial No</th>
                            <th>Device</th>
                            <th>Host Name</th>
                            <th>101</th>
                            <th>103</th>
                            <th>105</th>
                            <th>106</th>
                            <th>108</th>
                            <th>109</th>
                            <th>112</th>
                            <th>113</th>
                            <th>122</th>
                            <th>123</th>
                            <th>124</th>
                            <th>Total</th>
                            <th>Mono</th>
                            <th>Colour</th>

                        </tr>
                    </thead>
                    {this.state.devices.map((device, index) => {
                        var readings = undefined;
                        if (device.Meters) readings = device.Meters
                        return <tr>
                            <td>
                                {device.LastCheck ? Utils.formatDateTime(device.LastCheck, 2) : <span className="text-grey">N/A</span>}</td>
                            <td>{device.SerialNumber}</td>
                            <td>{device.Model}</td>

                            <td style={{ wordBreak: "break-word", minWidth: "200px" }}>{device.HostName}</td>
                            <td>{readings && readings.Canon101 ? readings.Canon101[0] : <span className="text-grey">N/A</span>}</td>
                            <td>{readings && readings.Canon103 ? readings.Canon103[0] : <span className="text-grey">N/A</span>}</td>
                            <td>{readings && readings.Canon105 ? readings.Canon105[0] : <span className="text-grey">N/A</span>}</td>
                            <td>{readings && readings.Canon106 ? readings.Canon106[0] : <span className="text-grey">N/A</span>}</td>
                            <td>{readings && readings.Canon108 ? readings.Canon108[0] : <span className="text-grey">N/A</span>}</td>
                            <td>{readings && readings.Canon109 ? readings.Canon109[0] : <span className="text-grey">N/A</span>}</td>
                            <td>{readings && readings.Canon112 ? readings.Canon112[0] : <span className="text-grey">N/A</span>}</td>
                            <td>{readings && readings.Canon113 ? readings.Canon113[0] : <span className="text-grey">N/A</span>}</td>
                            <td>{readings && readings.Canon122 ? readings.Canon122[0] : <span className="text-grey">N/A</span>}</td>
                            <td>{readings && readings.Canon123 ? readings.Canon123[0] : <span className="text-grey">N/A</span>}</td>
                            <td>{readings && readings.Canon124 ? readings.Canon124[0] : <span className="text-grey">N/A</span>}</td>
                            <td>{readings && readings.EngineCount ? readings.EngineCount[0] : <span className="text-grey">N/A</span>}</td>
                            <td>{readings && readings.TotalMono ? readings.TotalMono[0] : <span className="text-grey">N/A</span>}</td>
                            <td>{readings && readings.TotalColor ? readings.TotalColor[0] : <span className="text-grey">N/A</span>}</td>

                        </tr>
                    })}
                </table>

                </div>
            </div>
            <div style={{ textAlign: "right" }}>
                <UILIB.Button value="Export (csv)" onClick={this.exportCSV} />
            </div>
        </div>

    }
}
