import React from 'react'

export default class ProgressBar extends React.Component {
    constructor(props) {
        super(props);

    }

    componentDidMount() {

    }

    render() {
        var width = this.props.width;
        var style = {};
        if (this.props.style) style = this.props.style;
        style.width = this.props.width + "%";
        var classes = "progressBar";
        if (this.props.className) classes += " " + this.props.className;

        return <div className={classes} style={style}></div>


    }
}
