import React from 'react'
import {
    BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer
} from 'recharts';
import moment from 'moment';
import Axios from '../../../includes/axios';
import UILIB from '../../../common-objects/Lib'
import Utils from '../../../config/utils'
export default class GraphMonoColourVolumes extends React.Component {
    constructor(props) {
        super(props);

        let timer;
        this.state = {
            graphData: [],
            loading: true
        }
        this.getStats = this.getStats.bind(this);
    }

    componentDidMount() {
        this.getStats(false, this.props);
    }
    componentWillUnmount() {
        clearTimeout(this.timer)
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.startDate != this.props.startDate || nextProps.endDate != this.props.endDate) this.getStats(true, nextProps);
    }
    getStats(forced, props) {
        var self = this;
        if (forced) this.setState({ loading: true })
        var self = this;
        clearTimeout(self.timer)
        var qs = "";
        if (props.startDate && props.endDate) qs = `?start=${props.startDate}&end=${props.endDate}`
        Axios.get(`/dashboard/graphMonoColourVolumes` + qs).then(_res => {
            var data = _res.data.results.map((dat) => {
                dat.date = Utils.monthName(dat.date, true)
                return dat;
            })
            self.setState({ graphData: _res.data.results, loading: false })
            self.timer = setTimeout(() => { self.getStats(false, props) }, 60000)
        }).catch(() => {
            self.timer = setTimeout(() => { self.getStats(false, props) }, 60000)
        })
    }

    render() {
        if (this.state.loading) return <div style={{ textAlign: "center" }}><UILIB.LoadingIcon /></div>

        return <ResponsiveContainer height={250}>
            <BarChart data={this.state.graphData} margin={{ top: 10, right: 0, left: 20, bottom: 0 }}>
                <YAxis />
                <XAxis dataKey="date" axisLine={false} tickLine={false} padding={{ left: 15 }} tick={{ fontSize: 10, fill: 'black', fontFamily: "Roboto", fontWeight: 600 }} interval={0} />

                <Tooltip
                    labelStyle={{ fontSize: 10, color: 'black', fontFamily: "Roboto", fontWeight: 600 }}
                    itemSyle={{ fontSize: 10, fontFamily: "Roboto", fontWeight: 600 }}
                    cursor={false}
                />

                <Bar type="monotone" dot={false} dataKey="monoTotal" strokeWidth="0" fill={"black"} stroke={"black"} fillOpacity={0.8} />
                <Bar type="monotone" dot={false} dataKey="colourTotal" strokeWidth="0" fill={"#65DD6D"} stroke={"#65DD6D"} fillOpacity={0.8} />
            </BarChart>
        </ResponsiveContainer>
    }
}
