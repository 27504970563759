import React from 'react'
import { Row, Col } from 'react-grid-system';
import UILIB from '../../common-objects/Lib'

export default class MigrationContainer extends React.Component {
    constructor(props) {
        super(props);

        this.account = props.account;
    }

    componentDidMount() {

    }

    render() {
        const link = <UILIB.Link hRef={`${process.env.REACT_APP_ENV_PORTAL_UI_URL}invite/accept?token=${this.account.inviteIdV3}`}>
                      click here
                    </UILIB.Link>

        return  <Col xs={12} className="migrationBanner">
            <div className="container">
                <p>Hi {this.account.firstName} {this.account.lastName}, as part of our continuous improvement scheme 
                  you are now eligible to migrate to a new and improved version of the Customer Portal experience.
                </p>
                <p>Please {link} if you wish to begin the migration process. Once the process is complete you will no longer have access to 
                  this version of the portal.
                </p>
            </div>
        </Col>
    }
}
