import React from 'react'
import { Container, Row, Col, Visible, Hidden } from 'react-grid-system';
import UILIB from '../../common-objects/Lib'
import bgImage from '../../assetts/images/frontPage/bg6.jpg'
import Axios from '../../includes/axios';

import OverviewPage from './overview';
import AlertsPage from './alerts';
import MeterReadingsPage from './meterReadings';
import ReportsPage from './reports';
import TrainingPage from './training';
import KpaxSignup from './signup';
export default class Kpax extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currTab: "Overview",
            pageLoaded: false
        }

        this.changeTab = this.changeTab.bind(this);
    }

    componentDidMount() {
        Axios.get('/kpax/devices').then(data => {
            var devices = data.data.devices;
            this.setState({ devices: devices, pageLoaded: true })
        }).catch(err => {
            this.setState({ devices: [], pageLoaded: true })
        })
    }


    changeTab(newTab) {
        this.setState({ currTab: newTab })
    }

    render() {
        if (!this.state.pageLoaded) return <div style={{ textAlign: "center" }}><UILIB.LoadingIcon /></div>;
        if (!this.state.devices.length) return <KpaxSignup />
        return <div>
            <Row className="mar-t15">
                <Col xs={12} sm={12} md={12} lg={12} style={{ marginBottom: "20px" }}>
                    <div className="overViewHeader" style={{ backgroundImage: `url(${bgImage})` }}>
                        <div className="overViewHeaderText">
                            <h1>Fleet Management</h1>
                            <span>Powered by KPAX</span>
                        </div>
                    </div>
                </Col>

                <Col xs={12} sm={12} md={12} lg={12} className="mar-b20">
                    <Row>
                        <Col>
                            <div className={"dashboard_headerNav" + (this.state.currTab == "Overview" ? " active" : "")} onClick={() => { this.changeTab("Overview") }}>Overview</div>
                        </Col>
                        <Col>
                            <div className={"dashboard_headerNav" + (this.state.currTab == "Alerts" ? " active" : "")} onClick={() => { this.changeTab("Alerts") }}>Alerts</div>
                        </Col>
                        <Col>
                            <div className={"dashboard_headerNav" + (this.state.currTab == "Reports" ? " active" : "")} onClick={() => { this.changeTab("Reports") }}>Reports</div>
                        </Col>
                        <Col>
                            <div className={"dashboard_headerNav" + (this.state.currTab == "Training" ? " active" : "")} onClick={() => { this.changeTab("Training") }}>Training</div>
                        </Col>
                        <Col>
                            <div className={"dashboard_headerNav" + (this.state.currTab == "Meter Readings" ? " active" : "")} onClick={() => { this.changeTab("Meter Readings") }}>Meter Readings</div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            {this.state.currTab == "Overview" && <OverviewPage {...this.props} />}
            {this.state.currTab == "Alerts" && <AlertsPage {...this.props} />}
            {this.state.currTab == "Reports" && <ReportsPage {...this.props} />}
            {this.state.currTab == "Training" && <TrainingPage {...this.props} />}
            {this.state.currTab == "Meter Readings" && <MeterReadingsPage {...this.props} />}
        </div>
    }
}
