import React from 'react'

/* PAGING */
export default class PagingBlock extends React.Component {
    constructor(props) {
        super(props);

        this.changePage = this
            .changePage
            .bind(this);
        this.generateLinks = this.generateLinks.bind(this);
    }

    changePage(event) {

        var newValue = event
            .target
            .id
            .replace("pagingLnk_", "");

        this
            .props
            .changePage(newValue, null, null, null);
        this.props
    }
    generateLinks() {
        var theData = {};
        var thisText = this.props.text;
        var pagesText = [];
        var startPage = 0;
        var endPage = 0;

        var currentPage = 0;
        if (this.props.currentPage) {
            currentPage = Number(this.props.currentPage);
        }

        var totalPages = Math.floor((Number(this.props.totalRows) / Number(this.props.pageSize)));
        if (!(Number(this.props.totalRows) % Number(this.props.pageSize))) {
            totalPages -= 1;
        }

        if (this.props.totalRows == this.props.pageSize) {
            totalPages = 0;
        }
        var numberOfLinks = 5;
        if (this.props.numberOfLinks) {
            numberOfLinks = Number(this.props.numberOfLinks);
        }

        if (currentPage > totalPages) {
            currentPage = totalPages;
        }
        if (totalPages >= 1) {
            startPage = currentPage - Math.floor(numberOfLinks / 2);
            endPage = currentPage + Math.floor(numberOfLinks / 2);

            if (startPage < 0) {
                endPage += startPage * -1;
                startPage = 0;
            }
            if ((totalPages - endPage) < 0) {
                startPage -= (totalPages - endPage) * -1;
            }

            if (startPage < 0) {
                startPage = 0;
            }
            if (endPage > totalPages) {
                endPage = totalPages;
            }

        }

        if (currentPage > 0) {
            //left arrow
            pagesText.push(
                <li key={"pagingLnk_LT"}>
                    <a id={"pagingLnk_" + (currentPage - 1)} onClick={this.changePage} >
                        <span id={"pagingLnk_" + (currentPage - 1)} className="icon-chevron-left" />
                    </a>
                </li>

            )
        }

        var x = startPage;
        while (x <= endPage) {
            if (x <= totalPages) {
                var selectedClass = "";
                if (x == currentPage) {
                    selectedClass = "pagingLnkSelected";
                }
                pagesText.push(
                    <li key={"pagingLnk_" + x}>
                        <a id={"pagingLnk_" + x} className={"pagingLnk " + selectedClass} onClick={this.changePage}>{x + 1}</a>
                    </li>
                );
            }
            x++;
        }

        if (currentPage < totalPages) {
            //right arrow
            pagesText.push(

                <li key={"pagingLnk_RT"}>
                    <a id={"pagingLnk_" + (currentPage + 1)} onClick={this.changePage}>
                        <span id={"pagingLnk_" + (currentPage + 1)} className="icon-chevron-right"></span>
                    </a>
                </li>

            )
        }

        return pagesText
    }
    render() {
        var pagesText = "";
        if (!this.props.totalRows || Number(this.props.totalRows) < 2) {
            return <span></span>
        } else {
            pagesText = this.generateLinks();
        }

        return <ul className="pagingList">
            {pagesText}
        </ul>

        return <div className="table1Paging">
            {this.props.text.length > 0 && <span style={{
                marginRight: "10px"
            }}>
                {this.props.text}
            </span>}

            {pagesText}
        </div>

    }
}