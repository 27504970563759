import React from 'react'
import { Container, Row, Col, Visible, Hidden } from 'react-grid-system';

import GraphNumberAndTypeOfCalls from './graphNumberAndTypeOfCalls.jsx'
import GraphBreakDownCalls from './graphBreakDownCalls.jsx'
import GraphRTFCalls from './graphRTFCalls.jsx'
import GraphCourtesyCalls from './graphCourtesyCalls.jsx'
import GraphServiceCalls from './graphServiceCalls.jsx'
import TablServiceStats from './tableServicePerformanceStatistics.jsx'
export default class ServiceIndex extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

        }
    }
    render() {
        if (this.props.invalidDateRange) {
            return <div></div>
        }
        return <Row>


            <Col xs={12} sm={12} md={12} lg={12}>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <div className="paper">
                            <h2>Number & type of calls</h2>
                            <GraphNumberAndTypeOfCalls {...this.props} />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={12} md={6} lg={6}>
                        <div className="paper">
                            <h2>Breakdown Calls</h2>
                            <GraphBreakDownCalls {...this.props} />
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={6}>
                        <div className="paper">
                            <h2>RTF Calls</h2>
                            <GraphRTFCalls {...this.props} />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={12} md={6} lg={6}>
                        <div className="paper">
                            <h2>Courtesy Visits</h2>
                            <GraphCourtesyCalls {...this.props} />
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={6}>
                        <div className="paper">
                            <h2>IT Calls</h2>
                            <GraphServiceCalls {...this.props} />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <div className="paper">
                            <h2>Service Performance Statistics</h2>
                            <TablServiceStats {...this.props} />
                        </div>
                    </Col>

                </Row>
            </Col>
        </Row>
    }
}
