import React from 'react'
import { Container, Row, Col, Visible, Hidden } from 'react-grid-system';
import UILIB from '../../common-objects/Lib'
import bgImage from '../../assetts/images/frontPage/bg1.jpg'
import Axios from '../../includes/axios';
import Utils from '../../config/utils'
import moment from 'moment';

export default class Kpax extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pageLoaded: false,
            searchText: "",
            devices: [],
            vaDevices: [],
            subSiteFilter: -1,
            subSites: []
        }
        this.getDevices = this.getDevices.bind(this);
        this.getVADevices = this.getVADevices.bind(this);
    }

    componentDidMount() {

        var subSites = this.props.subSites.map(site => {
            return { value: site.id, label: site.altName }
        })
        subSites = subSites.sort((a, b) => {
            var nameA = (a.label);
            var nameB = (b.label);
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;

            }
            // names must be equal
            return 0;

        })
        subSites.unshift({ value: -1, label: "All Sites" })

        this.setState({ subSites }, this.getVADevices());
    }


    getVADevices() {
        Axios.get(`/equipment?machinesOnly=true`).then(_equipment => {
            this.setState({ vaDevices: _equipment.data.equipment }, this.getDevices)
        })
    }

    getDevices() {
        Axios.get('/kpax/devices').then(data => {
            this.setState({ devices: data.data.devices, pageLoaded: true })
        })
    }

    render() {

        var deviceData = this.state.devices;
        if (this.state.searchText && this.state.searchText.length) {
            deviceData = deviceData.filter(dv => dv.SerialNumber.toLowerCase().indexOf(this.state.searchText.toLowerCase()) > -1)
        }

        if (this.state.subSiteFilter != -1) {
            deviceData = deviceData.filter(dv => {
                var found = false;
                return this.state.vaDevices.find(vd => vd.serialnumber == dv.SerialNumberOriginal && vd.customerId == this.state.subSiteFilter)
            })
        }

        return <div>
            {!this.state.pageLoaded && <div style={{ textAlign: "center" }}>
                <UILIB.LoadingIcon />
                <div>Loading Data</div>
            </div>}
            {this.state.pageLoaded && <Row>
                <Col xs={12} sm={6}>
                    <UILIB.Input type="text" placeholder="Search for Serial Number" onChange={(ev) => { this.setState({ searchText: ev.target.value }) }} value={this.state.searchText} />
                </Col>
                <Col xs={12} sm={6}>
                    <UILIB.Select data={this.state.subSites} onChange={(ev) => { this.setState({ subSiteFilter: ev.target.value }) }} />
                </Col>
                <Col xs={12}>
                    <div style={{ marginTop: "5px", marginBottom: "5px", textAlign: "right" }}>{deviceData.length + " devices"}</div>
                    <div className="paper">
                        {!deviceData.length && <div style={{ textAlign: "center" }}>No Devices Found - Please try expanding your filter</div>}
                        {deviceData.length > 0 && <div className="standardTable"><table width="100%" cellPadding="5" cellSpacing="2">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Last Check</th>
                                    <th>Serial No</th>
                                    <th>Device</th>
                                    <th>Site</th>
                                    <th>Location</th>
                                    <th>Host Name</th>
                                    <th>IP</th>


                                    <th>Levels</th>
                                    <th>Total</th>
                                    <th>Mono</th>
                                    <th>Colour</th>
                                </tr>
                            </thead>

                            {deviceData.map((device, index) => {
                                var black = 0;
                                var cyan = -1;
                                var magenta = -1;
                                var yellow = -1;

                                var total = 0;
                                var totalColor = 0;
                                var totalMono = 0;

                                var voSite = "";
                                var voLocation = "";
                                var foundVALoc = this.state.vaDevices.find(vd => vd.serialnumber == device.SerialNumberOriginal)
                                if (foundVALoc) {
                                    voSite = foundVALoc.address1
                                    voLocation = foundVALoc.Location
                                }

                                if (device.Supplies) {
                                    if (device.Supplies.Black) black = device.Supplies.Black[0];
                                    if (device.Supplies.Cyan) cyan = device.Supplies.Cyan[0];
                                    if (device.Supplies.Magenta) magenta = device.Supplies.Magenta[0];
                                    if (device.Supplies.Yellow) yellow = device.Supplies.Yellow[0];
                                }
                                if (device.Meters) {
                                    if (device.Meters.EngineCount) total = device.Meters.EngineCount[0];
                                    if (device.Meters.TotalMono) totalMono = device.Meters.TotalMono[0];
                                    if (device.Meters.TotalColor) totalColor = device.Meters.TotalColor[0];
                                }
                                var daysOffline = 999;
                                if (device.LastCheck) {
                                    daysOffline = moment().diff(device.LastCheck, 'days')
                                }

                                return <tr key={"kpDev" + index}>
                                    <td><div className="icon-printer" style={{ color: daysOffline >= 2 ? 'red' : '' }} /></td>
                                    <td><span style={{ color: daysOffline >= 2 ? "red" : "" }}>{Utils.formatDateTime(device.LastCheck, 2)}</span></td>
                                    <td>{device.SerialNumber}</td>
                                    <td>{device.Model}</td>
                                    <td>{voSite}</td>
                                    <td>{voLocation}</td>

                                    <td style={{ wordBreak: "break-word", minWidth: "200px" }}>{device.HostName}</td>
                                    <td>{device.IP}</td>


                                    <td width="150">
                                        <UILIB.LineGraph min={0} max={100} value={black} color="#000000" outerStyle={{ width: "30px", height: "10px", display: "inline-block" }} alt={"Black " + black + "%"} />
                                        {(cyan != -1) && <UILIB.LineGraph min={0} max={100} value={cyan} color="#0093D3" outerStyle={{ width: "30px", height: "10px", display: "inline-block" }} alt={"Cyan " + cyan + "%"} />}
                                        {(magenta != -1) && <UILIB.LineGraph min={0} max={100} value={magenta} color="#CC006B" outerStyle={{ width: "30px", height: "10px", display: "inline-block" }} alt={"Magenta " + magenta + "%"} />}
                                        {(yellow != -1) && <UILIB.LineGraph min={0} max={100} value={yellow} color="#FFF10C" outerStyle={{ width: "30px", height: "10px", display: "inline-block" }} alt={"Yellow " + yellow + "%"} />}
                                    </td>
                                    <td>{total ? total : <span className="text-grey">N/A</span>}</td>
                                    <td>{totalMono ? totalMono : <span className="text-grey">N/A</span>}</td>
                                    <td>{totalColor ? totalColor : <span className="text-grey">N/A</span>}</td>
                                </tr>

                            })}
                        </table>
                        </div>}
                    </div>
                    <div style={{ marginTop: "5px", marginBottom: "5px", textAlign: "right" }}>{deviceData.length + " devices"}</div>
                </Col>
            </Row>
            }
        </div>
    }
}
