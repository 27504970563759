import React from 'react'
import moment from 'moment';
import Axios from '../../../includes/axios';
import UILIB from '../../../common-objects/Lib'
import Utils from '../../../config/utils'
export default class TableVolumeStatisticsSummary extends React.Component {
    constructor(props) {
        super(props);
        let timer = undefined;
        this.state = {
            graphData: [],
            loading: true
        }
        this.getStats = this.getStats.bind(this);
    }

    componentDidMount() {
        this.getStats(false, this.props);
    }
    componentWillUnmount() {
        clearTimeout(this.timer)
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.startDate != this.props.startDate || nextProps.endDate != this.props.endDate) this.getStats(true, nextProps);
    }
    getStats(forced, props) {
        var self = this;
        if (forced) this.setState({ loading: true })
        var self = this;
        clearTimeout(self.timer)
        var qs = "";
        if (props.startDate && props.endDate) qs = `?start=${props.startDate}&end=${props.endDate}`
        Axios.get(`/dashboard/statsVolumeStatisticsSummary` + qs).then(_res => {
            self.setState({ graphData: _res.data.results, loading: false })
            self.timer = setTimeout(() => { self.getStats(false, props) }, 160000)
        }).catch(() => {
            self.timer = setTimeout(() => { self.getStats(false, props) }, 160000)
        })
    }


    render() {
        if (this.state.loading) return <div style={{ textAlign: "center" }}><UILIB.LoadingIcon /></div>

        var totalMono = 0;
        this.state.graphData.forEach(gD => { totalMono += gD.monoTotal });
        var totalColour = 0;
        this.state.graphData.forEach(gD => { totalColour += gD.colourTotal });
        var totalVolume = 0;
        this.state.graphData.forEach(gD => { totalVolume += gD.volumesTotal });
        var totalAverage = 0;

        this.state.graphData.forEach(gD => {
            var average = gD.volumesTotal / gD.volumesCount;
            if (isNaN(average)) average = 0;
            totalAverage += average
        })

        var totalColourPct = 0;
        this.state.graphData.forEach(gD => {
            var totalCPct = (gD.colourTotal / gD.volumesTotal) * 100;
            if (isNaN(totalCPct) || totalCPct == Infinity) totalCPct = 0;
            totalColourPct += totalCPct;
        });
        totalColourPct = totalColourPct / this.state.graphData.length;
        if (isNaN(totalColourPct) || totalColourPct == Infinity) totalColourPct = 0;

        var totalMeterageSpend = 0;
        this.state.graphData.forEach(gD => { totalMeterageSpend += gD.meterTotal });

        return <div className="standardTable">
            <i><b>Note:</b> The Number of devices shown below is the actual amount of device's invoiced in that period and the volume is calculated from those devices only.</i>
            <div className="mar-b15"></div>
            <table width="100%" cellPadding="0" cellSpacing="0">
                <thead>
                    <tr>
                        <th width="20%"></th>
                        {this.state.graphData.map((row, index) => {
                            return <th key={"th_r_" + index} width={60 / this.state.graphData.length + "%"}>{Utils.monthName(row.month, true)}</th>
                        })}
                        <th width="20%">Totals</th>
                    </tr>
                </thead>
                <tr>
                    <td>
                        Mono Total
                    </td>
                    {this.state.graphData.map((row, index) => {
                        return <td key={"td_mt_" + index}>{Utils.formatNumber(row.monoTotal, 0)}</td>
                    })}
                    <td>{Utils.formatNumber(totalMono, 0)}</td>
                </tr>
                <tr>
                    <td>
                        Colour Total
                    </td>
                    {this.state.graphData.map((row, index) => {
                        return <td key={"td_mt_" + index}>{Utils.formatNumber(row.colourTotal, 0)}</td>
                    })}
                    <td>{Utils.formatNumber(totalColour, 0)}</td>
                </tr>
                <tr>
                    <td>
                        <strong>Total Volume</strong>
                    </td>
                    {this.state.graphData.map((row, index) => {
                        return <td key={"td_mt_" + index}><strong>{Utils.formatNumber(row.volumesTotal, 0)}</strong></td>
                    })}
                    <td>{Utils.formatNumber(totalVolume, 0)}</td>
                </tr>
                <tr>
                    <td>
                        <strong>Colour %</strong>
                    </td>
                    {this.state.graphData.map((row, index) => {
                        var colourPct = (row.colourTotal / row.volumesTotal) * 100;
                        if (isNaN(colourPct)) colourPct = 0;
                        if (colourPct == Infinity) colourPct = 0;
                        return <td key={"td_mt_" + index}><strong>{Utils.formatNumber(colourPct, 2)}%</strong></td>
                    })}
                    <td>{Utils.formatNumber(totalColourPct, 2)}%</td>
                </tr>
                <tr>
                    <td>
                        <strong>Service Spend</strong>
                    </td>
                    {this.state.graphData.map((row, index) => {
                        return <td key={"td_mt_" + index}><strong>£{Utils.formatNumber(row.meterTotal, 2)}</strong></td>
                    })}
                    <td>£{Utils.formatNumber(totalMeterageSpend, 2)}</td>
                </tr>
            </table>
        </div>
    }
}
