import React, { Component } from 'react';
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";

class Input extends Component {
    constructor(props) {
        super(props);
        let timer;
        this.state = {
        }

        this.changeDate = this.changeDate.bind(this);

    }

    changeDate(value) {
        this.props.onChange(value, this.props.name)
    }

    render() {

        var className = "textInput";
        if (this.props.className) {
            className += " " + this.props.className;
        }
        if (this.props.error && this.props.error.length) {
            className += " errored";
        }

        const isDisabled = this.props.disabled;

        if (this.props.type == "date") {
            var dateFormat = "dd/MM/yyyy";
            if (this.props.dateFormat) dateFormat = this.props.dateFormat
            return <div className="textInputHolder" style={this.props.outerStyle}>
                <DatePicker onChange={this.changeDate} selected={this.props.value} className={className} dateFormat={dateFormat} name={this.props.name} maxDate={this.props.maxDate} minDate={this.props.minDate} showQuarterYearPicker={this.props.showQuarterYearPicker} showMonthYearPicker={this.props.showMonthYearPicker} disabled={isDisabled} />
                {(this.props.error && this.props.error.length > 0) && <div className="errorTextHolder">{this.props.error}</div>}
            </div>
        }

        return <div className="textInputHolder" style={this.props.outerStyle}>

            <input {...this.props} className={className} disabled={isDisabled} />
            {(this.props.error && this.props.error.length > 0) && <div className="errorTextHolder">{this.props.error}</div>}
        </div>

    }
}

export default Input;