import React from 'react'

export default class TableSortLabel extends React.Component {
    constructor(props) {
        super(props);
        this.state = { animationClass: "", currentDirectionCSS: null }
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.direction != this.props.direction) {

            var directionClass = "";
            var currentDirectionCSS = "";

            if (this.props.active === true) {
                currentDirectionCSS = null;
                if (nextProps.direction === "asc") {
                    directionClass = "headerIconUp";

                }
                if (nextProps.direction === "desc") {
                    directionClass = "headerIconDown";
                }

            }
            else {
                if (nextProps.direction === "asc") {
                    currentDirectionCSS = { transform: "rotate(180deg)" }
                }
                if (nextProps.direction === "desc") {
                    currentDirectionCSS = { transform: "rotate(0deg)" }
                }
            }
            this.setState({ animationClass: directionClass, currentDirectionCSS: currentDirectionCSS })
        }
        return false;
    }

    componentDidMount() {
        this.setState({ firstRun: false });
    }

    render() {

        var classes = "header-label";

        if (this.props.active === true) {
            classes += " sortActive";
        }

        return <div onClick={this.props.onClick} className={classes}>
            {this.props.children}

        </div>


    }
}
