import React from 'react'
import { Container, Row, Col, Visible, Hidden } from 'react-grid-system';
import UILIB from '../../../../common-objects/Lib'
import axios from '../../../../includes/axios';
import Utils from '../../../../config/utils';

export default class ServiceCallHistory extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            tableDataRaw: [],
            tableData: [],
            pageLoaded: false,
            subSites: [],
            subsiteFilter: -1
        }

        this.getData = this.getData.bind(this)
    }

    componentWillMount() {
        var subSites = this.props.subSites.map(site => {
            return { value: site.id, label: site.altName }
        })

        subSites.unshift({ value: -1, label: "All Sites" })
        this.setState({ subSites }, this.getData());
    }

    actionChangefilter(value) {
      const result = (Number(value) === -1)
        ?  this.state.tableDataRaw
        :  this.state.tableDataRaw.filter(x => Number(x.customerId) === Number(value))
      this.setState({ ...this.state, tableData: result })
    }

    getData() {
      axios.get(`/work?daysSinceToday=365&includeEquipment=true&onlyClosed=true&includeActionCode=true`).then(_response => {
          this.setState({ tableData: _response.data.workUnits, tableDataRaw: _response.data.workUnits, pageLoaded: true })
      })
  }

    render() {
        if (!this.state.pageLoaded) return <div style={{ textAlign: "center" }}><UILIB.LoadingIcon /></div>
        return <div className="paper">
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <UILIB.Select data={this.state.subSites} onChange={(ev) => { this.actionChangefilter(ev.target.value) }} />
                <div>Showing calls that are less than 1 year old</div>
            </div>

            <div className="standardTable">
                <table width="100%" cellPadding="10" cellSpacing="0">
                    <thead>
                        <tr>
                            <th>Ref No</th>
                            <th>Your Ref: </th>
                            <th>Serial</th>
                            <th>Asset No: </th>
                            <th>Date</th>
                            <th>Site:</th>
                            <th>Issue</th>
                            <th>Action Taken</th>
                        </tr>
                    </thead>
                    {this.state.tableData.map((item, index) => {
                        return <tr key={"tr_" + index}>

                            <td>{item.Reference}</td>
                            <td></td>
                            <td>{item.SerialNumber}</td>
                            <td>{item.AssetNumber}</td>
                            <td>{Utils.formatDateTime(item.LoggedDate)}</td>
                            <td>{item.Location}</td>
                            <td>{item.SymptomCodeText}</td>
                            <td>{item.ActionCodeText}</td>

                        </tr>
                    })}
                </table>
            </div>
        </div>
    }
}
