import React from 'react'
import { Container, Row, Col, Visible, Hidden } from 'react-grid-system';
import UILIB from '../../common-objects/Lib'
import bgImage from '../../assetts/images/frontPage/bg6.jpg'
import Axios from '../../includes/axios';


export default class Admin extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pageLoaded: false,
            accountUsers: [],
            addingUser: false,
            addingUserPost: false,
            addUserEmail: "",
            removeUserId: -1,
            disabledPerms: false
        }

        this.getAccountUsers = this.getAccountUsers.bind(this);
        this.addUser = this.addUser.bind(this);
        this.deleteUser = this.deleteUser.bind(this);
        this.toggleAdmin = this.toggleAdmin.bind(this);
        this.updateUser = this.updateUser.bind(this);
        this.togglePerm = this.togglePerm.bind(this);
        this.resetPassword = this.resetPassword.bind(this);
        this.toggleSitePerm = this.toggleSitePerm.bind(this);
        this.toggleSitePermAll = this.toggleSitePermAll.bind(this);
    }

    componentDidMount() {

        this.getAccountUsers();
    }

    getAccountUsers() {
        Axios.get('/admin/users').then(data => {
            this.setState({ accountUsers: data.data.accounts, pageLoaded: true, disabledPerms: false })
        })
    }

    addUser() {
        this.setState({ addingUserPost: true })
        Axios.post('/admin/user', { emailAddress: this.state.addUserEmail }).then(data => {
            this.setState({ addingUserPost: false, addingUser: false, addUserEmail: "" }, this.getAccountUsers)
        }).catch(err => {
            var error = "There was a problem adding the user. Please try again";
            if (err.data && err.data.error) error = err.data.error;
            this.setState({ addingUserPost: false, addUserEmailError: error })
        })
    }

    deleteUser(userId) {
        Axios.delete('/admin/user/' + userId).then(data => {
            this.setState({ removeUserId: -1 }, this.getAccountUsers)
        }).catch(err => {
            this.setState({ removeUserId: -1 }, this.getAccountUsers)
        })
    }

    toggleAdmin(event, userObjIn) {
        var userObj = JSON.parse(JSON.stringify(userObjIn))

        if (userObj.isAdmin == "1" || userObj.isAdmin == 1) {

            userObj.isAdmin = "0"
        }
        else {
            userObj.isAdmin = "1";
        }
        this.updateUser(userObj)
    }

    updateUser(userObj) {
        var self = this;
        this.setState({ disabledPerms: true })
        Axios.put('/admin/user/' + userObj.id + '/perms', {
            user: userObj
        }).then(() => {
            self.getAccountUsers();
        })
    }

    togglePerm(perm, user) {
        if (!user.permissions[perm.value]) {
            user.permissions[perm.value] = 0;
        }
        if (user.permissions[perm.value]) {
            user.permissions[perm.value] = 0;
        }
        else {
            user.permissions[perm.value] = 1;
        }
        var self = this;
        Axios.put('/admin/user/' + user.id + '/perms', {
            user: user
        }).then(() => {
            self.getAccountUsers();
        })
    }

    toggleSitePerm(perm, user) {

        if (!user.sitePermissions[perm.value]) {
            user.sitePermissions[perm.value] = 0;
        }
        if (user.sitePermissions[perm.value]) {
            user.sitePermissions[perm.value] = 0;
        }
        else {
            user.sitePermissions[perm.value] = 1;
        }
        var self = this;
        Axios.put('/admin/user/' + user.id + '/perms', {
            user: user
        }).then(() => {
            self.getAccountUsers();
        })
    }

    toggleSitePermAll(allOn, user) {
        var restricted = 1;
        if (allOn) restricted = 0;

        var permissions = user.sitePermissions;
        this.props.subSites.forEach((site) => {
            permissions[site.id] = restricted;
        })
        this.props.customers.forEach((site) => {
            permissions[site.id] = restricted;
        })
        user.sitePermissions = permissions;
        var self = this;
        Axios.put('/admin/user/' + user.id + '/perms', {
            user: user
        }).then(() => {
            self.getAccountUsers();
        })
    }

    resetPassword(userId) {
        var self = this;
        Axios.put('/admin/user/' + userId + '/resetPassword').then(() => {

        })
    }
    render() {

        if (!this.state.pageLoaded) return <Row><Col xs={12} align="center"><UILIB.LoadingIcon /></Col></Row>
        var validUserEmail = false;
        if (this.state.addingUser) {
            if (this.state.addUserEmail && this.state.addUserEmail.length > 5 && this.state.addUserEmail.indexOf("@") > 0 && this.state.addUserEmail.indexOf(".") > 0) {
                validUserEmail = true;
            }
        }

        var permissions = [
            { label: "Dashboard", value: "dashboard" },
            { label: "Guides", value: "guides" },
            { label: "Request Consumables", value: "requestConsumables" },
            { label: "Request Engineer", value: "requestEngineer" },
            { label: "Cancel Engineer", value: "cancelEngineer" },
            { label: "Request Recycling Box", value: "requestRecycling" },
            { label: "Request Other Info", value: "requestOtherInfo" },
            { label: "Service History", value: "serviceHistory" },
            { label: "Machine Move Request", value: "machineMoveRequest" },
            { label: "Kpax", value: "kpax" },
        ]

        var permissionSites = [];
        this.props.subSites.forEach(site => {
            var finalName = site.name;
            if (site.town && site.town.length > 2) finalName += ". " + site.town;
            if (site.postcode && site.postcode.length > 2) finalName += ". " + site.postcode;
            permissionSites.push({ value: site.id, label: finalName })
        })
        this.props.customers.forEach(site => {
            if (permissionSites.find(f => f.value == site.id)) return;
            var finalName = site.Name;
            if (site.Town && site.Town.length > 2) finalName += ". " + site.Town;
            if (site.postCode && site.postCode.length > 2) finalName += ". " + site.postCode;
            permissionSites.push({ value: site.id, label: finalName })
        })


        return <div>
            <Row className="mar-t15">
                <Col xs={12} sm={12} md={12} lg={12} style={{ marginBottom: "20px" }}>
                    <div className="overViewHeader" style={{ backgroundImage: `url(${bgImage})` }}>
                        <div className="overViewHeaderText">
                            <h1>Admin</h1>
                            <p style={{ fontSize: "12px" }}>Invite and manage users on your account</p>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={12} className="mar-b15">
                    <div style={{ border: "1px dotted red", padding: "15px", backgroundColor: "#F0D9D9" }}>
                        <div>To comply with GDPR, please ensure that any users who no longer work for the organisation are regularly removed.</div>
                        <div>It is your responsibility to maintain the users listed below.</div>
                    </div>
                </Col>
            </Row>

            {this.state.addingUser && <Row >
                <Col>
                    <div className="paper">
                        <h2>Add a new user</h2>
                        <p>Please enter an email address for the person who you would like to invite. We will send them an email containing a link to get started</p>

                        <UILIB.Input type="email" name="emailAddress" value={this.state.addUserEmail} error={this.state.addUserEmailError} onChange={(event) => { this.setState({ addUserEmail: event.target.value }) }} placeholder="users@emailaddress.com" />

                        {this.state.addingUserPost && <UILIB.LoadingIcon style={{ width: "80px", height: "80px" }} />}
                        {!this.state.addingUserPost && <UILIB.Button value="Invite" disabled={!validUserEmail} onClick={this.addUser} />}
                    </div>
                </Col>
            </Row>}
            {!this.state.addingUser && <Row>
                <Col style={{ textAlign: "right" }} xs={12} className="mar-b15">
                    <UILIB.Button value="Add another User" onClick={() => { this.setState({ addingUser: true }) }} />
                </Col>
                <Col xs={12}>

                    <div className="paper">
                        <div className="standardTable">
                            <table width="100%" cellPadding="5" cellSpacing="2">
                                <thead>
                                    <tr>
                                        <th width="10"></th>
                                        <th width="300">Name</th>

                                        <th width="400">Permissions</th>
                                        <th align="right" width="50">Options</th>
                                    </tr>
                                </thead>
                                {this.state.accountUsers.map((user, userIndex) => {
                                    return <tr key={"userHolder_" + userIndex}>
                                        <td valign="top"><div className="icon icon-man2" /></td>
                                        <td valign="top">
                                            <div>{user.firstName + " " + user.lastName}</div>
                                            <div style={{ fontSize: "12px" }}>{user.emailAddress}</div>
                                        </td>

                                        <td valign="top">
                                            {this.props.account.id != user.id && <div>
                                                <div>
                                                    <UILIB.Select disabled={this.state.disabledPerms} name="isAdmin" value={user.isAdmin} data={[{ label: "Admin", value: "1" }, { label: "User", value: "0" }]} onChange={(event) => { this.toggleAdmin(event, user) }}></UILIB.Select>

                                                    {(user.isAdmin != 1 && !this.state.disabledPerms) && <div>
                                                        <div style={{ border: "1px solid #CCCCCC", padding: "20px", marginBottom: "15px" }}>
                                                            <div style={{ marginBottom: "5px", fontWeight: "bold" }}>Permissions</div>
                                                            <p>Select the areas of the Customer Portal you would like this user to have access to</p>
                                                            {permissions.map((perm, permIndex) => {
                                                                var isChosen = false;
                                                                if (user.permissions[perm.value] && user.permissions[perm.value] == 1) isChosen = true;
                                                                return <div
                                                                    key={"userPerm_" + userIndex + "_" + permIndex}
                                                                    onClick={() => { this.togglePerm(perm, user) }}
                                                                    alt="Click to Toggle"
                                                                    title="Click to Toggle"
                                                                    style={{
                                                                        cursor: "pointer",
                                                                        borderRadius: "20px",
                                                                        backgroundColor: isChosen ? "green" : "grey",
                                                                        color: "white",
                                                                        padding: "10px",
                                                                        textAlign: "center",
                                                                        display: "inline-block",
                                                                        marginRight: "5px",
                                                                        fontSize: "12px",
                                                                        marginBottom: "5px"
                                                                    }}>
                                                                    <span className={isChosen ? "icon-check" : "icon-cross"} /> {perm.label}</div>
                                                            })}
                                                        </div>

                                                        {permissionSites.length > 0 && <div style={{ border: "1px solid #CCCCCC", padding: "20px", marginBottom: "15px" }}>
                                                            <div style={{ marginBottom: "5px", fontWeight: "bold" }}>Sites Available</div>
                                                            <p>Select the sites this user is able to see in the customer portals.</p>
                                                            {permissionSites.map((subSite, subIndex) => {
                                                                var isRestricted = false;
                                                                if (user.sitePermissions[subSite.value] && user.sitePermissions[subSite.value] == 1) isRestricted = true;
                                                                var finalName = subSite.label;
                                                                return <div
                                                                    key={"sitePerm_" + userIndex + "_" + subIndex}
                                                                    onClick={() => { this.toggleSitePerm(subSite, user) }}
                                                                    alt="Click to Toggle"
                                                                    title="Click to Toggle"
                                                                    style={{
                                                                        cursor: "pointer",
                                                                        borderRadius: "20px",
                                                                        backgroundColor: isRestricted ? "grey" : "green",
                                                                        color: "white",
                                                                        padding: "10px",
                                                                        textAlign: "center",
                                                                        display: "inline-block",
                                                                        marginRight: "5px",
                                                                        fontSize: "12px",
                                                                        marginBottom: "5px"
                                                                    }}>
                                                                    <span className={isRestricted ? "icon-cross" : "icon-check"} /> {finalName}
                                                                </div>

                                                            })}

                                                            <div style={{ marginTop: "10px" }}>
                                                                <UILIB.Button value="Select All" style={{ marginRight: "5px" }} onClick={() => { this.toggleSitePermAll(true, user) }} />
                                                                <UILIB.Button value="Unselect All" onClick={() => { this.toggleSitePermAll(false, user) }} />
                                                            </div>

                                                        </div>}
                                                    </div>
                                                    }
                                                </div>
                                            </div>
                                            }
                                        </td>
                                        <td valign="top">
                                            {this.props.account.id != user.id && <div style={{ textAlign: "right" }}>
                                                {(this.state.removeUserId != user.id) && <div>
                                                    <UILIB.Button value="Remove" onClick={() => { this.setState({ removeUserId: user.id }) }} style={{ width: "100%", marginBottom: "5px" }} />
                                                    <UILIB.Button value="Send Password Reminder" onClick={() => { this.resetPassword(user.id) }} style={{ width: "100%" }} />
                                                </div>}
                                                {(this.state.removeUserId == user.id) && <div>

                                                    <UILIB.Button className="red mar-b10" value="Delete Them!" onClick={() => { this.deleteUser(user.id) }} style={{ width: "100%" }} />

                                                    <UILIB.Button className="green" value="Nevermind" onClick={() => { this.setState({ removeUserId: -1 }) }} style={{ width: "100%" }} />
                                                </div>}
                                            </div>}
                                        </td>
                                    </tr>
                                })}
                            </table>
                        </div>
                    </div>
                </Col>
            </Row >
            }
        </div>
    }
}
