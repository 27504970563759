import React from 'react'
import { Container, Row, Col, Visible, Hidden } from 'react-grid-system';
import UILIB from '../../common-objects/Lib'
import Axios from '../../includes/axios';
import Utils from '../../config/utils'
import moment from 'moment';
export default class KpaxAlerts extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pageLoaded: false,
            alerts: [],
            addingAlert: 0,
            addingAlertId: -1,
            alertEmailAddress: "",
            alertEmailAddressErr: "",
            alertTypes: [{
                id: 1,
                title: "Paper Out Alert",
                desc: "Receive alerts every time one of your devices runs low on paper.",
                guid: "91ed2686-c8a6-4316-857d-d523c22381cb",
                icon: "icon-document"
            },
            {
                id: 2,
                title: "Low Toner Alert",
                desc: "Receive alerts every time one of your devices runs low on toner.",
                guid: "3d920fe9-a3b1-4dff-bf6e-ffa04417db49",
                icon: "icon-battery-low1"
            },
            {
                id: 3,
                title: "Problem Machine Alerts",
                desc: "Receive an alert whenever a machine errors. These alerts include Paper Jams, Door Opens, Error Alerts, etc",
                guid: "649438bb-6c8a-4e2a-b61e-8d34bb763289",
                icon: "icon-shredder"
            },
            {
                id: 4,
                title: "Agent Offline",
                desc: "Receive an alert if your Kpax Reporting Agent(s) go offline",
                guid: "903869a6-6303-4b51-a95f-19ad3b2d3db8",
                icon: "icon-wifi-alert"
            },
            {
                id: 5,
                title: "Device Offline",
                desc: "Receive an alert if any of your devices stop reporting.",
                guid: "0359acf4-dec8-49ed-9239-4177ff4fda1a",
                icon: "icon-wifi-blocked"
            }]
        }

        this.getAlerts = this.getAlerts.bind(this);
        this.deleteAlert = this.deleteAlert.bind(this);
        this.addAlert = this.addAlert.bind(this);
        this.addAlertSelect = this.addAlertSelect.bind(this);
        this.changeInput = this.changeInput.bind(this);
        this.submitAddAlert = this.submitAddAlert.bind(this);
        this.closeAddForm = this.closeAddForm.bind(this);
    }

    componentDidMount() {
        this.getAlerts();
    }

    getAlerts() {
        Axios.get('/kpax/alerts').then(data => {
            this.setState({
                alerts: data.data.alerts.map(al => {
                    al.Name = al.Name.replace("DMC Portal", "Agilico Portal");
                    return al
                }),
                pageLoaded: true
            })
        })
    }

    deleteAlert(alertId) {
        var self = this;
        Axios.delete('/kpax/alerts/' + alertId).then(() => {
            self.getAlerts();
        })
    }

    addAlert() {
        this.setState({ addingAlert: 1 })
    }

    addAlertSelect(id) {
        this.setState({ addingAlert: 2, addingAlertId: id })
    }
    changeInput(event) {
        this.setState({ [event.target.name]: event.target.value })
    }
    submitAddAlert() {
        var alertEmailAddressErr = "";
        if (!this.state.alertEmailAddress || !this.state.alertEmailAddress.length) {
            alertEmailAddressErr = "Please enter an email address"
        }
        if (alertEmailAddressErr == "" && (this.state.alertEmailAddress.indexOf(".") < 0 || this.state.alertEmailAddress.indexOf("@") < 0)) {
            alertEmailAddressErr = "Please enter a valid email address"
        }
        this.setState({ alertEmailAddressErr })
        if (alertEmailAddressErr != "") {
            return;
        }
        var selectedAlert = this.state.alertTypes.find(at => at.id == this.state.addingAlertId)
        Axios.post('/kpax/alerts', { guid: selectedAlert.guid, emailAddress: this.state.alertEmailAddress }).then(data => {
            this.closeAddForm();
        })

    }

    closeAddForm() {
        this.setState({ addingAlert: 0, addingAlertId: -1, alertEmailAddress: "", alertEmailAddressErr: "" }, this.getAlerts)
    }
    render() {
        if (!this.state.pageLoaded) return <div style={{ textAlign: "center" }}><UILIB.LoadingIcon /></div>;

        if (this.state.addingAlert == 1) return <div>
            <Row>
                <Col xs={12}>
                    <div className="paper">
                        <h3>Select an Alert Type to Receive</h3>
                        <Row>
                            {this.state.alertTypes.map((alertType, index) => {
                                return <Col xs={12} sm={12} md={12} lg={6}>
                                    <div className={"paper"} key={"subPage_" + index} style={{ minHeight: "130px" }}>
                                        <Row style={{ marginBottom: "-15px" }}>
                                            <Col xs={12} sm={12} md={1} lg={1} className="text-center mar-b15">
                                                <div className={"icon " + alertType.icon} style={{ fontSize: "30px" }} />
                                            </Col>
                                            <Col xs={12} sm={12} md={7} lg={6} className="mar-b15">
                                                <div style={{ fontWeight: "bold", marginBottom: "10px" }}><a onClick={() => { this.addAlertSelect(alertType.id) }}>{alertType.title}</a></div>
                                                <div>{alertType.desc}</div>
                                            </Col>
                                            <Col xs={12} sm={12} md={4} lg={5} className="text-right">
                                                <UILIB.Button value="Select" onClick={() => { this.addAlertSelect(alertType.id) }} />
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            })}
                        </Row>
                    </div>
                </Col>
                <Col style={{ textAlign: "center" }} xs={12} className="mar-b15">
                    <UILIB.Button value="Back" onClick={this.closeAddForm} />
                </Col>
            </Row>
        </div>

        if (this.state.addingAlert == 2) {
            var selectedAlert = this.state.alertTypes.find(at => at.id == this.state.addingAlertId)
            return <div>
                <Col xs={12}>
                    <div className="paper secondary">
                        <h3 style={{ color: "white" }}>{selectedAlert.title}</h3>
                        <p style={{ color: "white" }}>{selectedAlert.desc}</p>
                    </div>
                </Col>
                <Col xs={12}>
                    <div className="paper">
                        <h3>Email Address to be sent to</h3>
                        <UILIB.Input type="email" placeHolder="your@emailaddress.com" name="alertEmailAddress" value={this.state.alertEmailAddress} onChange={this.changeInput} error={this.state.alertEmailAddressErr} />
                        <UILIB.Button value="Add the Alert" onClick={this.submitAddAlert} />
                    </div>
                </Col>
                <Col style={{ textAlign: "center" }} xs={12} className="mar-b15">
                    <UILIB.Button value="Back" onClick={this.closeAddForm} />
                </Col>
            </div>
        }
        return <Row>
            <Col style={{ textAlign: "right" }} xs={12} className="mar-b15">
                <UILIB.Button value="Add an Alert" onClick={this.addAlert} />
            </Col>
            <Col xs={12}>
                <div className="paper">
                    <div className="standardTable"><table width="100%" cellPadding="5" cellSpacing="2">
                        <thead>
                            <tr>
                                <th width="20"></th>
                                <th>Alert Type</th>
                                <th>Date Created</th>
                                <th>Sent To</th>
                                <th width="50"></th>
                            </tr>
                        </thead>

                        {(!this.state.alerts.length && <td colSpan="5" align="center">
                            <div style={{ marginBottom: "10px", fontWeight: "bold" }}>You have not alerts setup</div>
                            <div><a onClick={this.addAlert}>Click Here to Add an Alert</a>
                            </div>
                        </td>)}
                        {(this.state.alerts.length > 0) && this.state.alerts.map((alert, index) => {
                            return <tr>
                                <td width="20" align="center"><div className="icon icon-bubble-alert" /></td>
                                <td>{alert.Name}</td>
                                <td>{Utils.formatDateTime(alert.CreatedOn, 2)}</td>
                                <td>{alert.Email}</td>
                                <td width="50" align="center"><UILIB.Button value="Delete" onClick={() => { this.deleteAlert(alert.NotificationId) }} /></td>
                            </tr>
                        })}

                    </table>
                    </div>
                </div>
            </Col>
        </Row>

    }
}
