import React from 'react';
import UILIB from '../../common-objects/Lib'
import { Container, Row, Col, Visible, Hidden } from 'react-grid-system';
import bgImage from '../../assetts/images/frontPage/bg5.jpg'
export default class TelecomsAndIt extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return <div>
            <Row className="mar-t15">
                <Col xs={12} sm={12} md={12} lg={12} style={{ marginBottom: "20px" }}>
                    <div className="overViewHeader" style={{ backgroundImage: `url(${bgImage})`}}>
                        <div className="overViewHeaderText">
                            <h1>Telecoms &amp; IT</h1>
                            Hosted business VoIP telephony , powered by Agilico Connect<br />
                            Wondering how your device works? Or want to get the most out of it? Take a look at some of our user guides below...
                        </div>
                    </div>
                </Col>
                <Col xs={12} sm={6}>
                    <div className="flex justifyCenter mar-b15">
                        <div className="overViewHeader" style={{ backgroundImage: `url(${bgImage})`, height: "380px" }}></div>
                    </div>
                    <div className="paper">
                        <h2>Make sure your teams and customers stay connected and collaborative.</h2>
                        <p>Traditional on-premise telephony is costly, inflexible and limiting for remote employees.</p>
                        <p>We can connect you to our cloud telephony platform – delivering an enriched communications
                            experience for your people, no matter where they are. Using just an internet connection, you’ll get all the
                            capability of traditional telephony, plus enhanced collaboration capabilities like: MS Teams integration,
                            messaging, conferencing and more…</p>
                        <p>We’ll provide enhanced communications for your people while reducing costs and complexity.</p>
                        <p>Find out more about Hosted VoIP</p>
                        <div className="text-right">
                            <UILIB.Button value={"Click Here"} onClick={() => { window.open('https://www.agilico.co.uk/business-hosted-voip/', '_blank') }} />
                        </div>
                    </div>
                </Col>
                
                <Col xs={12} sm={6}>
                    <div className="paper primary flex justifyCenter mar-b10">
                        <div className="mar-r10 mar-b15" style={{ flex: 1 }}>
                            <h2>Telecoms &amp; IT Portal</h2>
                            <p>Access your bespoke Telecoms & IT management portal.
                                (Existing customers) </p>
                        </div>
                        <div className="text-right" style={{ flex: 0 }}>
                            <UILIB.Button value="Login Here" onClick={() => { window.open('https://agilico.myportallogin.com/', '_blank'); }} />
                        </div>
                    </div>
                    <div className="paper  flex justifyCenter mar-b15">
                        <div className="mar-r10" style={{ flex: 1 }}>
                            <h2>Microsoft Teams Phone Integration</h2>
                            <p>Make and take calls directly within Microsoft Teams.
                                Discover how we can help you embrace a streamlined and unified approach
                                to communication.
                            </p>
                        </div>
                        <div className="text-right" style={{ flex: 0 }}>
                            <UILIB.Button value="Find out more" onClick={() => { window.open('https://www.agilico.co.uk/microsoft-teams-phone-system-integration/', '_blank'); }} />
                        </div>
                    </div>
                    <div className="paper  flex justifyCenter mar-b15">
                        <div className="mar-r10" style={{ flex: 1 }}>
                            <h2>Managed Print Services</h2>
                            <p>
                                Maximise the effectiveness and efficiency of your print infrastructure.
                                Build a highly dependable, secure and cost-effective print capability for your
                                teams with centralised control and optimisation…
                            </p>
                        </div>
                        <div className="text-right" style={{ flex: 0 }}>
                            <UILIB.Button value="Find out more" onClick={() => { window.open('https://www.agilico.co.uk/services/managed-print-services/', '_blank'); }} />
                        </div>
                    </div>
                    <div className="paper  flex justifyCenter mar-b15">
                        <div className="mar-r10" style={{ flex: 1 }}>
                            <h2>Information Management</h2>
                            <p>
                                Give your teams the documents and information they need to stay productive.
                                Digitise your documents, data and workflow and give your people easy access to
                                the processes and information they need, 24/7…
                            </p>
                        </div>
                        <div className="text-right" style={{ flex: 0 }}>
                            <UILIB.Button value="Find out more" onClick={() => { window.open('https://www.agilico.co.uk/services/information-management//', '_blank'); }} />
                        </div>
                    </div>

                </Col>
            </Row>
        </div >
    }
}