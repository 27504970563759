export function getFormData() {
  return {
    formData: {
        yourName: "",
        emailAddress: "",
        contactNumber: "",
        companyName: "",
        department: "",
        requestType: 0,
        requestBody: "",
        referenceNumber: "",
        jobNumber: "",
        attachments: {}
    },
    errorData: {
        yourName: "",
        emailAddress: "",
        contactNumber: "",
        companyName: "",
        department: "",
        requestType: "",
        requestBody: "",
        referenceNumber: "",
        jobNumber: "",
        generalError: "",
        attachments: ""
    },
    submittingForm: false,
    submittedForm: false
  }
}