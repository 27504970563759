import React from 'react'
import { Container, Row, Col, Visible, Hidden } from 'react-grid-system';
import Axios from 'axios';
import UILIB from '../../common-objects/Lib'
import Connections from '../../config/connections'

export default class Login extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            account: {
                emailAddress: "",
                password: ""
            },
            errors: {
                emailAddress: "",
                password: ""
            },
            generalError: "",
            submitting: false,
            isMigrated: false
        }

        this.updateInput = this.updateInput.bind(this);
        this.login = this.login.bind(this);
    }

    updateInput(event) {
        var account = this.state.account;
        account[event.target.name] = event.target.value;
        this.setState({ account })
    }

    login() {
        var self = this;

        var errors = {
            emailAddress: "",
            password: ""
        }
        var errored = false;

        if (!this.state.account.emailAddress || this.state.account.emailAddress.length < 3 || this.state.account.emailAddress.indexOf(".") < 0 || this.state.account.emailAddress.indexOf("@") < 0) {
            errors.emailAddress = "Please enter a valid email address";
            errored = true;
        }

        if (!this.state.account.password || this.state.account.password.length < 2) {
            errors.password = "Please enter a password which is 2 or more characters long";
            errored = true;
        }

        this.setState({ errors, submitting: errored != true });
        if (errored) return;

        Axios.post(Connections.apiUrl + "/login", this.state.account).then((response) => {
            if (response.data.migrated) {
                this.setState({ isMigrated: true, submitting: false });
            }
            else {
                sessionStorage.setItem("customerContext", response.data.context);
                localStorage.setItem(`accessToken_${response.data.context}`, response.data.accessToken)
                self.props.history.push('/overview')
            }
        }).catch(err => {
            var generalError = "There was a problem, please try logging in again";
            if (err && err.response && err.response.data && err.response.data.errMessage) {
                generalError = err.response.data.errMessage;
            }
            this.setState({ generalError, submitting: false })
        })
    }

    render() {

        return <div>
            <h3>Welcome to the Agilico Customer Portals</h3>
            {!this.state.isMigrated && (
                <div>
                    <p>Enter your email address and password to login</p>
                    <p className="mar-b10" style={{ fontWeight: "bold" }}>If you have not received a customer portal login yet please <a href="mailto:customercare@agilico.co.uk" style={{ textDecoration: "underlined", fontWeight: "bold" }}>contact us</a></p>
                    <p className="mar-b20">For Customers already with Portal Access please login below.</p>
                </div>
            )}

            {this.state.isMigrated && (
                <div className='migrationAlert'>
                    <p>Your account has been migrated to the new and improved version of the Customer Portals. Please <UILIB.Link hRef={`${process.env.REACT_APP_ENV_PORTAL_UI_URL}`}>Click Here</UILIB.Link> and log in to your new portal</p>
                </div>
            )}

            {!this.state.submitting && <div className="mar-b20">

                <UILIB.Input name="emailAddress" type="email" placeholder="Your Email Address" value={this.state.account.emailAddress} onChange={this.updateInput} error={this.state.errors.emailAddress} disabled={this.state.isMigrated} />
                <UILIB.Input name="password" type="password" placeholder="Your password" value={this.state.account.password} onChange={this.updateInput} error={this.state.errors.password} disabled={this.state.isMigrated} />

                {(this.state.generalError && this.state.generalError.length > 1) && <div className="errorTextHolder" style={{ marginBottom: "20px" }}>{this.state.generalError}</div>}

                <Row className="mar-b20">
                    <Col xs={12} sm={6}>
                        <UILIB.Button value="Login" onClick={this.login} disabled={this.state.isMigrated} />
                    </Col>
                    <Col xs={12} sm={6} align="right" justify="center">

                    </Col>
                </Row>
                <a onClick={() => { this.props.history.push("/passwordreminder") }}>Forgotten your Password</a>
            </div>}

            {this.state.submitting && <div style={{ textAlign: "center" }}>
                <div><UILIB.LoadingIcon /></div>
                <div className="text-small">Logging in...</div>
            </div>}
        </div>
    }
}
