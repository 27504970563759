import React, { Component } from 'react';

class Input extends Component {
    constructor(props) {
        super(props);
        let timer;
        this.state = {
        }

    }




    render() {

        var className = "textInput";
        if (this.props.className) {
            className += " " + this.props.className;
        }
        if (this.props.error && this.props.error.length) {
            className += " errored";
        }

        var data = JSON.parse(JSON.stringify(this.props.data));
        // delete this.props.data;

        if (this.props.placeholder && (!this.props.value || this.props.value == "-1")) {
            data.unshift({ data: -1, label: this.props.placeholder })
        }

        return <div className="textInputHolder">
            <select {...this.props} className={className}>
                {data.map(d => {
                    return <option value={d.value} >{d.label}</option>
                })}
            </select>
            {(this.props.error && this.props.error.length > 0) && <div className="errorTextHolder">{this.props.error}</div>}
        </div>

    }
}

export default Input;