import React from 'react'
import { Container, Row, Col, Visible, Hidden } from 'react-grid-system';
import UILIB from '../../common-objects/Lib'
import bgImage from '../../assetts/images/frontPage/bg6.jpg'

import WelcomeStats from './welcomeStats'
import GuidesWidget from './guidesWidget'
import NewsWidget from './newsWidget'
export default class OverView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        }


    }
    render() {

        var encUserId = "";
        if (this.props.account.oldUserId && this.props.account.oldUserId != "0") {
            encUserId = this.props.currCustomer.Reference + "_" + this.props.account.oldUserId;
        }
        return <Row className="mar-t15">
            <Col xs={12} sm={12} md={12} lg={12} style={{ marginBottom: "20px" }}>
                <div className="overViewHeader" style={{ backgroundImage: `url(${bgImage})` }}>
                    <div className="overViewHeaderText">
                        <h1>Welcome to your Print Portal.</h1>
                        Here you can access a range of materials and helpful tips to get the most out of your Printer Fleet & Document Tools & Services, to help you save time, effort and waste.
                        To get started please use the navigation buttons located at the top of the page.
                    </div>
                </div>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6}>
                <WelcomeStats account={this.props.account} currCustomer={this.props.currCustomer} history={this.props.history} />
                <div className="paper agilicoGrey">
                    <h2>Quick Links</h2>
                    <Row>
                        {((this.props.account.permissions.requestConsumables != undefined && this.props.account.permissions.requestConsumables == 1) || this.props.account.isAdmin == 1) && <Col xs={12} sm={6}>
                            <div className="overViewQuickLinks" onClick={() => { this.props.history.push('/support/requestconsumables') }}>
                                <span className="icon icon-printer"></span><span className="qlContent">Request Consumables</span>
                            </div>
                        </Col>}
                        {((this.props.account.permissions.requestEngineer != undefined && this.props.account.permissions.requestEngineer == 1) || this.props.account.isAdmin == 1) && <Col xs={12} sm={6}>
                            <div className="overViewQuickLinks" onClick={() => { this.props.history.push('/support/requestengineer') }}>
                                <span className="icon icon-wrench"></span><span className="qlContent">Open a Support Ticket</span>
                            </div>
                        </Col>}
                        {((this.props.account.permissions.serviceHistory != undefined && this.props.account.permissions.serviceHistory == 1) || this.props.account.isAdmin == 1) && <Col xs={12} sm={6}>
                            <div className="overViewQuickLinks" onClick={() => { this.props.history.push('/support/servicehistory') }}>
                                <span className="icon icon-history"></span><span className="qlContent">Service History</span>
                            </div>
                        </Col>}
                        <Col xs={12} sm={6}>
                            <div className="overViewQuickLinks" onClick={() => { this.props.history.push('/support/downloads') }}>
                                <span className="icon icon-files"></span><span className="qlContent">Downloads</span>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Col>
            
            <Col xs={12} sm={12} md={6} lg={6}>
                <NewsWidget />
                <div className="paper">
                    <h2>Useful Information</h2>
                    <GuidesWidget history={this.props.history} />
                </div>
            </Col>

        </Row>
    }
}
