import React from 'react'
import { Container, Row, Col, Visible, Hidden } from 'react-grid-system';
import UILIB from '../../../common-objects/Lib'
import bgImage from '../../../assetts/images/frontPage/bg6.jpg'
import axios from '../../../includes/axios';
import { getFormData } from './defaults';
export default class MachineMoveRequest extends React.Component {
    constructor(props) {
        super(props);

        var deviceDefinition = {
            "Qty": "",
            "Model & Items": "",
            "Device": false,
            "Serial": "",
            "DMC Asset No": "",
            "A4 / A3": "",
            "final mono": "",
            "Final Colour": "",
        }
 
        this.state = getFormData(deviceDefinition);
        this.updateField = this.updateField.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.addDevice = this.addDevice.bind(this);
        this.removeDevice = this.removeDevice.bind(this);
        this.updateDevice = this.updateDevice.bind(this);
        this.deviceDefinition = deviceDefinition;
    }

    componentWillMount() {
        var formData = this.state.formData;
        formData.Company = this.props.currCustomer.Name;
        formData.Email = this.props.account.emailAddress;
        this.setState({ formData })
    }

    submitForm() {
        var failed = false;
        var errorData = {}

        if (!this.state.formData["Signed"] || this.state.formData["Signed"].length < 3) {
            errorData["Signed"] = "Please enter a valid name";
            failed = true;
        }
        if (!this.state.formData["Print Name"] || this.state.formData["Print Name"].length < 3) {
            errorData["Print Name"] = "Please enter a valid name";
            failed = true;
        }
        if (!this.state.formData["date"] || this.state.formData["date"].length < 3) {
            errorData["date"] = "Please enter a date";
            failed = true;
        }
        if (!this.state.formData["No Items"]) {
            errorData["No Items"] = "Please enter value";
            failed = true;
        }

        if (failed) {
            this.setState({ errorData: errorData })
            return
        }

        this.setState({ submittingForm: true, errorData })
        var self = this;
        var postBody = {
            formData: this.state.formData,
            deviceData: this.state.devicesToBeMoved
        }

        axios.post(`/utilities/deviceMoveRequest`, postBody).then(_result => {
            var formData = {
                Company: this.props.currCustomer.Name,
                Email: this.props.account.emailAddress
            }
            this.setState({ 
              ...getFormData(this.deviceDefinition),
              submittingForm: false, 
              submittedForm: true, 
              pageLoaded: true,
              formData: formData })
        }).catch(err => {
            console.log(err);
            var theState = self.state;
            theState.errorData.generalError = "There was an issue submitting the form, please try again"
            theState.submittingForm = false
            this.setState({ theState })
        })
    }

    updateField(event, fieldName) {

        var state = this.state;
        var value = "";
        var fName = "";
        if (fieldName) {
            value = event;
            fName = fieldName;
        }
        else {
            value = event.target.value;
            fName = event.target.name;

            if (event.target.type === "checkbox") {
                value = event.target.checked
            }
        }
        state.formData[fName] = value;
        this.setState(state)
    }

    addDevice() {
        var devicesToBeMoved = this.state.devicesToBeMoved;
        devicesToBeMoved.push(JSON.parse(JSON.stringify(this.state.deviceDefinition)))

        this.setState({ devicesToBeMoved: devicesToBeMoved });
    }
    removeDevice(index) {
        var devicesToBeMoved = this.state.devicesToBeMoved;
        devicesToBeMoved.splice(index, 1)

        this.setState({ devicesToBeMoved: devicesToBeMoved });
    }

    updateDevice(index, event) {
        var state = this.state;
        var value = event.target.value;
        if (event.target.type === "checkbox") {
            value = event.target.checked
        }

        state.devicesToBeMoved[index][event.target.name] = value;
        this.setState(state)
    }
    render() {
        return <div>
            <Row className="mar-t15">
                <Col xs={12} sm={12} md={12} lg={12} style={{ marginBottom: "20px" }}>
                    <div className="overViewHeader" style={{ backgroundImage: `url(${bgImage})` }}>
                        <div className="overViewHeaderText">
                            <h1>Machine Move Request</h1>
                        Please complete the following form if you require a machine moved from one location to another.
                    </div>
                    </div>
                </Col>

                {this.state.submittingForm && <UILIB.LoadingIcon iconType="2" />}
                {this.state.submittedForm && <Col xs={12}>
                    <div className="paper">
                        <h2>Request Submitted</h2>
                        <p>Thank you for submitting your Request.</p>
                        <p>A member of staff should be back in contact with you on the contact details you provided shortly.</p>
                        <UILIB.Button value="Continue" onClick={() => { this.setState({ submittedForm: false }) }} />
                    </div>
                </Col>}
                {this.state.errorData.generalError &&
                    <Col xs={12} style={{ marginBottom: "20px" }}>
                        <div style={{
                            backgroundColor: "red",
                            color: "white",
                            padding: "10px",
                            textAlign: "center"
                        }}>
                            {this.state.errorData.generalError}
                        </div>
                    </Col>
                }
            </Row>
            {(!this.state.submittingForm && !this.state.submittedForm) && <Row>
                <Col xs={12}>

                    <Row>
                        <Col xs={12} sm={6}>
                            <div className="paper">
                                <Row>
                                    <Col xs={12} >
                                        <label>Company</label>
                                        <UILIB.Input type="text" name="Company" value={this.state.formData["Company"]} onChange={this.updateField} error={this.state.errorData["Company"]} />
                                    </Col>
                                    <Col xs={12}>
                                        <label>Purchase Order Ref</label>
                                        <UILIB.Input type="text" name="Purchase Order Ref" value={this.state.formData["Purchase Order Ref"]} onChange={this.updateField} error={this.state.errorData["Purchase Order Ref"]} />
                                    </Col>
                                    <Col xs={12}>
                                        <label>Delivery Address</label>
                                        <UILIB.TextArea name="Delivery Address" onChange={this.updateField} error={this.state.errorData["Delivery Address"]}>{this.state.formData["Delivery Address"]}</UILIB.TextArea>
                                    </Col>
                                    <Col xs={12} >
                                        <label>Sales Person</label>
                                        <UILIB.Input type="text" name="Salesperson" value={this.state.formData.Salesperson} onChange={this.updateField} error={this.state.errorData.Salesperson} />
                                    </Col>
                                    <Col xs={12} >
                                        <label>Courier Details</label>
                                        <UILIB.Input type="text" name="Courier Details" value={this.state.formData["Courier Details"]} onChange={this.updateField} error={this.state.errorData["Courier Details"]} />
                                    </Col>
                                    <Col xs={12}>
                                        <label>General Access</label>
                                        <UILIB.TextArea name="General Access" onChange={this.updateField} error={this.state.errorData["General Access"]}>{this.state.formData["General Access"]}</UILIB.TextArea>
                                    </Col>
                                    <Col xs={12}>
                                        <label>Collection Address</label>
                                        <UILIB.TextArea name="Collection Address" onChange={this.updateField} error={this.state.errorData["Collection Address"]}>{this.state.formData["Collection Address"]}</UILIB.TextArea>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col xs={12} sm={6}>
                            <div className="paper">
                                <Row>


                                    <Col xs={12}>
                                        <label>Movement Date</label>
                                        <UILIB.Input type="date" name="Movement Date" value={this.state.formData["Movement Date"]} onChange={(event) => { this.updateField(event, "Movement Date") }} error={this.state.errorData["Movement Date"]} />
                                    </Col>
                                    <Col xs={12} style={{ marginBottom: "30px" }} >
                                        <div style={{ marginBottom: "15px" }}>
                                            <label>Delivery Method</label>
                                        </div>
                                        <UILIB.CheckBox outerStyle={{ display: "inline" }} name="DMC" value={this.state.formData["DMC"]} onChange={this.updateField} error={this.state.errorData["DMC"]} />
                                        Agilico
                                        <UILIB.CheckBox outerStyle={{ display: "inline" }} name="Courier" value={this.state.formData["Courier"]} onChange={this.updateField} error={this.state.errorData["Courier"]} />
                                        Courier
                                    </Col>
                                    <Col xs={12}>
                                        <label>Install Floor</label>
                                        <div>
                                            <UILIB.Input type="text" name="Install Floor" value={this.state.formData["Install Floor"]} onChange={this.updateField} error={this.state.errorData["Install Floor"]} />
                                        </div>
                                        <div style={{ marginBottom: "30px", textAlign: "right" }}>
                                            <UILIB.CheckBox outerStyle={{ display: "inline" }} name="Stairs" value={this.state.formData["Stairs"]} onChange={this.updateField} error={this.state.errorData["Stairs"]} />
                                            Stairs
                                            <UILIB.CheckBox outerStyle={{ display: "inline" }} name="Lift" value={this.state.formData["Lift"]} onChange={this.updateField} error={this.state.errorData["Lift"]} />
                                            Lift
                                            <UILIB.CheckBox outerStyle={{ display: "inline" }} name="Help Avail" value={this.state.formData["Help Avail"]} onChange={this.updateField} error={this.state.errorData["Help Avail"]} />
                                            Help Avail
                                        </div>
                                    </Col>

                                    <Col xs={12} >
                                        <label>Contact</label>
                                        <UILIB.Input type="text" name="Contact" value={this.state.formData["Contact"]} onChange={this.updateField} error={this.state.errorData["Contact"]} />
                                    </Col>
                                    <Col xs={12}>
                                        <label>Phone</label>
                                        <UILIB.Input type="text" name="Phone" value={this.state.formData["Phone"]} onChange={this.updateField} error={this.state.errorData["Phone"]} />
                                    </Col>
                                    <Col xs={12} >
                                        <label>Parking</label>
                                        <div>
                                            <UILIB.Input type="text" name="Parking" value={this.state.formData["Parking"]} onChange={this.updateField} error={this.state.errorData["Parking"]} />
                                        </div>
                                        <div style={{ marginBottom: "30px", textAlign: "right" }}>
                                            <UILIB.CheckBox outerStyle={{ display: "inline" }} name="Parking onsite" value={this.state.formData["Parking onsite"]} onChange={this.updateField} error={this.state.errorData["Parking onsite"]} />
                                            On Site

                                            <UILIB.CheckBox outerStyle={{ display: "inline" }} name="Street Parking" value={this.state.formData["Street Parking"]} onChange={this.updateField} error={this.state.errorData["Street Parking"]} />
                                            Street

                                            <UILIB.CheckBox outerStyle={{ display: "inline" }} name="Parking Meter" value={this.state.formData["Parking Meter"]} onChange={this.updateField} error={this.state.errorData["Parking Meter"]} />
                                            Meter
                                        </div>
                                    </Col>
                                    <Col xs={12}>
                                        <label>Email</label>
                                        <UILIB.Input type="email" name="Email" value={this.state.formData["Email"]} onChange={this.updateField} error={this.state.errorData["Email"]} />
                                    </Col>

                                </Row>
                            </div>
                        </Col>
                    </Row>

                </Col>
                <Col xs={12}>
                    <div className="paper">
                        <h2>Devices to be Moved</h2>
                        <Row>
                            <Col xs={12}>
                                <table width="100%" cellPadding="10" cellSpacing="0">
                                    <tr>
                                        <td>Qty</td>
                                        <td>Models and Accessories</td>
                                        <td>Is Device</td>
                                        <td>Serial Number</td>
                                        <td>Agilico Group Asset Number</td>
                                        <td>A4/A3</td>
                                        <td>Final Meter Mono</td>
                                        <td>Final Meter Colour</td>
                                        <td></td>
                                    </tr>
                                    {this.state.devicesToBeMoved.map((dev, index) => {
                                        return <tr key={"dev_" + index}>
                                            <td>
                                                <UILIB.Input type="number" name="Qty" value={dev["Qty"]} onChange={(event) => this.updateDevice(index, event)} />
                                            </td>
                                            <td>
                                                <UILIB.Input type="text" name="Model & Items" value={dev["Model & Items"]} onChange={(event) => this.updateDevice(index, event)} />
                                            </td>
                                            <td>
                                                <UILIB.CheckBox name="Device" value={dev["Device"]} onChange={(event) => this.updateDevice(index, event)} />
                                            </td>
                                            <td>
                                                <UILIB.Input type="text" name="Serial" value={dev["Serial"]} onChange={(event) => this.updateDevice(index, event)} />
                                            </td>
                                            <td>
                                                <UILIB.Input type="text" name="DMC Group Asset No" value={dev["DMC Group Asset No"]} onChange={(event) => this.updateDevice(index, event)} />
                                            </td>
                                            <td>
                                                <UILIB.Input type="text" name="A4 / A3" value={dev["A4 / A3"]} onChange={(event) => this.updateDevice(index, event)} />
                                            </td>
                                            <td>
                                                <UILIB.Input type="text" name="final mono" value={dev["final mono"]} onChange={(event) => this.updateDevice(index, event)} />
                                            </td>
                                            <td>
                                                <UILIB.Input type="text" name="Final Colour" value={dev["Final Colour"]} onChange={(event) => this.updateDevice(index, event)} />
                                            </td>
                                            {this.state.devicesToBeMoved.length > 1 && <td width="20">
                                                <div className="icon-cross" onClick={() => { this.removeDevice(index) }} style={{ cursor: "pointer" }} />
                                            </td>}
                                        </tr>
                                    })}
                                </table>
                                <UILIB.Button value="Add another Device" onClick={this.addDevice} />
                            </Col>
                        </Row>
                    </div>
                </Col >
                <Col xs={12}>
                    <div className="paper">
                        <h2>
                            Device(s) Require Network Connection
                            <UILIB.CheckBox outerStyle={{ display: "inline" }} name="network" value={this.state.formData["network"]} onChange={this.updateField} error={this.state.errorData["network"]} />
                        </h2>
                        {this.state.formData["network"] && <Row>
                            <Col xs={12} sm={6} md={4}>
                                <label>Contact Name</label>
                                <UILIB.Input type="text" name="Contact Name" value={this.state.formData["Contact Name"]} onChange={this.updateField} error={this.state.errorData["Contact Name"]} />
                            </Col>
                            <Col xs={12} sm={6} md={4}>
                                <label>Contact Email</label>
                                <UILIB.Input type="text" name="Contact Email" value={this.state.formData["Contact Email"]} onChange={this.updateField} error={this.state.errorData["Contact Email"]} />
                            </Col>
                            <Col xs={12} sm={6} md={4}>
                                <label>Contact Telephone</label>
                                <UILIB.Input type="text" name="Contact Telephone" value={this.state.formData["Contact Telephone"]} onChange={this.updateField} error={this.state.errorData["Contact Telephone"]} />
                            </Col>
                        </Row>}
                    </div>
                </Col >
                <Col xs={12} sm={6} md={6} >
                    <div className="paper">
                        <h2>Logistics Notes</h2>
                        <Row>
                            <Col xs={12} >
                                <UILIB.TextArea type="text" name="logistic notes" value={this.state.formData["logistic notes"]} onChange={this.updateField} error={this.state.errorData["logistic notes"]} />
                            </Col>
                        </Row>
                    </div>
                </Col >
                <Col xs={12} sm={6} md={6} >
                    <div className="paper">
                        <h2>Specific Instructions</h2>
                        <Row>
                            <Col xs={12} >
                                <label>Decommision Required</label>
                                <UILIB.CheckBox outerStyle={{ display: "inline" }} name="decom yes" value={this.state.formData["decom yes"]} onChange={this.updateField} error={this.state.errorData["decom yes"]} />
                            </Col>
                            <Col xs={12} >
                                <UILIB.TextArea type="text" name="specific instructions" value={this.state.formData["specific instructions"]} onChange={this.updateField} error={this.state.errorData["specific instructions"]} />
                            </Col>
                        </Row>
                    </div>
                </Col >
                <Col xs={12} sm={6} md={6} >
                    <div className="paper">
                        <h2>Important Data Erase Information</h2>

                        <p>
                            Personal data may be stored on the print device(s) you are returning, please ticking one of the following options to let us know how you would like us to handle its destruction and we will provide you with a quotation:
                        </p>
                        <p>
                            <UILIB.CheckBox outerStyle={{ display: "inline" }} name="dataDestruct" value={this.state.formData["dataDestruct"]} onChange={this.updateField} error={this.state.errorData["dataDestruct"]} />
                            Data destruction to U.S. DoD standard on receipt of print device at Agilico Group goods inwards, including provision of certificate of destruction.
                        </p>
                        <p>
                            <UILIB.CheckBox outerStyle={{ display: "inline" }} name="hard disk remove" value={this.state.formData["hard disk remove"]} onChange={this.updateField} error={this.state.errorData["hard disk remove"]} />
                             Hard disk drive removed and handed to you prior to removal of the print device from your premises.</p>
                        <p>
                            <UILIB.CheckBox outerStyle={{ display: "inline" }} name="no action" value={this.state.formData["no action"]} onChange={this.updateField} error={this.state.errorData["no action"]} />
                            No action to be taken, any data held print device will remain discoverable.
                         </p>
                        <p>Pricing can be found on your Agilico Group dedicated portal or by contacting logistics@agilico.co.uk</p>

                    </div>
                </Col >
                <Col xs={12} sm={6} md={6} >
                    <div className="paper">
                        <h2>Confirmed Collection of Goods</h2>
                        <Row>
                            <Col xs={12} sm={6}>
                                <label>Signed</label>
                                <UILIB.Input type="text" name="Signed" value={this.state.formData["Signed"]} onChange={this.updateField} error={this.state.errorData["Signed"]} />
                            </Col>
                            <Col xs={12} sm={6}>
                                <label>Date</label>
                                <UILIB.Input type="date" name="date" value={this.state.formData["date"]} onChange={this.updateField} error={this.state.errorData["date"]} />
                            </Col>
                            <Col xs={12} sm={6}>
                                <label>Print Name</label>
                                <UILIB.Input type="text" name="Print Name" value={this.state.formData["Print Name"]} onChange={this.updateField} error={this.state.errorData["Print Name"]} />
                            </Col>
                            <Col xs={12} sm={6}>
                                <label>No Items</label>
                                <UILIB.Input type="number" name="No Items" value={this.state.formData["No Items"]} onChange={this.updateField} error={this.state.errorData["No Items"]} />
                            </Col>
                        </Row>

                    </div>
                    <div style={{ textAlign: "center" }}>
                        <UILIB.Button value="Submit Request" onClick={this.submitForm} />
                    </div>
                </Col >

            </Row>
            }
        </div>
    }
}
