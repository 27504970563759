import React from 'react'
import { Container, Row, Col, Visible, Hidden } from 'react-grid-system';
import Axios from 'axios';
import UILIB from '../../common-objects/Lib'
import queryString from 'query-string';
import Connections from '../../config/connections'

export default class Login extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

            emailAddress: "",
            emailAddressError: "",
            submitting: false,
            sentReminder: false
        }

        this.updateInput = this.updateInput.bind(this);
        this.sendReminder = this.sendReminder.bind(this);
    }

    componentDidMount() {
        var params = queryString.parse(this.props.location.search)
        if (params.t) {
            this.setState({ emailAddress: params.t })
        }
    }


    updateInput(event) {
        var emailAddress = event.target.value;
        this.setState({ emailAddress, sentReminder: false })
    }

    sendReminder() {
        var self = this;
        var errored = false;
        var emailAddressError = "";
        if (!this.state.emailAddress || this.state.emailAddress.length < 3 || this.state.emailAddress.indexOf(".") < 0 || this.state.emailAddress.indexOf("@") < 0) {
            emailAddressError = "Please enter a valid email address";
            errored = true;
        }

        this.setState({ emailAddressError, submitting: errored != true });
        if (errored) return;

        Axios.post(Connections.apiUrl + "/passwordReminder", { emailAddress: this.state.emailAddress }).then((response) => {

            self.setState({ submitting: false, sentReminder: true })

        }).catch(err => {
            var emailAddressError = "There was a problem, please try again";
            if (err && err.response && err.response.data && err.response.data.errMessage) {
                emailAddressError = err.response.data.errMessage;
            }
            self.setState({ emailAddressError, submitting: false })
        })
    }

    render() {

        if (this.state.submitting) {
            return <div></div>
        }
        if (this.state.sentReminder) {
            return <div>
                <h3>Password Email Sent</h3>
                <p>An email is on its way to your inbox which contains a link which will allow you to reset your password</p>
                <p className="mar-b20">If you continue having issues, please <a href="mailto:customercare@agilico.co.uk">contact us</a></p>
                <UILIB.Button value="Continue" onClick={() => { this.props.history.push("/") }} />
            </div>
        }

        return <div>
            <h3>Password Reset</h3>
            <p>If you have forgotten your login details, please enter your email address below and we will send you a link which allows you to reset your password.</p>
            <p className="mar-b20">If you continue having issues, please <a href="mailto:customercare@agilico.co.uk">contact us</a></p>
            <div className="mar-b20">

                <UILIB.Input name="emailAddress" type="email" placeholder="Your Email Address" value={this.state.emailAddress} onChange={this.updateInput} error={this.state.emailAddressError} />
                <Row className="mar-b20">
                    <Col xs={12} sm={6}>
                        <UILIB.Button value="Send Reminder" onClick={this.sendReminder} />
                    </Col>
                    <Col xs={12} sm={6} align="right" justify="center">
                    </Col>
                </Row>
                <a onClick={() => { this.props.history.push("/") }}>or Return to Login</a>
            </div>


        </div>
    }
}
