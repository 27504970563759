import React from 'react'
import { Container, Row, Col, Visible, Hidden } from 'react-grid-system';
import UILIB from '../../../common-objects/Lib'
import bgImage from '../../../assetts/images/frontPage/bg6.jpg'
import axios from '../../../includes/axios';
import { getFormData } from './defaults';
export default class RequestEngineer extends React.Component {
    constructor(props) {
        super(props);

        this.state = getFormData();
        this.updateField = this.updateField.bind(this);
        this.submitForm = this.submitForm.bind(this);

    }

    componentDidMount() {
        var newState = this.state;
        newState.formData.emailAddress = this.props.account.emailAddress;
        newState.formData.yourName = this.props.account.firstName + " " + this.props.account.lastName;
        newState.formData.companyName = this.props.currCustomer.Name;

        this.setState(newState);
    }


    updateField(event) {
        var state = this.state;
        state.formData[event.target.name] = event.target.value;
        this.setState(state)
    }

    submitForm() {
        var failed = false;
        var errorData = {
            yourName: "",
            emailAddress: "",
            contactNumber: "",
            deliveryAddress: ""
        }


        if (this.state.formData.yourName.length < 5) {
            errorData.yourName = "Please enter a valid name";
            failed = true;
        }
        if (this.state.formData.emailAddress.length < 5 || !this.state.formData.emailAddress.indexOf('@') || !this.state.formData.emailAddress.indexOf('.')) {
            errorData.emailAddress = "Please enter a valid email address";
            failed = true;
        }
        if (this.state.formData.contactNumber.length < 5) {
            errorData.contactNumber = "Please enter a valid number";
            failed = true;
        }
        if (this.state.formData.deliveryAddress.length < 3) {
            errorData.deliveryAddress = "Please enter delivery address";
            failed = true;
        }


        this.setState({ errorData: errorData })
        if (failed) {
            return
        }
        this.setState({ submittingForm: true })



        axios.post(`/utilities/requestRecycling`, this.state.formData).then(_result => {
            this.setState({ ...getFormData(), submittingForm: false, submittedForm: true, pageLoaded: true })
        }).catch(err => {
            var theState = self.state;
            theState.errorData.generalError = "There was an issue submitting the form, please try again"
            theState.submittingForm = false
            this.setState({ theState })
        })

    }



    render() {
        if (!this.state.pageLoaded || this.state.submittingForm) return <div style={{ textAlign: "center" }}><UILIB.LoadingIcon iconType="2" /></div>

        var quantitySelect = [];
        var tCnt = 0;
        while (tCnt < 31) {
            quantitySelect.push({ label: tCnt, value: tCnt });
            tCnt++;
        }

        var finalCostQuantity = this.state.formData.quantity * 18;
        var finalCostCollect = this.state.formData.quantityCollect * 18;
        var finalCost = finalCostQuantity + finalCostCollect;
        var finalCostVat = finalCost + (finalCost / 100 * 20);
        return <Row className="mar-t15">
            <Col xs={12} sm={12} md={12} lg={12} style={{ marginBottom: "20px" }}>
                <div className="overViewHeader" style={{ backgroundImage: `url(${bgImage})` }}>
                    <div className="overViewHeaderText">
                        <h1>Request a Recycling Box</h1>
                        If you require a recycling box, please complete the following form and our service department will handle your request as soon as possible.
                    </div>
                </div>
            </Col>

            {this.state.errorData.generalError &&
                <Col xs={12} style={{ marginBottom: "20px" }}>
                    <div style={{
                        backgroundColor: "red",
                        color: "white",
                        padding: "10px",
                        textAlign: "center"
                    }}>
                        {this.state.errorData.generalError}
                    </div>
                </Col>
            }

            {
                this.state.submittedForm && <Col xs={12}>
                    <div className="paper">
                        <h2>Request Submitted</h2>
                        <p>Thank you for submitting your Request.</p>
                        <p>A member of staff should be back in contact with you on the contact details you provided shortly.</p>
                        <UILIB.Button value="Continue" onClick={() => { this.setState({ submittedForm: false }) }} />
                    </div>
                </Col>
            }
            {
                (!this.state.submittingForm && !this.state.submittedForm) && <Col xs={12}>
                    <div className="paper">
                        <h2>Your Contact Details</h2>
                        <Row>
                            <Col xs={12} sm={6} md={4}>
                                <label>Your Name</label>
                                <UILIB.Input type="text" name="yourName" value={this.state.formData.yourName} onChange={this.updateField} error={this.state.errorData.yourName} />
                            </Col>
                            <Col xs={12} sm={6} md={4}>
                                <label>Your Email</label>
                                <UILIB.Input type="email" name="emailAddress" value={this.state.formData.emailAddress} onChange={this.updateField} error={this.state.errorData.emailAddress} />
                            </Col>
                            <Col xs={12} sm={6} md={4}>
                                <label>Contact Number</label>
                                <UILIB.Input type="text" name="contactNumber" value={this.state.formData.contactNumber} onChange={this.updateField} error={this.state.errorData.contactNumber} />
                            </Col>

                            <Col xs={12} className="mar-b15">
                                <label>Delivery Address</label>
                                <UILIB.TextArea type="text" name="deliveryAddress" style={{ height: "150px" }} onChange={this.updateField} error={this.state.errorData.deliveryAddress}>{this.state.formData.deliveryAddress}</UILIB.TextArea>
                            </Col>
                            <Col xs={12} sm={6} className="mar-b15">
                                <label>Boxes Required</label>
                                <UILIB.Select data={quantitySelect} name="quantity" value={this.state.formData.quantity} onChange={this.updateField} error={this.state.errorData.quantity} />
                            </Col>
                            <Col xs={12} sm={6} className="mar-b15">
                                <label>Boxes to be collected</label>
                                <UILIB.Select data={quantitySelect} name="quantityCollect" value={this.state.formData.quantityCollect} onChange={this.updateField} error={this.state.errorData.quantityCollect} />
                            </Col>
                            <Col xs={12} className="mar-b15">
                                <div style={{ fontSize: "16px", marginBottom: "10px" }}>
                                    <strong>Charges</strong>
                                </div>
                                <div>
                                    {(finalCostQuantity > 0) && <div className="mar-b10"><strong>£{finalCostQuantity.toFixed(2)}</strong> Delivery Cost (ex VAT)</div>}
                                    {(finalCostCollect > 0) && <div className="mar-b10"><strong>£{finalCostCollect.toFixed(2)}</strong> Collection Cost (ex VAT)</div>}

                                    <div>
                                        <div>Total</div>
                                        <strong style={{ fontSize: "20px", marginRight: "10px" }}>£{finalCostVat.toFixed(2)}</strong>
                                         (£{finalCost.toFixed(2)} ex VAT)</div>
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div>
                                    <span><UILIB.CheckBox checked={this.state.confirmed} outerStyle={{ display: "inline-block" }} onClick={(event) => { this.setState({ confirmed: event.target.checked }) }} /></span>
                                    <span>I accept I will be charged £{finalCostVat.toFixed(2)} for these recycling box(es).</span>
                                </div>
                            </Col>

                            <Col xs={12}>
                                <UILIB.Button value="Submit Request" onClick={this.submitForm} disabled={!this.state.confirmed} />
                            </Col>

                        </Row>
                    </div>
                </Col >
            }
        </Row >
    }
}
