import React from 'react'
import { Container, Row, Col, Visible, Hidden } from 'react-grid-system';
import UILIB from '../../common-objects/Lib'
import bgImage from '../../assetts/images/frontPage/bg3.jpg'
import Axios from '../../includes/axios';

export default class OverViewWelcomeStats extends React.Component {
    constructor(props) {
        super(props);

        let timer;
        this.state = {
            loadingStats: true,
            engineersCalledOut: 0,
            consumablesUsed: 0,
            inventory: 0,
            totalCopies: 0,
            brokenDown: 0
        }
        this.getStats = this.getStats.bind(this);
    }

    componentDidMount() {
        this.getStats();
    }
    componentWillUnmount() {
        clearTimeout(this.timer)
    }

    getStats() {
        var self = this;
        var promises = [];
        clearTimeout(self.timer)
        promises.push(Axios.get(`/equipment?countOnly=true&onlyCopiers=true`))
        promises.push(Axios.get(`/work?daysSinceToday=30&countOnly=true&excludeCategoryIds=4_5_6_7`))
        promises.push(Axios.get(`/consumable?daysSinceToday=30&countOnly=true`))
        // promises.push(Axios.get(`/work/workunit?daysSinceToday=30&countOnly=true&categoryId=2`))
        promises.push(Axios.get('/work?daysSinceToday=30'))
        Promise.all(promises).then(res => {
            var engineersCalledOut = 0;
            var consumablesUsed = 0;
            var inventory = 0;
            var brokenDown = 0;
            if (res.length) {
                inventory = res[0].data.count;
                engineersCalledOut = res[1].data.count;
                consumablesUsed = res[2].data.count;
                brokenDown = res[3].data.count;
            }
            this.setState({ loadingStats: false, engineersCalledOut, consumablesUsed, inventory, brokenDown }, () => {
                self.timer = setTimeout(() => { self.getStats() }, 10000)
            })
        }).catch((err) => {
            console.log(err);
            self.timer = setTimeout(() => { self.getStats() }, 10000)
        })

    }

    render() {

        return <div className="paper primary">
            < h2 > Welcome back {this.props.account.FirstName}</h2 >
            <div className="mar-b20">It's great to see you. Here's a few awesome facts about {this.props.currCustomer.Name} from the past 30 days...</div>
            {this.state.loadingStats && <div style={{ textAlign: "center" }}><UILIB.LoadingIcon /></div>}
            {!this.state.loadingStats && <Row>
                <Col xs={12} sm={6}>
                    <div className="overViewQuickStats">
                        <span className="stat">{this.state.inventory}</span><span className="text">Print Devices Online</span>
                    </div>
                </Col>
                <Col xs={12} sm={6}>
                    <div className="overViewQuickStats">
                        <span className="stat">{this.state.engineersCalledOut}</span><span className="text">Engineers Called Out</span>
                    </div>
                </Col>
                <Col xs={12} sm={6}>
                    <div className="overViewQuickStats">
                        <span className="stat">{this.state.brokenDown}</span><span className="text">Machine(s) Broken Down</span>
                    </div>
                </Col>
                <Col xs={12} sm={6}>
                    <div className="overViewQuickStats">
                        <span className="stat">{this.state.consumablesUsed}</span><span className="text">Consumables Ordered</span>
                    </div>
                </Col>
                {(this.props.account.isAdmin == 1 || (this.props.account.permissions.dashboard != undefined && this.props.account.permissions.dashboard == 1)) && <Col xs={12} sm={6}>
                    <UILIB.Button value="Find out more" onClick={() => { this.props.history.push('/dashboard') }} />
                </Col>}
            </Row>}


        </div>
    }
}
