

import React from 'react';
import ReactDOM from 'react-dom';
import { Router, Route, Link, Switch, Redirect } from "react-router-dom";
import browserHistory from 'history/createBrowserHistory'
import { setConfiguration } from 'react-grid-system';
import SessionHelpers from './session/index'
import Axios from './includes/axios';

require('./stylesheets/base.scss');

const theHistory = browserHistory();
setConfiguration({ defaultScreenClass: 'md', gridColumns: 12 });

import FrontPageTemplate from './templates/frontPage'
import ControlPanelTemplate from './templates/controlPanel'

theHistory.listen(location => {

    var loggedIn = SessionHelpers.checkAccount(sessionStorage.getItem("customerContext"));
    
    if (loggedIn) {
        SessionHelpers.getAccount().then(acc => {
            var customerPerson = { emailAddress: "none@none.com" };
            if (acc) {
                customerPerson = acc.customer.customerPerson
            }
            if (acc && acc.customer && acc.customer.customerPerson) {
                Axios.post('/tracking', { path: location.pathname, userId: acc.customer.customerPerson.id })
            }
        })
    }
});


ReactDOM.render(
    <Router history={theHistory}>
        <Switch>
            <Route exact path="/" component={FrontPageTemplate} />
            <Route exact path="/passwordreminder" component={FrontPageTemplate} />
            <Route exact path="/resetpassword" component={FrontPageTemplate} />
            <Route exact path="/accountRegistration" component={FrontPageTemplate} />
            <Route path="/event/" component={ControlPanelTemplate} history={theHistory} />
            <Route path="/overview/" component={ControlPanelTemplate} history={theHistory} />
            <Route path="/dashboard/" component={ControlPanelTemplate} history={theHistory} />
            <Route path="/support/" component={ControlPanelTemplate} history={theHistory} />
            <Route path="/guides/" component={ControlPanelTemplate} history={theHistory} />
            <Route path="/kpax/" component={ControlPanelTemplate} history={theHistory} />
            <Route path="/admin/" component={ControlPanelTemplate} history={theHistory} />
            <Route path="/account/" component={ControlPanelTemplate} history={theHistory} />
            <Route path="/telecomsandit/" component={ControlPanelTemplate} history={theHistory} />
            <Route component={FrontPageTemplate} history={theHistory} />
        </Switch>
    </Router>
    ,
    document.getElementById('app')
);

module.hot.accept();