import React from 'react'

import { Container, Row, Col, Visible, Hidden } from 'react-grid-system';
import UILIB from '../../common-objects/Lib'
import bgImage from '../../assetts/images/frontPage/bg6.jpg'

import ServiceIndex from './service/index.jsx'
import DevicesIndex from './devices/index.jsx'
import LocationsIndex from './locations/index.jsx'
import moment from 'moment';

import Axios from '../../includes/axios';

export default class Dashboard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currTab: "Service",
            startDate: new Date(),
            endDate: new Date(),
            startDateFormatted: moment().startOf("month").format("YYYY-MM-DD"),
            endDateFormatted: moment().endOf("month").format("YYYY-MM-DD"),
            invalidDateRange: false
        }
        this.changeTab = this.changeTab.bind(this);
        this.changeDate = this.changeDate.bind(this);
        this.quarter_of_the_year = this.quarter_of_the_year.bind(this);
        this.exportData = this.exportData.bind(this);
    }

    componentWillMount() {
        var startDate = new Date(Date.now())
        startDate.setMonth(startDate.getMonth() - 11);
        if (startDate.getFullYear() == 2020) {
            startDate.setMonth(0);
            startDate.setFullYear(2021);
        }

        var endDate = new Date(Date.now());
        startDate = moment(startDate).startOf("month").toDate();
        endDate = moment(endDate).endOf("month").toDate();

        var startDateFormatted = moment(startDate).format("YYYY-MM-DD");
        var endDateFormatted = moment(endDate).format("YYYY-MM-DD");

        this.setState({ startDate, endDate, startDateFormatted, endDateFormatted })
    }

    quarter_of_the_year(theDate) {
        var month = theDate.getMonth() + 1;
        var quarter = (Math.ceil(month / 3));
        switch (quarter) {
            case 1:
                theDate.setMonth(0);
                break;
            case 2:
                theDate.setMonth(3);
                break;
            case 3:
                theDate.setMonth(6);
                break;
            case 4:
                theDate.setMonth(9);
                break;
        }
        return theDate;
    }

    changeTab(newTab) {
        var invalidDateRange = this.state.invalidDateRange;

        var dateDiff = moment(this.state.startDate).diff(this.state.endDate, "months");
        if (dateDiff < -12 || dateDiff > 12) {
            invalidDateRange = true;
        }

        this.setState({ currTab: newTab, invalidDateRange })
    }
    changeDate(value, name) {
        var newDate = new Date(value);
        if (name == "startDate") {
            newDate = moment(newDate).startOf("month").toDate();
        }

        if (name == "endDate") {

            newDate = moment(newDate).endOf("month").toDate();
        }
        this.setState({ [name]: newDate, [name + "Formatted"]: moment(newDate).format("YYYY-MM-DD") }, () => {

            var invalidDateRange = false;
            var dateDiff = moment(this.state.startDate).diff(this.state.endDate, "months");
            if (dateDiff < -11 || dateDiff > 11) {
                invalidDateRange = true;
            }

            this.setState({ invalidDateRange })


        });
    }

    exportData() {
        var url = '/dashboard/export';
        switch (this.state.currTab) {
            case "Service":
                url += `?type=service&start=${this.state.startDate}&end=${this.state.endDate}`;
                break;
            case "Devices":
                url += `?type=devices&start=${this.state.startDate}&end=${this.state.endDate}`;
                break;
            case "Locations":
                url += `?type=locations&start=${this.state.startDate}&end=${this.state.endDate}`;
                break;
        }
        Axios.get(url).then(_res => {
            const url = window.URL.createObjectURL(new Blob([_res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `export_${this.state.currTab}_${moment(this.state.startDate).format("YYYY-MM-DD")}_${moment(this.state.endDate).format("YYYY-MM-DD")}.csv`);
            document.body.appendChild(link);
            link.click();
        }).catch(err => {
            console.log(err);
        })

    }
    render() {

        var maxDate = this.state.endDate;
        var minDate = this.state.startDate;
        var statsMinDate = moment("2020-08-01").startOf("month").toDate();
        var ultimateMaxDate = moment().endOf("month").toDate();
        return <div>
            <Row className="mar-t15">
                <Col xs={12} sm={12} md={12} lg={12} style={{ marginBottom: "20px" }}>
                    <div className="overViewHeader" style={{ backgroundImage: `url(${bgImage})` }}>
                        <div className="overViewHeaderText">
                            <h1>Dashboard</h1>
                            View up to the minute statistics about how your devices are performing, how well they are being supported, and much more...
                    </div>
                    </div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} className="mar-b20">
                    <Row>
                        <Col xs={4}>
                            <div className={"dashboard_headerNav" + (this.state.currTab == "Service" ? " active" : "")} onClick={() => { this.changeTab("Service") }}>Service</div>
                        </Col>
                        <Col xs={4}>
                            <div className={"dashboard_headerNav" + (this.state.currTab == "Devices" ? " active" : "")} onClick={() => { this.changeTab("Devices") }}>Device Statistics</div>
                        </Col>
                        <Col xs={4}>
                            <div className={"dashboard_headerNav" + (this.state.currTab == "Locations" ? " active" : "")} onClick={() => { this.changeTab("Locations") }}>Device Locations</div>
                        </Col>
                    </Row>
                </Col>
                <Col xs={12} sm={10} md={10} lg={10} className="mar-b20" style={{ display: "flex", alignItems: "center" }}>
                    <span>Show data between: </span>
                    <UILIB.Input type="date" value={this.state.startDate} onChange={this.changeDate} name="startDate" outerStyle={{ margin: "0", marginRight: "10px", width: "120px" }} minDate={statsMinDate} maxDate={maxDate} showQuarterYearPicker={false} dateFormat={"MMM yyyy"} showMonthYearPicker={true} />

                    <span>and</span>
                    <UILIB.Input type="date" value={this.state.endDate} onChange={this.changeDate} name="endDate" outerStyle={{ width: "120px", margin: "0" }} minDate={minDate} maxDate={ultimateMaxDate} showQuarterYearPicker={false} dateFormat={"MMM yyyy"} showMonthYearPicker={true} />

                    {this.state.invalidDateRange && <span style={{ color: "red" }}>Please choose 12 months or less</span>}


                </Col>

                <Col xs={12} sm={2} md={2} lg={2} className="text-right mar-b20">
                    <a onClick={this.exportData}><span className="icon icon-download2" /> Export</a>
                </Col>
            </Row>
            {this.state.currTab == "Service" && <ServiceIndex {...this.props} invalidDateRange={this.state.invalidDateRange} startDate={this.state.startDateFormatted} endDate={this.state.endDateFormatted} />}
            {this.state.currTab == "Devices" && <DevicesIndex {...this.props} invalidDateRange={this.state.invalidDateRange} startDate={this.state.startDateFormatted} endDate={this.state.endDateFormatted} />}
            {this.state.currTab == "Locations" && <LocationsIndex {...this.props} invalidDateRange={this.state.invalidDateRange} startDate={this.state.startDateFormatted} endDate={this.state.endDateFormatted} />}
        </div>
    }
}
