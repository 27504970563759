import axios from 'axios';
import DBConnection from '../config/connections';

var axiosInstance = axios.create();

axiosInstance.interceptors.request.use(config => {

    var context = sessionStorage.getItem("customerContext");
    var accessToken = localStorage.getItem(`accessToken_${context}`);

    if (accessToken) {
        if (config.method !== 'OPTIONS') {
            config.headers['x-access-token'] = accessToken;
        }
        var baseUrl = DBConnection.apiUrl
        config.url = baseUrl + config.url;
        return config;
    } else {
        localStorage.removeItem(`accessToken_${context}`);
        sessionStorage.removeItem("customerContext");
        return Promise.reject('No Token');
    }
}, function (err) {
    window.location = '/';
    return Promise.reject('No Token');
})

axiosInstance.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (error == "No Token") {
        window.location = "/";
    }
    return Promise.reject(error.response);
})

export default axiosInstance;