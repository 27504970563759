import React, { Component } from 'react';

class textArea extends Component {
    constructor(props) {
        super(props);
        let timer;
        this.state = {
        }

    }


    render() {

        var className = "textInput textArea";
        if (this.props.className) {
            className += " " + this.props.className;
        }
        if (this.props.error && this.props.error.length) {
            className += " errored";
        }

        return <div className="textInputHolder" style={this.props.outerStyle}>
            <textarea {...this.props} className={className}>{this.props.children}</textarea>
            {(this.props.error && this.props.error.length > 0) && <div className="errorTextHolder">{this.props.error}</div>}
        </div>

    }
}

export default textArea;