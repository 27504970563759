import React from 'react'
import { Row, Col } from 'react-grid-system';
import UILIB from '../../common-objects/Lib'
import ReactPlayer from 'react-player'
import { Document, Page, pdfjs } from 'react-pdf'
import axios from '../../includes/axios';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

export default class GuidesViewContent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            numPages: null,
            pageNumber: 1,
            pdfLoaded: false,
            error: false,
            pdfBase64: null
        }
        this.onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this);
        this.pdfChangePage = this.pdfChangePage.bind(this);
    }

    componentDidMount() {
      var newState = this.state;
      this.setState(newState, this.getFile);
    }

    getFile() {
      axios.get(`/content/getFile/${this.props.contentItem.id}/${this.props.contentItem.fileUrl}`)
      .then(_result => {
        this.setState({ ...this.state, pdfBase64: _result.data.file });
      })
      .catch(err => {
          console.log(err);
          this.setState({ ...this.state, error: true });
      })
    }

    onDocumentLoadSuccess(_pdfInfo) {
        this.setState({ numPages: _pdfInfo.numPages, pdfLoaded: true });
    }

    pdfChangePage(amt, specific) {
        if (isNaN(amt)) return;
        var pageNumber = this.state.pageNumber + amt;
        if (specific) {
            pageNumber = Number(amt);
        }
        if (pageNumber <= 0) {
            pageNumber = 1;
        }
        if (pageNumber > this.state.numPages) {
            pageNumber = this.state.numPages;
        }
        this.setState({ pageNumber })
    }

    render() {
        if (!this.props.contentItem || this.state.error) return (
          <Row className="mar-t15">
            <Col xs={12} sm={12} md={12} lg={12} style={{ textAlign: "right" }}>
              <UILIB.Button value="Back" onClick={this.props.closeViewContent} />  
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} style={{ marginTop: "150px", textAlign: "center" }}>
              <div>Oops - We can't find this content</div>
            </Col>
          </Row>
        )

        if (!this.state.pdfBase64) return <div style={{ textAlign: "center" }}><UILIB.LoadingIcon /></div>

        return <Row className="mar-t15">
            <Col xs={12} sm={12} md={8} lg={8} style={{ marginBottom: "20px" }}>
                <h1>Viewing {this.props.contentItem.shortDesc}</h1>
            </Col>
            <Col xs={12} sm={12} md={4} lg={4} style={{ textAlign: "right" }}>
                <UILIB.Button value="Back" onClick={this.props.closeViewContent} />
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} style={{ marginBottom: "20px" }}>
                {(this.props.contentItem.fileType == "video") &&
                    <div style={{ position: "relative", paddingTop: "56.25%" }}>
                        <ReactPlayer style={{ position: "absolute", top: "0", left: "0" }} url={"https://360.agilico.co.uk/externalpages/assets/" + this.props.contentItem.fileUrl} playing width={"100%"} height={"100%"} controls={true} />
                    </div>
                }
                {(this.props.contentItem.fileType == "mp4") &&
                    <div style={{ position: "relative", paddingTop: "56.25%" }}>
                        <ReactPlayer style={{ position: "absolute", top: "0", left: "0" }} url={"https://360.agilico.co.uk/externalpages/assets/" + this.props.contentItem.fileUrl} playing width={"100%"} height={"100%"} controls={true} />
                    </div>
                }
                {(this.props.contentItem.fileType == "pdf") && <div style={{ width: "fit-content", margin: "0 auto" }}>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <Document file={`data:application/pdf;base64,${this.state.pdfBase64}`} onLoadSuccess={this.onDocumentLoadSuccess}>
                            <Page pageNumber={this.state.pageNumber} renderAnnotationLayer={false} renderTextLayer={false} />
                        </Document>
                    </div>
                    {!this.state.pdfLoaded && <UILIB.LoadingIcon />}
                    {this.state.pdfLoaded && <div style={{ display: "flex", width: "100%" }}>
                        <p style={{ flex: 0 }} onClick={() => { this.pdfChangePage(-1) }}><div className="icon icon-arrow-left-circle" style={{ cursor: "pointer" }} /></p>
                        <p style={{ flex: 1, textAlign: "center" }}>Page <UILIB.Input type="number" value={this.state.pageNumber} outerStyle={{ width: "70px", display: "inline-block" }} onChange={(event) => { this.pdfChangePage(event.target.value, true) }} /> of {this.state.numPages}</p>
                        <p style={{ flex: 0, textAlign: "right" }} onClick={() => { this.pdfChangePage(1) }}><div className="icon icon-arrow-right-circle" style={{ cursor: "pointer" }} /></p>
                    </div>}
                </div>
                }

            </Col>
        </Row>
    }
}
