import React from 'react'
import { Container, Row, Col, Visible, Hidden } from 'react-grid-system';
import UILIB from '../../../common-objects/Lib'
import bgImage from '../../../assetts/images/frontPage/bg6.jpg'
import axios from '../../../includes/axios';
import { getFormData } from './defaults';
export default class RequestInformation extends React.Component {
    constructor(props) {
        super(props);

        this.state = getFormData();
        this.updateField = this.updateField.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.fileAdded = this.fileAdded.bind(this);
    }

    componentWillMount() {

        var newState = this.state;
        newState.formData.emailAddress = this.props.account.emailAddress;
        newState.formData.contactName = this.props.account.firstName + " " + this.props.account.lastName;
        newState.formData.companyName = this.props.currCustomer.Name;
        this.setState(newState)
    }


    updateField(event) {
        var state = this.state;
        state.formData[event.target.name] = event.target.value;
        this.setState(state)
    }

    fileAdded(e) {
        let files;
        if (e.preventDefault) {
            e.preventDefault();
            files = e.target.files;
        }
        else {
            files = e;
        }

        var thisState = this.state;
        thisState.formData.attachments = files;
        this.setState(thisState);
    }

    submitForm() {
        var failed = false;
        var errorData = {
            contactName: "",
            emailAddress: "",
            contactNumber: "",
            companyName: "",
            department: "",
            requestType: "",
            requestBody: "",
            referenceNumber: "",
            jobNumber: "",
            generalError: "",
            attachments: ""
        }


        if (this.state.formData.contactName.length < 5) {
            errorData.contactName = "Please enter a valid name";
            failed = true;
        }
        if (this.state.formData.emailAddress.length < 5 || this.state.formData.emailAddress.indexOf('@') < 0 || this.state.formData.emailAddress.indexOf('.') < 0) {
            errorData.emailAddress = "Please enter a valid email address";
            failed = true;
        }
        if (this.state.formData.contactNumber.length < 5) {
            errorData.contactNumber = "Please enter a valid number";
            failed = true;
        }
        if (this.state.formData.companyName.length < 3) {
            errorData.companyName = "Please enter name";
            failed = true;
        }
        if (isNaN(this.state.formData.requestType)) {
            errorData.requestType = "Please select a Request Type";
            failed = true;
        } else {
            if (this.state.formData.requestType == 6 && this.state.formData.referenceNumber.length < 3) {
                errorData.referenceNumber = "Please enter a reference number";
                failed = true;
            }
            if (this.state.formData.requestType == 7 && this.state.formData.jobNumber.length < 3) {
                errorData.jobNumber = "Please enter a job number";
                failed = true;
            }
        }
        if (this.state.formData.requestBody.length < 5) {
            errorData.requestBody = "Please enter a request";
            failed = true;
        }

        if (failed) {
            this.setState({ errorData: errorData })
            return
        }
        this.setState({ submittingForm: true })
        var self = this;
        if (this.state.formData.requestType == 6) {
            var emailRequest = {
                requestType: "1",
                emailBody: `Name: ${this.state.formData.contactName}<br>
                Company: ${this.state.formData.companyName}<br>
                Email: ${this.state.formData.emailAddress}<br>
                Tel No: ${this.state.formData.contactNumber}<br>
                Ref: ${this.state.formData.referenceNumber}<br>
                Notes: ${this.state.formData.requestBody}<br>
            `}
            axios.post(`/utilities/sendEmail`, emailRequest).then(_result => {
                this.setState({ submittingForm: false, submittedForm: true })
            }).catch(err => {
                var theState = self.state;
                theState.errorData.generalError = "There was an issue submitting the form, please try again"
                theState.submittingForm = false
                this.setState({ theState })
            })
        }
        else {
            var data = new FormData();
            Object.keys(this.state.formData).forEach((formElement, index) => {
                if (formElement == "attachments") {
                    data.append('file', this.state.formData.attachments[0])
                }
                else {
                    data.append(formElement, this.state.formData[formElement])
                }
            })

            var formData = {
                contactName: "",
                emailAddress: "",
                contactNumber: "",
                companyName: "",
                department: "",
                requestType: 0,
                referenceNumber: "",
                jobNumber: "",
                requestBody: "",
                attachments: {}
            }
            axios.post(`/utilities/customerRequest`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(_result => {
                this.setState({ ...getFormData(), submittingForm: false, submittedForm: true, pageLoaded: true, formData })
            }).catch(err => {
                var theState = self.state;
                theState.errorData.generalError = "There was an issue submitting the form, please try again"
                theState.submittingForm = false
                this.setState({ theState })
            })
        }
    }


    render() {

        var requestTypes = [
            { value: 0, label: "Account Query" },
            { value: 1, label: "Copy of an Invoice" },
            { value: 2, label: "Copy of a Statement" },
            { value: 3, label: "Account Manager Visit" },
            { value: 4, label: "Training Visit" },
            { value: 5, label: "Service Invoice Query" },
            { value: 6, label: "Non-Contract Device Call" },
            { value: 7, label: "Service Call Status" },
            { value: 21, label: "Portal Query" }
        ].sort((a, b) => {
            if (a.label < b.label) return -1;
            if (a.label > b.label) return 1;
            return 0
        }
        )

        if (this.state.submittingForm) return <div style={{ textAlign: "center" }}><UILIB.LoadingIcon iconType="2" /></div>

        return <Row className="mar-t15"> 
            <Col xs={12} sm={12} md={12} lg={12} style={{ marginBottom: "20px" }}>
                <div className="overViewHeader" style={{ backgroundImage: `url(${bgImage})` }}>
                    <div className="overViewHeaderText">
                        <h1>Other Request</h1>
                        Request an account manager visit, an invoice reprinted, and much more...
                    </div>
                </div>
            </Col>

            {this.state.submittedForm && <Col xs={12}>
                <div className="paper">
                    <h2>Request Submitted</h2>
                    <p>Thank you for submitting your Request.</p>
                    <p>A member of staff should be back in contact with you on the contact details you provided shortly.</p>
                    <UILIB.Button value="Continue" onClick={() => { this.setState({ submittedForm: false }) }} />
                </div>
            </Col>}
            {(!this.state.submittingForm && !this.state.submittedForm) && <div>
                <Col xs={12}>
                    <div className="paper">
                        <h2>Your Contact Details</h2>
                        <Row>
                            <Col xs={12} sm={6} md={4}>
                                <label>Your Name</label>
                                <UILIB.Input type="text" name="contactName" value={this.state.formData.contactName} onChange={this.updateField} error={this.state.errorData.contactName} />
                            </Col>
                            <Col xs={12} sm={6} md={4}>
                                <label>Your Email</label>
                                <UILIB.Input type="email" name="emailAddress" value={this.state.formData.emailAddress} onChange={this.updateField} error={this.state.errorData.emailAddress} />
                            </Col>
                            <Col xs={12} sm={6} md={4}>
                                <label>Contact Number</label>
                                <UILIB.Input type="text" name="contactNumber" value={this.state.formData.contactNumber} onChange={this.updateField} error={this.state.errorData.contactNumber} />
                            </Col>
                            <Col xs={12} sm={6} md={4}>
                                <label>Company Name</label>
                                <UILIB.Input type="text" name="companyName" value={this.state.formData.companyName} onChange={this.updateField} error={this.state.errorData.companyName} />
                            </Col>
                            <Col xs={12} sm={6} md={4}>
                                <label>Department</label>
                                <UILIB.Input type="text" name="department" value={this.state.formData.department} onChange={this.updateField} error={this.state.errorData.department} />
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col xs={12}>
                    <div className="paper">
                        <h2>Your Request</h2>
                        <Row>

                            <Col xs={12} sm={6} md={6}>
                                <label>Request Type</label>
                                <UILIB.Select data={requestTypes} name="requestType" value={this.state.formData.requestType} placeholder="Select a Request Type" onChange={this.updateField} error={this.state.errorData.requestType} />
                            </Col>
                            <Col xs={12} sm={6} md={6}>
                                {this.state.formData.requestType == 6 && <div>
                                    <label>Your reference no</label>
                                    <UILIB.Input type="text" name="referenceNumber" value={this.state.formData.referenceNumber} onChange={this.updateField} error={this.state.errorData.referenceNumber} />
                                </div>}
                                {this.state.formData.requestType == 7 && <div>
                                    <label>Job Number</label>
                                    <UILIB.Input type="text" name="jobNumber" value={this.state.formData.jobNumber} onChange={this.updateField} error={this.state.errorData.jobNumber} />
                                </div>}
                            </Col>
                            <Col xs={12} sm={12} md={12}>
                                <label>Your Request</label>
                                <div className="mar-t10" style={{ fontSize: "12px" }}>Please enter more information about your request. For example if you are requesting an invoice reprint, please include the invoice number(s) you require...</div>
                                <UILIB.TextArea name="requestBody" style={{ height: "200px" }} onChange={this.updateField} error={this.state.errorData.requestBody}>{this.state.formData.requestBody}</UILIB.TextArea>
                            </Col>
                            {this.state.formData.requestType != 6 && <Col xs={12} sm={6} className="mar-b15">
                                <label>Attachment</label>
                                <div className="mar-t10" style={{ fontSize: "12px" }}>If you would like to attach a relevant file (ie a PDF, jpeg, etc) relating to this request, please use the following option:</div>
                                <UILIB.Input type="file" name="attachments" onChange={this.fileAdded} error={this.state.errorData.attachments} />
                            </Col>}
                            {this.state.errorData.generalError && <Col xs={12}>
                                <div className="paper red">
                                    {this.state.errorData.generalError}
                                </div>
                            </Col>}
                            <Col xs={12}>
                                <UILIB.Button value="Submit Request" onClick={this.submitForm} />
                            </Col>
                        </Row>
                    </div>
                </Col>
            </div>}
        </Row>
    }
}
