export function getFormData() {
  return {
    subSites: [],
    subsiteFilter: -1,
    pageLoaded: false,
    equipment: [],
    submittingForm: false,
    submittedForm: false,
    formData: {
        yourName: "",
        emailAddress: "",
        contactNumber: "",
        companyName: "",
        department: "",
        referenceNo: "",
        faultType: -1,
        endUser: "",
        faultDesc: "",
        bestContactNo: "",
        bestContactTime: "",
        faultTypeSW: -1,
        faultTypeHW: -1,
        selectedEquipmentId: -1,
        attachments: [],
        attachmentName: "",
        faultTypeSWOther: ""
    },
    errorData: {
        yourName: "",
        emailAddress: "",
        contactNumber: "",
        companyName: "",
        department: "",
        referenceNo: "",
        faultType: "",
        endUser: "",
        faultDesc: "",
        bestContactNo: "",
        bestContactTime: "",
        faultTypeSW: "",
        faultTypeHW: "",
        selectedEquipmentId: "",
        attachment: "",
        generalError: "",
        faultTypeSWOther: ""
    },
    editLocationId: 0,
    editLocationText: "",
    searches: {
        serial: "",
        deviceName: "",
        location: "",
        assetNo: "",
        address: ""
    } 
  }
}