import React from 'react'
import { Container, Row, Col, Visible, Hidden } from 'react-grid-system';
import UILIB from '../../../common-objects/Lib'
import bgImage from '../../../assetts/images/frontPage/bg6.jpg'

export default class Downloads extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            downloads: [
                {
                    title: "Canon Printer Drivers",
                    description: "Search and find the right driver for your Canon devices",
                    link: "https://www.canon.co.uk/support/business-product-support/"
                },
                {
                    title: "Sharp Printer Drivers",
                    description: "Search and find the right driver for your Sharp devices",
                    link: "https://global.sharp/restricted/products/copier/downloads/select_us_driver.html"
                },
                {
                    title: "Ricoh Printer Drivers",
                    description: "Search and find the right driver for your Ricoh devices",
                    link: "https://www.ricoh.co.uk/support"
                },
                {
                    title: "Toshiba Printer Drivers",
                    description: "Search and find the right driver for your Toshiba devices",
                    link: "https://business.toshiba.com/support"
                },
                {
                    title: "HP Drivers",
                    description: "Search and find the right driver for your HP devices",
                    link: "https://support.hp.com/gb-en/drivers"
                },
                {
                    title: "Konica Minolta Drivers",
                    description: "Search and find the right driver for your Konica Minolta devices",
                    link: "https://www.konicaminolta.co.uk/en-gb/customer-support/download-centre"
                },
                {
                    title: "Kyocera Printer Drivers",
                    description: "Search and find the right driver for your Kyocera devices",
                    link: "http://www.kyocerasupport.co.uk/index/download_center.html"
                },
                {
                    title: "Canon Remote Viewer Software",
                    description: "Download the remove viewer software here",
                    link: "https://www.canon.co.uk/support/products/imagerunner/imagerunner-advance-c5550i-iii.html?type=download&softwaredetailid=tcm:14-1446096&os=windows%2010%20%2864-bit%29&language="
                },
                {
                    title: "Power PDF Pro Trial",
                    description: "Download a Free 45 Day Trial of Power PDF Pro",
                    link: "https://www.nuance.com/en-gb/print-capture-and-pdf-solutions/document-capture-and-workflow/ecopy/ecopy-pdf-pro-office-free-trial.html"
                }
            ]
        }
        this.changePage = this.changePage.bind(this);

    }

    componentWillMount() {
    }

    changePage(url) {
        window.open(url, '_blank')
    }

    render() {

        return <div>
            <Row className="mar-t15">
                <Col xs={12} sm={12} md={12} lg={12} style={{ marginBottom: "20px" }}>
                    <div className="overViewHeader" style={{ backgroundImage: `url(${bgImage})` }}>
                        <div className="overViewHeaderText">
                            <h1>Downloads</h1>
                            The information you need, download it here!
                            From product brochures, to instruction manuals. to print drivers. Find useful information about your Agilico Group devices here.
                        </div>
                    </div>
                </Col>
                <Col xs={12}>

                    <Row>



                        {this.state.downloads.map((download, dIndex) => {
                            return <Col xs={12} sm={12} md={12} lg={6} key={"Down_" + dIndex}>
                                <div className="paper" style={{ minHeight: "130px" }}>
                                    <Row style={{ marginBottom: "-15px" }}>
                                        <Col xs={12} sm={12} md={1} lg={1} className="text-center mar-b15">
                                            <div className={"icon-download2"} style={{ fontSize: "30px" }} />
                                        </Col>
                                        <Col xs={12} sm={12} md={7} lg={6} className="mar-b15">
                                            <div style={{ fontWeight: "bold", marginBottom: "10px" }}>

                                                <a onClick={() => { this.changePage(download.link) }}>{download.title}</a>
                                            </div>
                                            <div>{download.description}</div>
                                        </Col>
                                        <Col xs={12} sm={12} md={4} lg={5} className="text-right">
                                            <UILIB.Button value="Click Here" onClick={() => { this.changePage(download.link) }} />
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        })
                        }

                    </Row>
                </Col>
            </Row>
        </div>
    }
}
