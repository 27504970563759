import React from 'react'
import { Container, Row, Col, Visible, Hidden } from 'react-grid-system';
import UILIB from '../../common-objects/Lib'
import bgImage from '../../assetts/images/frontPage/bg6.jpg'
import Axios from '../../includes/axios';
import ViewContent from './viewContent'
export default class Guides extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            featuredGuides: [],
            standardGuides: [],
            allGuides: [],
            categories: [],
            devices: [],
            currentFilter: "all",
            currentDevice: "all",
            pageLoaded: false,
            viewingContent: false,
            viewingContentItem: {},
            searchText: "",
            startupContentId: 0
        }

        this.changePage = this.changePage.bind(this);
        this.changeFilter = this.changeFilter.bind(this);
        this.changeDevice = this.changeDevice.bind(this);
        this.getContent = this.getContent.bind(this);
        this.viewContent = this.viewContent.bind(this);
        this.closeViewContent = this.closeViewContent.bind(this);
        this.changeSearch = this.changeSearch.bind(this);

    }

    componentDidMount() {
        if (this.props.match && this.props.match.params && this.props.match.params.contentId) {
            this.setState({ startupContentId: this.props.match.params.contentId }, this.getContent);
        }
        else {
            this.getContent();
        }

    }

    getContent() {
        var self = this;
        Axios.get(`/content`).then((_res) => {

            var categories = [];
            var devices = [];
            _res.data.content.forEach(item => {
                if (!item.featured) {
                    if (!categories.find(cat => cat.value == item.category)) {
                        var catLabel = item.category.charAt(0).toUpperCase() + item.category.slice(1);
                        if (catLabel.toLowerCase() == "producttours") catLabel = "Product Tours";
                        categories.push({ "value": item.category, "label": catLabel })
                    }

                    if (!devices.find(dev => dev.value == item.deviceDescription)) {
                        devices.push({ "value": item.deviceDescription, "label": item.deviceDescription })
                    }
                }
            })

            categories.unshift({ "value": "all", "label": "All Categories" })
            devices.unshift({ "value": "all", "label": "All Devices" })
            self.setState({
                pageLoaded: true,
                allGuides: _res.data.content,
                standardGuides: _res.data.content.filter(i => !i.featured),
                featuredGuides: _res.data.content.filter(i => i.featured),
                categories: categories,
                devices: devices
            }, () => {
                if (this.state.startupContentId) {
                    var contentItem = this.state.allGuides.find(g => Number(g.id) === Number(this.state.startupContentId))
                    this.setState({ startupContentId: 0 }, () => {
                        this.viewContent(contentItem)
                    })
                }
            })

        }).catch(err => {
            this.setState({ pageLoaded: true })
        })
    }

    changePage(url) {
        this.props.history.push(url);
    }

    changeFilter(event) {
        this.setState({ currentFilter: event.target.value })
    }
    changeDevice(event) {
        this.setState({ currentDevice: event.target.value })
    }
    changeSearch(event) {
        this.setState({ searchText: event.target.value })
    }
    viewContent(contentItem) {
        this.setState({ viewingContentItem: contentItem, viewingContent: true })
    }
    closeViewContent() {
        this.setState({ viewingContent: false, viewingContentItem: {} });
    }
    render() {
        if (!this.state.pageLoaded) {
            return <div style={{ textAlign: "center" }}><UILIB.LoadingIcon /></div>
        }
        if (this.state.viewingContent) {
            return <Row className="mar-t15">
                <Col xs={12} sm={12} md={12} lg={12}>
                    <ViewContent contentItem={this.state.viewingContentItem} closeViewContent={this.closeViewContent} />
                </Col>
            </Row>
        }
        var foundGuides = false;


        return <Row className="mar-t15">
            <Col xs={12} sm={12} md={12} lg={12} style={{ marginBottom: "20px" }}>
                <div className="overViewHeader" style={{ backgroundImage: `url(${bgImage})` }}>
                    <div className="overViewHeaderText">
                        <h1>Guides</h1>
                        Wondering how your device works? Or want to get the most out of it? Take a look at some of our user guides below...
                    </div>
                </div>
            </Col>

            {(!this.state.featuredGuides.length && !this.state.standardGuides.length) && <Col xs={12} sm={12} md={12} lg={12} style={{ textAlign: "center" }}>
                No Guides Found. Please check later.
            </Col>}

            {(this.state.featuredGuides.length || this.state.standardGuides.length) && <Col xs={12} sm={12} md={12} lg={12}>
                <Row>
                    {this.state.featuredGuides.map((guide, index) => {
                        var thisIcon = "icon-document2";
                        switch (guide.fileType) {
                            case "video":
                                thisIcon = "icon-file-video";
                                break;
                            case "image":
                                thisIcon = "icon-file-image";
                                break;
                        }
                        var colSize = 4;
                        if (this.state.featuredGuides.length == 1) colSize = 12;
                        if (this.state.featuredGuides.length == 2) colSize = 6;
                        if (this.state.featuredGuides.length == 3) colSize = 4;
                        return <Col xs={12} sm={12} md={colSize} lg={colSize} key={"fGuide_" + index}>
                            <div className="paper secondary guides_featuredGuide" >
                                <div className={"icon " + thisIcon} />
                                <div style={{ flex: "1" }}>
                                    <div className="guide_featured">Featured</div>
                                    <h2>{guide.shortDesc}</h2>
                                    <div className="guide_content">{guide.longDesc}</div>
                                    <div className="guide_button"><UILIB.Button style={{ border: "2px solid white" }} value={"View"} onClick={() => {

                                        if (guide?.fileType?.toLowerCase() == "link") {
                                            window.open(guide.fileUrl, '_blank')
                                        }
                                        else {
                                            this.viewContent(guide)
                                        }
                                    }} /></div>
                                </div>
                            </div>
                        </Col>
                    })}
                </Row>
                <Row>

                    <Col xs={12} sm={12} md={4} lg={8}>
                        <UILIB.Input placeholder="Search" style={{ maxWidth: "600px" }} value={this.state.searchText} onChange={this.changeSearch} />
                    </Col>

                    <Col xs={6} sm={6} md={4} lg={2} offset={{}} className="text-right">
                        <UILIB.Select data={this.state.devices} value={this.state.currentDevice} onChange={this.changeDevice} />
                    </Col>
                    <Col xs={6} sm={6} md={4} lg={2} offset={{}} className="text-right">
                        <UILIB.Select data={this.state.categories} value={this.state.currentFilter} onChange={this.changeFilter} />
                    </Col>
                </Row>
                {(this.state.standardGuides.length > 0) && <Row>

                    {this.state.standardGuides.map((guide, index) => {
                        var thisIcon = "icon-document2";
                        var typeBg = "orange";
                        switch (guide.fileType) {
                            case "video":
                                thisIcon = "icon-file-video";
                                break;
                            case "image":
                                thisIcon = "icon-file-image";
                                break;
                        }
                        switch (guide.category) {
                            case "copying":
                                typeBg = "orange"
                                break;
                            case "printing":
                                typeBg = "purple"
                                break;
                            case "scanning":
                                typeBg = "green"
                                break;
                            case "manuals":
                                typeBg = "blue"
                                break;
                        }

                        if (this.state.currentFilter != "all") {
                            if (this.state.currentFilter != guide.category) return;
                        }
                        if (this.state.currentDevice != "all") {
                            if (this.state.currentDevice != guide.deviceDescription) return;
                        }
                        if (this.state.searchText && this.state.searchText.length) {
                            if (guide?.shortDesc?.toLowerCase().indexOf(this?.state?.searchText?.toLowerCase()) < 0) {
                                return;
                            }
                        }
                        foundGuides = true;

                        var guideUri = guide.fileUrl;
                        var guideCat = guide.category;
                        
                        if (guideCat?.toLowerCase() == "producttours") guideCat = "Product Tours"
                        return <Col xs={12} sm={12} md={4} lg={3} key={"sGuide_" + index}>
                            <div className="paper guides_standardGuide">
                                {guide.image && guide.image.length > 3 && <img src={guide.image} style={{ width: "50px", maxHeight: "50px", marginRight: "15px" }} />}
                                {(!guide.image || guide.image.length < 3) && <div className={"icon " + thisIcon} style={{ width: "50px", fontSize: "30px" }} />}
                                <div style={{ flex: "1" }}>
                                    <h2 style={{ overflowWrap: "anywhere" }}>{guide.shortDesc}</h2>
                                    <div className="guide_type" style={{ backgroundColor: typeBg, marginRight: "5px" }}>{guideCat}</div>

                                    <div className="guide_content">{guide.longDesc}</div>
                                    <div className="guide_button"><UILIB.Button className="button-small outlined" value={"View"} onClick={() => {
                                        if (guide?.fileType?.toLowerCase() == "link") {
                                            window.open(guideUri, '_blank')
                                        }
                                        else {
                                            this.viewContent(guide)
                                        }
                                    }} /></div>
                                </div>
                            </div>
                        </Col>
                    })}
                </Row>}

                {(!this.state.standardGuides.length || !foundGuides) && <Row>
                    <Col xs={12} style={{ textAlign: "center" }}>
                        <h1>No Documents Found</h1>
                        <p>Please change your search filters</p>
                    </Col>
                </Row>}


            </Col>
            }
        </Row>
    }
}
