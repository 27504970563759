import React from 'react'
import { Container, Row, Col, Visible, Hidden } from 'react-grid-system';

import GraphMeterageSpend from './graphMeterageSpend.jsx'
import GraphMonoColourVolumes from './graphMonoColourVolumes.jsx'
import GraphColorPctAgainstTotalVolume from './graphColorPctAgainstTotalVolume'
import TableVolumeStatisticsSummary from './tableVolumeStatisticsSummary'
export default class DevicesIndex extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

        }


    }
    render() {
        if (this.props.invalidDateRange) {
            return <div></div>
        }
        return <Row>

            <Col xs={12} sm={12} md={12} lg={12}>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <div className="paper">
                            <h2>Volume Statistics Summary</h2>
                            <TableVolumeStatisticsSummary {...this.props} />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <div className="paper">
                            <h2>Service Spend</h2>
                            <GraphMeterageSpend {...this.props} />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={12} md={6} lg={6}>
                        <div className="paper">
                            <h2>Mono/Colour Volumes</h2>
                            <GraphMonoColourVolumes {...this.props} />
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={6}>
                        <div className="paper">
                            <h2>Colour % against Total Volume</h2>
                            <GraphColorPctAgainstTotalVolume {...this.props} />
                        </div>
                    </Col>
                </Row>

            </Col>
        </Row>
    }
}
