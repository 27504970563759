import React, { Component } from 'react';

class TemplateImageLoader extends Component {
    constructor(props) {
        super(props);
        let timer;
        this.state = {
            templateImageLoaded: false,
            rndNo: Math.random() * 99999999,
            loadTries: 0
        }
        this.checkTemplateImageExists = this.checkTemplateImageExists.bind(this);
    }

    componentWillUnmount() {
        clearTimeout(this.timer)
    }
    checkTemplateImageExists() {
        if (this.state.templateImageLoaded) return;
        if (this.state.loadTries > 10) {
            return;
        }
        var loadTries = this.state.loadTries + 1;
        this.setState({ loadTries })
        var self = this;
        var img = new Image();
        img.onload = function () { self.setState({ templateImageLoaded: true }) };
        img.onerror = function () { self.timer = setTimeout(self.checkTemplateImageExists, 1000); };
        img.src = this.props.src;
    }

    componentWillMount() {
        this.checkTemplateImageExists();
    }

    render() {
        var imageUrl = this.props.src + "?rnd=" + this.state.rndNo
        var backgroundImage = imageUrl;

        if (this.state.loadTries > 10) {
            backgroundImage = "https://cdn1.ourmailsender.com/siteContent/assets/templates/common/unknownTemplate.jpg";
            return <img src={backgroundImage} style={this.props.style} alt={this.props.title} title={this.props.title} className={this.props.className} />
        }

        if (this.state.templateImageLoaded) {

            return <img src={backgroundImage} style={this.props.style} alt={this.props.title} title={this.props.title} className={this.props.className} />
        }
        else {
            return <div alt={this.props.title} title={this.props.title} className={this.props.className}><UILIB.LoadingIcon style={{ width: "100px" }} /></div>

        }

    }
}

export default TemplateImageLoader;