export function getFormData(props) {
  return {
    pageLoaded: false,
    submittingForm: false,
    submittedForm: false,
    work: [],
    formData: {
        yourName: props.account.FirstName && props.account.LastName ? props.account.FirstName + " " + props.account.LastName : "",
        emailAddress: props.account.EmailAddress ? props.account.EmailAddress : "",
        contactNumber: props.account.phone ? props.account.phone : "",
        companyName: props.currCustomer.Name,
        department: "",
        selectedJobId: -1,
        deviceModel: "",
        serialNumber: "",
        referenceNo: ""
    },
    errorData: {
        yourName: "",
        emailAddress: "",
        contactNumber: "",
        companyName: "",
        department: "",
        generalError: ""
    }
  }
}