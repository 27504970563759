import React, { Component } from 'react';

class Avatar extends Component {
    constructor(props) {
        super(props);

    }



    render() {
        return <div className="avatarHolder" style={{ backgroundImage: `url(${this.props.src})` }}>
        </div>

    }
}

export default Avatar;