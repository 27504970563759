import React from 'react'
import { Container, Row, Col, Visible, Hidden } from 'react-grid-system';
import UILIB from '../../../common-objects/Lib'
import bgImage from '../../../assetts/images/frontPage/bg6.jpg'
import axios from '../../../includes/axios'
import StickyTable from "react-sticky-table-thead"
import Drawer from '../../includes/drawer.jsx';
import { getFormData } from './defaults';

export default class RequestEngineer extends React.Component {
    constructor(props) {
        super(props);

        this.state = getFormData(this.props);
        this.getEquipment = this.getEquipment.bind(this);
        this.getStaples = this.getStaples.bind(this);
        this.updateField = this.updateField.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.changeConsumablesOrdered = this.changeConsumablesOrdered.bind(this);
        this.viewStaplePrices = this.viewStaplePrices.bind(this);
    }

    componentDidMount() {
        var newState = this.state;
        newState.formData.emailAddress = this.props.account.emailAddress;
        newState.formData.yourName = this.props.account.firstName + " " + this.props.account.lastName;
        newState.formData.companyName = this.props.currCustomer.Name;
        var subSites = this.props.subSites.map(site => {
            return { value: site.id, label: site.altName }
        })
        subSites = subSites.sort((a, b) => {
            var nameA = (a.label);
            var nameB = (b.label);
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;

            }
            // names must be equal
            return 0;

        })
        subSites.unshift({ value: -1, label: "All Sites" })
        newState.subSites = subSites;

        this.setState(newState, this.getEquipment);

    }

    getEquipment() {
        axios.get(`/equipment?includeTonerParts=1&includeOrders=true`).then(_equipment => {
            this.setState({ equipment: _equipment.data.equipment }, this.getStaples)
        })
    }
    getStaples() {

        var serials = this.state.equipment.map(eq => { return eq.itemId });
        let uniqueSerials = [...new Set(serials)];

        let serialsText = uniqueSerials.join();


        axios.get(`/consumable/staples?serials=${serialsText}`).then(_staples => {
            this.setState({ staplePrices: _staples.data.staples, pageLoaded: true })
        })
    }

    updateField(event) {
        var state = this.state;
        var value = event.target.value;
        if (event.target.type == "checkbox") {
            value = event.target.checked;
        }
        state.formData[event.target.name] = value;
        this.setState(state)
    }

    changeConsumablesOrdered(parentMachine, event) {
        const { target } = event;
        const value = parseInt(target.value);
        const min = parseInt(target.min);
        const max = parseInt(target.max);

        if (value >= min && (!isNaN(max) && value <= max || isNaN(max))) {
            var formData = this.state.formData;
            if (!formData.consumablesOrdered[parentMachine.id]) {
                formData.consumablesOrdered[parentMachine.id] = {
                    serial: parentMachine.serialnumber,
                    deviceName: parentMachine.description,
                    location: parentMachine.Location,
                    accountNo: parentMachine.accountNumber,
                    assetNo: parentMachine.AssetNumber
                }
            }
            formData.consumablesOrdered[parentMachine.id][event.target.name] = event.target.value;

            this.setState({ formData });
        }
    }

    handleInputRestriction(event) {
        const { target } = event;
        const value = parseInt(target.value);
        const min = parseInt(target.min);
        const max = parseInt(target.max);
        if (isNaN(value) || value < min || value > max) {
            event.preventDefault();
        }
    }

    submitForm() {
        var failed = false;
        var errorData = {
            yourName: "",
            emailAddress: "",
            contactNumber: "",
            companyName: "",
            department: "",
            poNumber: "",
            notes: "",
            generalError: ""
        }


        if (this.state.formData.yourName.length < 5) {
            errorData.yourName = "Please enter a valid name";
            failed = true;
        }
        if (this.state.formData.emailAddress.length < 5 || !this.state.formData.emailAddress.indexOf('@') || !this.state.formData.emailAddress.indexOf('.')) {
            errorData.emailAddress = "Please enter a valid email address";
            failed = true;
        }
        if (this.state.formData.contactNumber.length < 5) {
            errorData.contactNumber = "Please enter a valid number";
            failed = true;
        }
        if (this.state.formData.companyName.length < 3) {
            errorData.companyName = "Please enter name";
            failed = true;
        }

        if (this.state.formData.urgent && this.state.formData.urgentDeliverySignature.length < 3) {
            errorData.urgentDeliverySignature = "Please enter your name to confirm Delivery";
            failed = true;
        }

        if (failed) {
            errorData.generalError = "Required field(s) missing. Please check your entries above";
        }
        if (!Object.keys(this.state.formData.consumablesOrdered).length) {
            errorData.generalError = "Please select at least one consumable to order";
            failed = true;
        }

        if (failed) {
            this.setState({ errorData: errorData })
            return
        }


        this.setState({ errorData: errorData, submittingForm: true, submittedForm: false })
        var self = this;

        axios.post(`/utilities/consumableRequest?onlyCopiers=true`, this.state.formData).then(_result => {
            this.setState({ 
              ...getFormData(this.props), 
              equipment: this.state.equipment,
              subSites: this.state.subSites,
              submittingForm: false, 
              submittedForm: true, 
              pageLoaded: true })
        }).catch(err => {
            var theState = self.state;
            theState.errorData.generalError = "There was an issue submitting the form, please try again"
            theState.submittingForm = false
            self.setState({ theState })
        })
    }

    viewStaplePrices() {

        var drawer = <Drawer title="Staple Prices" close={() => {
            this.setState({ drawer: "" })
        }}>


            <div className="standardTable" style={{ height: "calc(100% - 100px)" }}>
                <table width="100%" cellPadding="5" cellSpacing="0">
                    {this.state.staplePrices.map(staple => {
                        return <tr>
                            <td>
                                <div style={{ fontSize: "12px", fontWeight: "bold" }}>{staple.Description}</div>
                                <div style={{ fontSize: "12px" }}>{staple.PartNumber}</div>
                            </td>
                            <td>£{staple.BaseUnitPrice}</td>
                        </tr>
                    })
                    }
                </table >
            </div>
        </Drawer >
        this.setState({ drawer })
    }

    render() {
        if (!this.state.pageLoaded || this.state.submittingForm) return <div style={{ textAlign: "center" }}><UILIB.LoadingIcon /></div>

        var showAssetNo = false;
        this.state.equipment.forEach(eq => {
            if (eq.AssetNumber && eq.AssetNumber.length) {
                showAssetNo = true;
            }
        })
        return <div>
            <Row className="mar-t15">
                <Col xs={12} sm={12} md={12} lg={12} style={{ marginBottom: "20px" }}>
                    <div className="overViewHeader" style={{ backgroundImage: `url(${bgImage})` }}>
                        <div className="overViewHeaderText">
                            <h1>Request Consumables</h1>
                            If you require consumables please complete the following form, and our service department will handle your request as soon as possible.
                        </div>
                    </div>
                </Col>
            </Row>
            {this.state.errorData.generalError &&
                <Col xs={12} style={{ marginBottom: "20px" }}>
                    <div style={{
                        backgroundColor: "red",
                        color: "white",
                        padding: "10px",
                        textAlign: "center"
                    }}>
                        {this.state.errorData.generalError}
                    </div>
                </Col>
            }


            {this.state.submittedForm && <Row className="mar-t15">
                <Col xs={12}>
                    <div className="paper">
                        <h2>Request Submitted</h2>
                        <p>Thank you for submitting your Request.</p>
                        <p>A member of staff should be back in contact with you on the contact details you provided shortly.</p>
                        <UILIB.Button value="Continue" onClick={() => { this.setState({ submittedForm: false }) }} />
                    </div>
                </Col>
            </Row>
            }
            {
                (!this.state.submittingForm && !this.state.submittedForm) && <Row className="mar-t15">
                    <Col xs={12}>
                        <div className="paper">
                            <h2>Your Contact Details</h2>
                            <Row>
                                <Col xs={12} sm={6} md={4}>
                                    <label>Your Name</label>
                                    <UILIB.Input type="text" name="yourName" value={this.state.formData.yourName} onChange={this.updateField} error={this.state.errorData.yourName} />
                                </Col>
                                <Col xs={12} sm={6} md={4}>
                                    <label>Your Email</label>
                                    <UILIB.Input type="email" name="emailAddress" value={this.state.formData.emailAddress} onChange={this.updateField} error={this.state.errorData.emailAddress} />
                                </Col>
                                <Col xs={12} sm={6} md={4}>
                                    <label>Contact Number</label>
                                    <UILIB.Input type="text" name="contactNumber" value={this.state.formData.contactNumber} onChange={this.updateField} error={this.state.errorData.contactNumber} />
                                </Col>
                                <Col xs={12} sm={6} md={4}>
                                    <label>Company Name</label>
                                    <UILIB.Input type="text" name="companyName" value={this.state.formData.companyName} onChange={this.updateField} error={this.state.errorData.companyName} />
                                </Col>
                                <Col xs={12} sm={6} md={4}>
                                    <label>Department</label>
                                    <UILIB.Input type="text" name="department" value={this.state.formData.department} onChange={this.updateField} error={this.state.errorData.department} />
                                </Col>
                            </Row>
                        </div>
                    </Col>

                    <Col xs={12}>
                        <div className="paper">
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <h2>Device Affected</h2>
                                <h4>Please note: A maximum of 1 of each consumable can be ordered at a time. Please contact <a href='mailto:toner@agilico.co.uk'>toner@agilico.co.uk</a> to order more.</h4>
                                <UILIB.Select data={this.state.subSites}
                                    onChange={(ev) => { this.setState({ subsiteFilter: ev.target.value }) }} />
                            </div>
                            <div className="standardTable" style={{ marginBottom: "15px" }}>
                                <StickyTable height={600}>
                                    <table width="100%" cellPadding="10" cellSpacing="0">
                                        <thead>
                                            <tr>
                                                <th >
                                                    Serial
                                                </th>
                                                <th >
                                                    Device
                                                </th>
                                                {showAssetNo && <th>
                                                    Asset No
                                                </th>}
                                                <th width="20" >
                                                    Location
                                                </th>
                                                <th width="100">
                                                    <div style={{ width: "100px" }}>
                                                        <div style={{ marginRight: "5px", display: "inline-block", borderRadius: "100px", width: "15px", height: "15px", backgroundColor: "black", color: "white" }} />
                                                        Black Toner
                                                    </div>
                                                </th>
                                                <th width="100" >
                                                    <div style={{ width: "100px" }}>
                                                        <div style={{ marginRight: "5px", display: "inline-block", borderRadius: "100px", width: "15px", height: "15px", backgroundColor: "cyan" }} />
                                                        Cyan Toner
                                                    </div>
                                                </th>
                                                <th width="100" >
                                                    <div style={{ width: "110px" }}>
                                                        <div style={{ marginRight: "5px", display: "inline-block", borderRadius: "110px", width: "15px", height: "15px", backgroundColor: "magenta" }} />
                                                        Magenta Toner
                                                    </div>
                                                </th>
                                                <th width="100" >
                                                    <div style={{ width: "100px" }}>
                                                        <div style={{ marginRight: "5px", display: "inline-block", borderRadius: "100px", width: "15px", height: "15px", backgroundColor: "yellow" }} />
                                                        Yellow Toner
                                                    </div>
                                                </th>
                                                <th width="90" >
                                                    Waste Toner
                                                </th>
                                                <th width="90" >
                                                    Staples
                                                </th>
                                                <th width="90" >
                                                    Booklet Staples
                                                </th>
                                                <th width="90" >
                                                    Print Heads
                                                </th>
                                                <th width="90" >
                                                    Wide Format Main Cartridge
                                                </th>
                                                {/* <th width="90" style={{ backgroundColor: "#000033" }}>
                                                    Waste Return Cardboard Boxes
                                                </th> */}
                                            </tr>
                                        </thead>
                                        {this.state.equipment.map((eq, index) => {
                                            var style = {};
                                            if (this.state.subsiteFilter != -1 && this.state.subsiteFilter != eq.customerId) return;

                                            var blackPart = "";
                                            var cyanPart = "";
                                            var magentaPart = "";
                                            var yellowPart = "";
                                            var wastePart = "";


                                            if (eq.compatibleItems.black) {
                                                blackPart = eq.compatibleItems.black.desc

                                            }
                                            if (eq.compatibleItems.cyan) {
                                                cyanPart = eq.compatibleItems.cyan.desc

                                            }
                                            if (eq.compatibleItems.magenta) {
                                                magentaPart = eq.compatibleItems.magenta.desc

                                            }
                                            if (eq.compatibleItems.yellow) {
                                                yellowPart = eq.compatibleItems.yellow.desc

                                            }
                                            if (eq.compatibleItems.waste) {
                                                wastePart = eq.compatibleItems.waste.desc

                                            }


                                            var blackNo = 0;
                                            var cyanNo = 0;
                                            var magentaNo = 0;
                                            var yellowNo = 0;
                                            var wasteNo = 0;
                                            var staplesNo = 0;
                                            var bookletstaplesNo = 0;
                                            var printheadsNo = 0;
                                            var wideformatcartNo = 0;
                                            var wastereturnboxesNo = 0;

                                            var consumablesOrdered = this.state.formData.consumablesOrdered;
                                            if (consumablesOrdered[eq.id]) {
                                                if (consumablesOrdered[eq.id].black) blackNo = consumablesOrdered[eq.id].black;
                                                if (consumablesOrdered[eq.id].cyan) cyanNo = consumablesOrdered[eq.id].cyan;
                                                if (consumablesOrdered[eq.id].magenta) magentaNo = consumablesOrdered[eq.id].blmagentaack;
                                                if (consumablesOrdered[eq.id].yellow) yellowNo = consumablesOrdered[eq.id].yellow;
                                                if (consumablesOrdered[eq.id].waste) wasteNo = consumablesOrdered[eq.id].waste;
                                                if (consumablesOrdered[eq.id].staples) staplesNo = consumablesOrdered[eq.id].staples;
                                                if (consumablesOrdered[eq.id].bookletstaples) bookletstaplesNo = consumablesOrdered[eq.id].bookletstaples;
                                                if (consumablesOrdered[eq.id].printheads) printheadsNo = consumablesOrdered[eq.id].printheads;
                                                if (consumablesOrdered[eq.id].wideformatcart) wideformatcartNo = consumablesOrdered[eq.id].wideformatcart;
                                                if (consumablesOrdered[eq.id].wastereturnboxes) wastereturnboxesNo = consumablesOrdered[eq.id].wastereturnboxes;
                                            }


                                            return <tr key={"eq_" + index} style={style} >

                                                <td valign="top">
                                                    {eq.serialnumber}
                                                    {eq.alreadyOnOrder && eq.alreadyOnOrder != "0" && <div style={{ color: "red" }}>{eq.alreadyOnOrder} toners on order</div>}
                                                </td>
                                                <td valign="top">
                                                    {eq.description}
                                                    {eq.activeOrders.map(ord => {
                                                        var color = "black";
                                                        var txtColor = "white";
                                                        switch (ord.ConsumableTypeID) {
                                                            case "1":
                                                                color = "black"
                                                                txtColor = "white";
                                                                break;
                                                            case "2":
                                                                color = "cyan"
                                                                txtColor = "white";
                                                                break;
                                                            case "3":
                                                                color = "magenta"
                                                                txtColor = "white";
                                                                break;
                                                            case "4":
                                                                color = "yellow"
                                                                break;
                                                            case "5":
                                                                color = "waste"
                                                                break;

                                                        }
                                                        return <div style={{ backgroundColor: color, marginTop: "10px", border: "2px solid " + color, borderRadius: "10px", fontSize: "12px", padding: "5px", textAlign: "center" }}>
                                                            <div style={{ color: txtColor }}><span style={{ textTransform: "capitalize", color: txtColor }}>{color}</span> on Order</div>
                                                            <div style={{ fontSize: "11px", color: txtColor }}>Ref: {ord.Reference}</div>
                                                        </div>
                                                    })}
                                                </td>
                                                {showAssetNo && <td valign="top">
                                                    {eq.AssetNumber}
                                                </td>}
                                                <td valign="top">
                                                    {eq.Location}
                                                </td>
                                                <td valign="top">
                                                    {!blackPart && <div>N/A</div>}
                                                    {blackPart && <div>
                                                        <UILIB.Input type="number" name={"black"} value={blackNo} disabled={!blackPart} onChange={(event) => { this.changeConsumablesOrdered(eq, event) }} onKeyDown={(event) => { this.handleInputRestriction(event) }} min="0" max="1" />
                                                        <div style={{ fontSize: "10px" }}>
                                                            Part Number:
                                                            <div>{blackPart}</div>
                                                        </div>
                                                    </div>}
                                                </td>
                                                <td valign="top">
                                                    {!cyanPart && <div>N/A</div>}
                                                    {cyanPart && <div>
                                                        <UILIB.Input type="number" name={"cyan"} value={cyanNo} disabled={!cyanPart} onChange={(event) => { this.changeConsumablesOrdered(eq, event) }} onKeyDown={(event) => { this.handleInputRestriction(event) }} min="0" max="1" />
                                                        <div style={{ fontSize: "10px" }}>
                                                            Part Number:
                                                            <div>{cyanPart}</div>
                                                        </div>
                                                    </div>}
                                                </td>
                                                <td valign="top">
                                                    {!magentaPart && <div>N/A</div>}
                                                    {magentaPart && <div>
                                                        <UILIB.Input type="number" name={"magenta"} value={magentaNo} disabled={!magentaPart} onChange={(event) => { this.changeConsumablesOrdered(eq, event) }} onKeyDown={(event) => { this.handleInputRestriction(event) }} min="0" max="1" />
                                                        <div style={{ fontSize: "10px" }}>
                                                            Part Number:
                                                            <div>{magentaPart}</div>
                                                        </div>
                                                    </div>}
                                                </td>
                                                <td valign="top">
                                                    {!yellowPart && <div>N/A</div>}
                                                    {yellowPart && <div>
                                                        <UILIB.Input type="number" name={"yellow"} value={yellowNo} disabled={!yellowPart} onChange={(event) => { this.changeConsumablesOrdered(eq, event) }} onKeyDown={(event) => { this.handleInputRestriction(event) }} min="0" max="1" />
                                                        <div style={{ fontSize: "10px" }}>
                                                            Part Number:
                                                            <div>{yellowPart}</div>
                                                        </div>
                                                    </div>}
                                                </td>
                                                <td valign="top">
                                                    {!wastePart && <div>N/A</div>}
                                                    {wastePart && <div>
                                                        <UILIB.Input type="number" name={"waste"} value={wasteNo} onChange={(event) => { this.changeConsumablesOrdered(eq, event) }} min="0" />
                                                        <div style={{ fontSize: "10px" }}>
                                                            Part Number:
                                                            <div>
                                                                {wastePart}
                                                            </div>
                                                        </div>
                                                    </div>}
                                                </td>
                                                <td valign="top">
                                                    <UILIB.Input type="number" name={"staples"} value={staplesNo} onChange={(event) => { this.changeConsumablesOrdered(eq, event) }} onKeyDown={(event) => { this.handleInputRestriction(event) }} min="0" max="1" />
                                                </td>
                                                <td valign="top">
                                                    <UILIB.Input type="number" name={"bookletstaples"} value={bookletstaplesNo} onChange={(event) => { this.changeConsumablesOrdered(eq, event) }} onKeyDown={(event) => { this.handleInputRestriction(event) }} min="0" max="1" />
                                                </td>
                                                <td valign="top">
                                                    <UILIB.Input type="number" name={"printheads"} value={printheadsNo} onChange={(event) => { this.changeConsumablesOrdered(eq, event) }} onKeyDown={(event) => { this.handleInputRestriction(event) }} min="0" max="1" />
                                                </td>
                                                <td valign="top">
                                                    <UILIB.Input type="number" name={"wideformatcart"} value={wideformatcartNo} onChange={(event) => { this.changeConsumablesOrdered(eq, event) }} onKeyDown={(event) => { this.handleInputRestriction(event) }} min="0" max="1" />
                                                </td>
                                                {/* <td valign="top">
                                                    <UILIB.Input type="number" name={"wastereturnboxes"} value={wastereturnboxesNo} onChange={(event) => { this.changeConsumablesOrdered(eq, event) }} min="0" />
                                                </td> */}
                                            </tr>
                                        })
                                        }
                                    </table>
                                </StickyTable>
                            </div>
                            <div style={{ textAlign: "right" }}>
                                <UILIB.Button className="outlined" value="View Staple Prices" style={{ border: "2px solid white", fontSize: "12px", padding: "5px 10px 5px 10px" }} onClick={this.viewStaplePrices} />
                            </div>
                        </div>
                    </Col>

                    <Col xs={12}>
                        <div className="paper">
                            <h2>Extra Information</h2>
                            <Row>

                                <Col xs={12} sm={6} md={4}>
                                    <label>Your PO Number</label>
                                    <UILIB.Input type="text" name="poNumber" value={this.state.formData.poNumber} onChange={this.updateField} error={this.state.errorData.poNumber} />
                                </Col>
                                <Col xs={12} sm={6} md={8}>
                                    <label>Additional Notes</label>
                                    <UILIB.TextArea name="notes" value={this.state.formData.notes} onChange={this.updateField} error={this.state.errorData.notes} />
                                </Col>
                                <Col xs={12} style={{ display: "flex" }} className="mar-b15">
                                    <UILIB.CheckBox name="urgent" checked={this.state.formData.urgent} value={this.state.formData.urgent} outerStyle={{ marginRight: "10px" }} onChange={this.updateField} />
                                    <div>
                                        By ticking this box you have selected for this request to be processed as an urgent ‘Before 10:30 Next Day delivery’ - as such you have accepted there will be an additional
                                        charge applied to the order. Our normal service will generally take 1-2 working days and is not charged for contracted customers.
                                        If you have any questions please contact our Toner Team on 0208 688 4243 (opt 2) or <a href="mailto:toner@agilico.co.uk">toner@agilico.co.uk</a>
                                    </div>
                                </Col>

                                {(this.state.formData.urgent == true) && <Col xs={12} className="mar-b15">
                                    <div className="paper" style={{ border: "2px dotted red" }}>
                                        <Row>
                                            <Col xs={12} className="mar-b15">
                                                <h2 style={{ marginBottom: "10px" }}>Urgent Order Form</h2>
                                                <div>Please fill in the below information in order to confirm that you agree to be charged for the quoted price for your order.</div>
                                            </Col>
                                            <Col xs={12} className="mar-b15">
                                                <label>Delivery Type</label>
                                                <UILIB.Select data={
                                                    [
                                                        { value: "2", label: "Next Day - (£8.95 + vat)" },
                                                        { value: "1", label: "Next Day Before 12:00pm - (£20.00 + vat)" },
                                                        { value: "3", label: "Next Day Before 10:30am - (£30.00 + vat)" }

                                                    ]
                                                } name="urgentDeliveryType" onChange={this.updateField} />
                                                {(this.state.formData.urgentDeliveryType == -1) && <div style={{ color: "red" }}>
                                                    Same Day delivery is only available in certain areas. For more information please contact <a href="mailto:toner@agilico.co.uk">toner@agilico.co.uk</a>
                                                </div>}
                                            </Col>

                                            <Col xs={12}>
                                                <label>I agree</label>
                                                <UILIB.Input type="text" name="urgentDeliverySignature" placeholder="Type your Name to Confirm" onChange={this.updateField} error={this.state.errorData.urgentDeliverySignature} />
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>}
                                {this.state.errorData.generalError && <Col xs={12}>
                                    <div className="errorTextHolder mar-b15">{this.state.errorData.generalError}</div>
                                </Col>}
                                <Col xs={12}>
                                    <UILIB.Button value="Submit Request" onClick={this.submitForm} />
                                    <h5>Please note: A maximum of 1 of each consumable can be ordered at a time. Please contact <a href='mailto:toner@agilico.co.uk'>toner@agilico.co.uk</a> to order more.</h5>
                                </Col>

                            </Row>
                        </div>
                    </Col>
                </Row>
            }
            {this.state.drawer}
        </div >

    }
}
