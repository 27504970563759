import React from 'react'
import { Container, Row, Col, Visible, Hidden } from 'react-grid-system';
import UILIB from '../../../../common-objects/Lib'
import axios from '../../../../includes/axios';
import Utils from '../../../../config/utils';

export default class Invoices extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            tableDataRaw: [],
            tableData: [],
            pageLoaded: false,
            subSites: [],
            subsiteFilter: -1
        }

        this.getData = this.getData.bind(this)
        this.downloadInvoice = this.downloadInvoice.bind(this);
    }

    componentWillMount() {
        var subSites = this.props.subSites.map(site => {
            return { value: site.id, label: site.altName }
        })

        subSites.unshift({ value: -1, label: "All Sites" })
        this.setState({ subSites }, this.getData());
    }

    actionChangefilter(value) {
      const result = (Number(value) === -1)
        ?  this.state.tableDataRaw
        :  this.state.tableDataRaw.filter(x => Number(x.CustomerId) === Number(value))
      this.setState({ ...this.state, tableData: result })
    }

    getData() {
        axios.get(`/invoice`).then(_response => {
            this.setState({ tableData: _response.data.invoices, tableDataRaw: _response.data.invoices, pageLoaded: true })
        })
    }

    downloadInvoice(invoiceId, reference) {
        axios.get(`/invoice/download/${invoiceId}`,
            {
                responseType: 'blob'
            })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'invoice' + reference + '.pdf');
                document.body.appendChild(link);
                link.click();
            })
    }

    render() {
        if (!this.state.pageLoaded) return <div style={{ textAlign: "center" }}><UILIB.LoadingIcon /></div>
        return <div className="paper">
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <UILIB.Select data={this.state.subSites}
                    onChange={(ev) => { this.actionChangefilter(ev.target.value) }} />
            </div>
            <div className="standardTable">
                <table width="100%" cellPadding="10" cellSpacing="0">
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Ref No</th>
                            <th>Company</th>
                            <th>Value</th>
                            <td></td>

                        </tr>
                    </thead>
                    {this.state.tableData.map((item, index) => {

                        var valueStyle = {};
                        var baseTotal = item.BaseTotalValue;
                        if (baseTotal) baseTotal = Number(baseTotal).toFixed(2);
                        if (item.reference && item.reference.indexOf("CRN") > -1) {
                            valueStyle.color = "red"
                            baseTotal = "-" + baseTotal;
                        }

                        return <tr key={"tr_" + index}>

                            <td>{Utils.formatDateTime(item.InvoiceDate, 2)}</td>
                            <td>{item.reference}</td>
                            <td>{item.Name}</td>
                            <td style={valueStyle}>&pound;{baseTotal}</td>
                            <td style={{ textAlign: "center" }}>
                                {(item.EmailedDate || item.PrintedDate) && <a onClick={() => { this.downloadInvoice(item.id, item.reference) }}><span className="icon icon-download2" /> Download</a>}
                                {(!item.EmailedDate && !item.PrintedDate) && <span>Invoice Available Soon</span>}
                            </td>

                        </tr>
                    })}
                </table>
            </div>
        </div>
    }
}
