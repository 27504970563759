import React from 'react'
import { Container, Row, Col, Visible, Hidden } from 'react-grid-system';
import UILIB from '../../common-objects/Lib'
import Axios from '../../includes/axios';

export default class OverViewGuidesWidget extends React.Component {
    constructor(props) {
        super(props);

        let timer;
        this.state = {
            contentItems: []
        }
        this.getStats = this.getStats.bind(this);
        this.viewContent = this.viewContent.bind(this);
    }

    componentWillMount() {
        this.getStats();
    }

    viewContent(guide) {
        if (guide.fileType.toLowerCase() == "link") {
            window.open(guide.fileUrl, '_blank')
        }
        else {
            this.props.history.push('/guides/' + guide.id);
        }

    }

    getStats() {
        var self = this;
        Axios.get(`/content?limit=12`).then((_res) => {

            var contentItems = _res.data.content.sort((a, b) => b.featured - a.featured)
            contentItems.splice(0, 1)
            self.setState({
                pageLoaded: true,
                contentItems: contentItems
            })
        }).catch(() => {
            this.setState({ pageLoaded: true })
        })
    }

    render() {
        if (!this.state.pageLoaded) {
            return <div style={{ textAlign: "center" }}><UILIB.LoadingIcon /></div>
        }

        if (!this.state.contentItems.length) return <Row>
            <Col xs={12} sm={12} md={12} lg={12} style={{ textAlign: "center" }}>
                <div style={{ fontWeight: "bold", textAlign: "center" }}>No Guides Found</div>
                Please check back later
            </Col>
        </Row>
        return <Row>
            {this.state.contentItems.map((cItem, index) => {
                var thisIcon = "icon-document2";
                switch (cItem.fileType) {
                    case "video":
                        thisIcon = "icon-file-video";
                        break;
                    case "image":
                        thisIcon = "icon-file-image";
                        break;
                }


                return <Col xs={12} sm={12} md={6} lg={6}>
                    <div className="overViewGuideLinks" onClick={() => { this.viewContent(cItem) }}>
                        {cItem.image && cItem.image.length > 3 && <img src={cItem.image} style={{ width: "50px", maxHeight: "50px", marginRight: "15px" }} />}
                        {(!cItem.image || cItem.image.length < 3) && <div style={{ width: "50px" }}><div className={"icon " + thisIcon} style={{ width: "50px", fontSize: "30px" }} /></div>}
                        <span className="ovContent">
                            <div style={{ marginBottom: "5px" }}>{cItem.shortDesc}</div>
                            {/* <div className="text-small">{cItem.longDesc}</div> */}
                        </span>
                    </div>
                </Col>
            })}
        </Row>
    }
}
