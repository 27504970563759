import React from 'react'
import { Container, Row, Col, Visible, Hidden } from 'react-grid-system';
import UILIB from '../../common-objects/Lib'
import axiosInstance from '../../includes/axios';

export default class KpaxReports extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pageLoaded: false,
            emailAddress: "",
            accessToken: ""
        }

        this.getAccessToken = this.getAccessToken.bind(this);
    }

    componentDidMount() {
        this.getAccessToken();
    }

    getAccessToken() {
        var self = this;
        axiosInstance.get("/kpax/dashboardAccessToken").then(_response => {
            self.setState({ pageLoaded: true, accessToken: _response.data.accessToken, emailAddress: _response.data.emailAddress })
        }).catch(err => {

        })
    }


    render() {
        if (!this.state.pageLoaded) return <div style={{ textAlign: "center" }}><UILIB.LoadingIcon /></div>
        return <Row>
            <Col xs={12}>
                <iframe src={`https://stats.kpax.dmcplc.co.uk/auth/link_authenticate?email=${this.state.emailAddress}&api_token=${this.state.accessToken}`} style={{ width: "100%", height: "700px", border: "0px" }} border="0"></iframe>
            </Col>
        </Row>
    }
}
