import React from 'react'
import { Container, Row, Col, Visible, Hidden } from 'react-grid-system';
import UILIB from '../Lib'


export default class EventCard extends React.Component {
    constructor(props) {
        super(props)


    }

    render() {
        return <div className="eventCard"></div>
    }
}
