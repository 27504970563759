import React from 'react'
import ReactDOM from 'react-dom';

export default class bb_dropdown extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isOpen: false, ourWidth: 0 };
        this.handleClick = this.handleClick.bind(this);
        this.checkListener = this.checkListener.bind(this);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.n != this.state.isOpen) {
            this.setState({ isOpen: nextState.isOpen }, this.checkListener);
            return true;
        }
        if (nextProps != this.props) {
            return true;
        }
        return false;
    }
    componentDidMount() {
        var ourWidth = this.refs.outerCont.offsetWidth;
        this.setState({ isOpen: true, ourWidth: ourWidth }, this.checkListener);
    }

    checkListener() {
        if (this.state.isOpen === true) {
            document.addEventListener('click', this.handleClick, false);
        }
        else {
            document.removeEventListener('click', this.handleClick, false);
        }
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClick, false);
    }

    handleClick(e) {
        //prevents parent click if needed

        e.stopPropagation();
        e.preventDefault();

        // if (!ReactDOM.findDOMNode(this).contains(e.target) || e.target.localName === "a") {
        //     this.setState({ isOpen: false });
        //     this.props.onClose(this.props.id);
        // }
    }

    render() {
        var self = this;
        var theStyle = this.props.style || {};

        if (this.props.align === "left") {
            theStyle.right = this.state.ourWidth;
        }
        if (this.props.align === "right") {
            theStyle.left = this.state.ourWidth;
        }

        if (this.props.top) {
            theStyle.top = this.props.top;
        }

        if (this.props.width) {
            theStyle.width = this.props.width;
        }

        theStyle.display = "none";
        if (this.state.isOpen === true) {
            theStyle.display = this.props.display || "block";
        }

        if (this.state.isOpen == true) {
            return <div className="pos-rel" ref="outerCont" onClick={this.handleClick}>
                <div style={theStyle} className={"topnav-dropdown " + this.props.className}>
                    {/* {this.props.children} */}
                </div>
            </div>
        }
        else {
            return <div className="pos-rel" ref="outerCont"></div>
        }

    }
}