import Axios from '../includes/axios';

class SessionHelpers {

    static checkAccount(context) {

        var loggedIn = false;
        var accessToken = localStorage.getItem(`accessToken_${context}`);
        if (accessToken && accessToken.length) {
            loggedIn = true;
        }
        return loggedIn;
    }

    static logout(context) {
        localStorage.removeItem(`accessToken_${context}`);
        sessionStorage.removeItem("customerContext");
        window.open('/', '_self')
    }

    static getAccount() {
        var customer = {};
        return Axios.get("/customerPerson").then((response) => {
            customer = response.data;
            return Axios.get("/customerPerson/allSites")
        }).then(subSites => {
            return {
                customer: customer,
                subSites: subSites.data.results
            }
        })
    }

}
export default SessionHelpers