import React, { Component } from 'react';

class CheckBox extends Component {
    constructor(props) {
        super(props);
        let timer;
        this.state = {
        }

    }




    render() {

        var props = JSON.parse(JSON.stringify(this.props));
        var outerStyle = {};
        if (props.outerStyle) {
            outerStyle = JSON.parse(JSON.stringify(props.outerStyle));
            props.outerStyle = undefined;
        }

        return <div className="textInputHolder" style={outerStyle} >
            <input type="checkbox" {...this.props} />
        </ div>

    }
}

export default CheckBox;