import moment from 'moment';

class Utils {

    static formatDateTime(theDateTime, format) {
        if (!theDateTime) return ''

        if (Number(format) == 2) {
            //short date
            var newDate = moment(theDateTime).format("DD/MM/YYYY");
            return newDate;
        }
        else {
            //date time
            var theFormat = "DD/MM/YYYY HH:mm";
            return moment(theDateTime).format(theFormat);
        }
    }

    static formatNumber(theNumber, decimals) {
        if (isNaN(theNumber)) return theNumber
        var finalNo = Number.parseFloat(theNumber).toFixed(decimals)
        if (isNaN(finalNo)) finalNo = 0;
        return finalNo;
    }
    static monthName(monthNo, short) {
        var monthTxt = "";
        switch (Number(monthNo)) {
            case 1:
                monthTxt = "January"
                break;
            case 2:
                monthTxt = "February"
                break;
            case 3:
                monthTxt = "March"
                break;
            case 4:
                monthTxt = "April"
                break;
            case 5:
                monthTxt = "May"
                break;
            case 6:
                monthTxt = "June"
                break;
            case 7:
                monthTxt = "July"
                break;
            case 8:
                monthTxt = "August"
                break;
            case 9:
                monthTxt = "September"
                break;
            case 10:
                monthTxt = "October"
                break;
            case 11:
                monthTxt = "November"
                break;
            case 12:
                monthTxt = "December"
                break;
        }
        if (short) {
            monthTxt = monthTxt.substring(0, 3);
        }
        return monthTxt;
    }


}

export default Utils;