import React from 'react'
import { Container, Row, Col, Visible, Hidden } from 'react-grid-system';
import UILIB from '../../common-objects/Lib'
import bgImage from '../../assetts/images/frontPage/bg6.jpg'
import Axios from '../../includes/axios';


export default class Account extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pageLoaded: true,
            firstName: "",
            lastName: ""
        }
    }

    componentDidMount() {
        const { firstName, lastName, emailAddress, account } = this.props;
      
        this.setState({
          firstName: firstName || account.firstName || "",
          lastName: lastName || account.lastName || "",
          emailAddress: emailAddress || account.emailAddress || "",
          newPassword: "",
          newPassword2: "",
          firstNameError: "",
          lastNameError: "",
          emailAddressError: "",
          newPasswordError: "",
          newPassword2Error: "",
          savingDetails: false,
          savingPassword: false,
          passwordLength: false,
          passwordLowercase: false,
          passwordUppercase: false,
          passwordNumber: false,
          passwordSpecial: false
        });
      
        this.saveDetails = this.saveDetails.bind(this);
        this.updateValue = this.updateValue.bind(this);
        this.savePassword = this.savePassword.bind(this);
      }

      updateValue(event) {
        const { name, value } = event.target;
      
        this.setState({ [name]: value, saveProfile: false });
      
        if (name === "newPassword") {
          this.setState({
            passwordLength: value.length >= 8 && value.length <= 16,
            passwordLowercase: /[a-z]/.test(value),
            passwordUppercase: /[A-Z]/.test(value),
            passwordNumber: /\d/.test(value),
            passwordSpecial: /[@#$%^&*\-_+=[\]{}|\\:',?/`~"();!]/.test(value) && /^[A-Za-z\d@#$%^&*\-_+=[\]{}|\\:',?/`~"();!]*$/.test(value)
          });
        }
      }

    saveDetails() {
        var firstNameError = "";
        var lastNameError = "";
        var emailAddressError = "";
        var errored = false;
        if (this.state.firstName.length < 2) {
            errored = true;
            firstNameError = "Enter a first name"
        }
        if (this.state.lastName.length < 2) {
            errored = true;
            lastNameError = "Enter a last name"
        }
        if (this.state.emailAddress.length < 5 || this.state.emailAddress.indexOf("@") < 0 || this.state.emailAddress.indexOf(".") < 0) {
            errored = true;
            emailAddressError = "Enter a valid email address"
        }

        this.setState({ firstNameError, lastNameError, emailAddressError })

        if (errored) return;

        var self = this;
        Axios.put('/customerPerson', {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            emailAddress: this.state.emailAddress
        }).then(data => {
            self.setState({ savingDetails: true })
        })

    }

    savePassword() {
        var newPasswordError = "";
        var newPassword2Error = "";
        var errored = false;

        const password = this.state.newPassword;
        const errors = [];

        // Check password length
        if (password.length < 8 || password.length > 16) {
            errors.push("Password must be between 8 and 16 characters.");
        }

        // Check for lowercase letters
        if (!/[a-z]/.test(password)) {
            errors.push("Password must include at least one lowercase letter.");
        }

        // Check for uppercase letters
        if (!/[A-Z]/.test(password)) {
            errors.push("Password must include at least one uppercase letter.");
        }

        // Check for digits
        if (!/\d/.test(password)) {
            errors.push("Password must include at least one digit.");
        }

        // Check for special characters
        if (!/[^A-Za-z0-9]/.test(password)) {
            errors.push("Password must include at least one special character.");
        }

        if (errors.length > 0) {
            newPasswordError = errors.join(" ");
            errored = true;
        }

        if (this.state.newPassword != this.state.newPassword2) {
            errored = true;
            newPasswordError = "Passwords do not match"
            newPassword2Error = "Passwords do not match"
        }

        this.setState({ newPasswordError, newPassword2Error })

        if (errored) return;

        var self = this;
        Axios.put('/customerPerson/password', {
            password: this.state.newPassword,
            password2: this.state.newPassword2
        }).then(data => {
            self.setState({ savingPassword: true })
        }).catch(err => {
            var newPasswordError = "";
            console.log(err.data.errMessage)
            if (err && err.data && err.data.errMessage) {
                newPasswordError = err.data.errMessage;
            } else {
                newPasswordError = "There was a problem, please try again";
            }
            self.setState({ newPasswordError, newPassword2Error })
        })
    }

    render() {

        if (!this.state.pageLoaded) return <Row><Col xs={12} align="center"><UILIB.LoadingIcon /></Col></Row>

        return <div>
            <Row className="mar-t15">
                <Col xs={12} sm={12} md={12} lg={12} style={{ marginBottom: "20px" }}>
                    <div className="overViewHeader" style={{ backgroundImage: `url(${bgImage})` }}>
                        <div className="overViewHeaderText">
                            <h1>My Profile</h1>
                            <p style={{ fontSize: "12px" }}>Update your own login settings using the following options</p>
                        </div>
                    </div>
                </Col>
                <Col xs={12} sm={6}>
                    <div className="paper">
                        <h2 className="mar-b5">Your Details</h2>
                        <p style={{ marginTop: "0px" }}>Update your account details below.</p>
                        <Row>
                            <Col xs={12}>
                                <label>Your Name</label>
                                <Row className="mar-b0">
                                    <Col xs={12} sm={6}>
                                        <UILIB.Input type="text" name="firstName" placeholder="First Name" value={this.state.firstName} error={this.state.firstNameError} onChange={this.updateValue} />
                                    </Col>
                                    <Col xs={12} sm={6}>
                                        <UILIB.Input type="text" name="lastName" placeholder="Last Name" value={this.state.lastName} error={this.state.lastNameError} onChange={this.updateValue} />
                                    </Col>
                                </Row>
                            </Col>
                            {Boolean(this.props.account.autoGenerated && this.props.account.autoGenerated == "1") && <Col xs={12}>
                                <label>Your Email Address</label>
                                <div style={{ marginTop: "10px", marginBottom: "15px" }}>Please contact <a href="mailto:customercare@agilico.co.uk">customercare@agilico.co.uk</a> in order to change your email address.</div>
                            </Col>
                            }
                            {Boolean(!this.props.account.autoGenerated || this.props.account.autoGenerated != "1") && <Col xs={12}>
                                <label>Your Email Address</label>
                                <UILIB.Input type="text" name="emailAddress" placeholder="email@address.com" value={this.state.emailAddress} error={this.state.emailAddressError} onChange={this.updateValue} />
                            </Col>
                            }
                            <Col xs={12}>
                                <UILIB.Button value="Save" onClick={this.saveDetails} /> {this.state.savingDetails && <span style={{ color: "green", marginLeft: "10px" }}>Saved</span>}
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col xs={12} sm={6}>
                    <div className="paper">
                        <h2 className="mar-b5">Your Password</h2>
                        <p style={{ marginTop: "0px" }}>Change your password using the following options.</p>
                        <Row>
                            <Col xs={12}>
                                <label>New Password</label>
                                <UILIB.Input type="password" name="newPassword" placeholder="New Password" value={this.state.newPassword} error={this.state.newPasswordError} onChange={this.updateValue} />
                            </Col>
                            <Col xs={12}>
                                <label>Confirm New Password</label>
                                <UILIB.Input type="password" name="newPassword2" placeholder="New Password" value={this.state.newPassword2} error={this.state.newPassword2Error} onChange={this.updateValue} />
                            </Col>
                            <Col xs={12}>
                                <UILIB.Button value="Save" onClick={this.savePassword} /> {this.state.savingPassword && <span style={{ color: "green", marginLeft: "10px" }}>Saved</span>}
                            </Col>
                        </Row>

                        <Row>
                            {/* Add list of password requirments and tick them as their state is true */}
                            <Col xs={12}>
                                <h3>Password Requirements</h3>
                                <ul>
                                    <li style={{ color: this.state.passwordUppercase ? "green" : "red" }}>At least one uppercase letter</li>
                                    <li style={{ color: this.state.passwordLowercase ? "green" : "red" }}>At least one lowercase letter</li>
                                    <li style={{ color: this.state.passwordNumber ? "green" : "red" }}>At least one numeric character</li>
                                    <li style={{ color: this.state.passwordSpecial ? "green" : "red" }}>At least one valid special character</li>
                                    <li style={{ color: this.state.passwordLength ? "green" : "red" }}>8 to 16 characters long</li>
                                </ul>
                            </Col>
                        </Row>
                    </div>
                </Col>


            </Row>

        </div>
    }
}
