import React from 'react'
import UILIB from '../../Lib';


export default class DataTable1 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingData: 1,
            tableData: [],
            tableDataFixed: [],
            firstRun: true
        }
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps != this.props || this.state != nextState) {
            return true;
        }
        return false;
    }
    componentWillUpdate(nextProps) {
        if (nextProps.loadingData != this.props.loadingData) {
            this.setState({ loadingData: 0 }, () => {
            });
        }
    }


    componentDidMount() {
        this.setState({ loadingData: this.props.loadingData });
    }



    openPop() {

    }

    render() {

        var thisLoadingHolder = [];
        for (var x = 0; x < this.props.pageSize; x++) {
            thisLoadingHolder.push(<tr key={"blank-" + x} >
                <td width="20%" style={{ padding: "10px" }}><div className="blankCellPadder" /></td>
                <td width="20%" style={{ padding: "10px" }}><div className="blankCellPadder" /></td>
                <td width="20%" style={{ padding: "10px" }}><div className="blankCellPadder" /></td>
                <td width="20%" style={{ padding: "10px" }}><div className="blankCellPadder" /></td>
                <td width="20%" style={{ padding: "10px" }}><div className="blankCellPadder" /></td>
            </tr>);
        }


        //Normal Code
        if (this.state.loadingData === 1) {

            return <table width="100%" className="table-cont2 with-lines">
                <tbody>
                    {thisLoadingHolder}
                </tbody>
            </table>

        }
        else {

            if (Object.keys(this.props.tableData).length == 0) {
                return <table className="table-cont2 with-lines" cellPadding="0" cellSpacing="0">
                    <tbody>
                        <tr>
                            <td><div style={{ textAlign: "center", padding: "20px" }}>{this.props.noResultsTxt}</div></td>
                        </tr>
                    </tbody>
                </table>
            } else {

                var sortDir = this.props.sortedDirection;
                var sortCol = this.props.sortedColumn;
                var header = Object.keys(this.props.tableData[0]).map(key => {
                    var obj = this.props.tableData[0][key];
                    obj.key = key;
                    return obj;
                })

                if (header[0].order !== undefined) header.sort((a, b) => a.order - b.order);
                return (
                    <div style={{ position: "relative" }}>
                        <div className="tableHolder js-scrollable" onScroll={this.handleScroll} >
                            <table className="table-cont2 with-lines" cellPadding="0" cellSpacing="0">
                                <thead>
                                    <tr>
                                        {header.map((thisObj, index) => {
                                            if (thisObj.hasOwnProperty("headerValue") === false) {
                                                return
                                            }

                                            var classes = "";
                                            if (thisObj.isCheckBox) {
                                                classes += " checkboxFix";
                                            }

                                            var theStyle = {};
                                            if (thisObj.align) {
                                                theStyle.textAlign = thisObj.align;
                                            }
                                            if (thisObj.width) {
                                                theStyle.width = thisObj.width + "px";
                                                theStyle.minWidth = thisObj.width + "px";
                                            }
                                            var isOrderedBy = false;

                                            var finalSortDir = "";
                                            if (sortCol === thisObj.key) {
                                                {
                                                    if (sortDir == "asc") {
                                                        finalSortDir = "desc";
                                                    } else {
                                                        finalSortDir = "asc";
                                                    }
                                                }
                                            }

                                            var thisClick = () => {
                                                this.props.dataUpdater(null, finalSortDir, thisObj.key, null);
                                            };



                                            var thisInnerHTML = <span>{thisObj.headerValue} </span>;

                                            if (thisObj.orderBy == true) {
                                                thisInnerHTML = <UILIB.TableSortLabel direction={finalSortDir}
                                                    active={sortCol === thisObj.key}
                                                    onClick={thisClick}>{thisObj.headerValue}
                                                </UILIB.TableSortLabel>
                                            }


                                            return <th key={"headCol" + index} name={"headCol" + index} style={theStyle} className={classes}>{thisInnerHTML}</th>
                                        })
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.props.tableData.map((theRow, rowIndex) => {
                                        return <tr key={"row-" + rowIndex}>{header.map((theCol, colIndex) => {
                                            var thisObj = theRow[theCol.key];
                                            if (thisObj.hasOwnProperty("headerValue") === false) {
                                                return null
                                            }
                                            var theStyle = {};
                                            var tdClasses = "";
                                            var divClasses = "";
                                            if (thisObj.align) {
                                                theStyle.textAlign = thisObj.align;
                                            }
                                            if (thisObj.fixed) {
                                                divClasses = "fixedCol";
                                                tdClasses = "tdFixedCol";
                                            }

                                            if (thisObj.isCheckBox) {
                                                tdClasses += " checkboxFix"
                                            }

                                            return <td className={tdClasses} key={"col-" + rowIndex + "-" + colIndex} name={"col-" + rowIndex + "-" + colIndex} style={theStyle}>
                                                <div className={divClasses}>{thisObj.value}</div>
                                            </td>
                                        })}
                                        </tr>
                                    })}

                                </tbody>
                            </table>
                        </div>
                    </div>
                )

            }
        }

    }
}
