import React from 'react'
import LoadingIcon1 from '../../assetts/images/loadingIcons/Loading_icon.gif'

export default class LoadingIcons extends React.Component {

    render() {
        var holderClasses = "";
        var theClass = "loadingIcon";
        if (this.props.noClass === "true") {
            theClass = "";
        }
        if (this.props.className) theClass += " " + this.props.className

        return <div style={{ marginBottom: "30px" }}>
            <img src={LoadingIcon1} className={theClass} width={this.props.width} style={this.props.style} />
            <div style={{ textAlign: "center" }}>Loading Data can take up to 30 seconds</div>
        </div>

    }


}
