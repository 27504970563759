import React from 'react'
import { Container, Row, Col, Visible, Hidden } from 'react-grid-system';
import Axios from 'axios';
import UILIB from '../../common-objects/Lib'
import Connections from '../../config/connections'
import queryString from 'query-string';

export default class ResetPassword extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            newPassword1: "",
            newPassword2: "",
            newFirstName: "",
            newLastName: "",
            newPassword1Error: "",
            newPassword2Error: "",
            newFirstNameError: "",
            newLastNameError: "",
            submitting: false,
            allDone: false,
            invalidToken: 0,
            userId: -1,
            firstName: "",
            authCode: "",
            uid: 0,
            needsName: false,
            passwordLength: false,
            passwordLowercase: false,
            passwordUppercase: false,
            passwordNumber: false,
            passwordSpecial: false
        }

        this.updateInput = this.updateInput.bind(this);
        this.goReset = this.goReset.bind(this);
        this.checkAuthToken = this.checkAuthToken.bind(this);
    }

    componentDidMount() {
        var params = queryString.parse(this.props.location.search)
        if (!params.authcode || params.authcode.length < 5 || !params.uid) {
            this.setState({ invalidToken: true })
        } else {
            this.setState({ authCode: params.authcode, uid: params.uid }, this.checkAuthToken);
        }
    }
    checkAuthToken() {
        Axios.post(Connections.apiUrl + "/passwordremindercheck", { authToken: this.state.authCode, uid: this.state.uid }).then((response) => {

            var needsName = false;
            if (response.data.firstName === "Awaiting Registration") {
                needsName = true;
            }

            this.setState({ invalidToken: 2, userId: response.data.userId, firstName: response.data.firstName, needsName: needsName })

        }).catch(err => {
            this.setState({ invalidToken: 1 })
        })
    }

    updateInput(event) {
        this.setState({ [event.target.name]: event.target.value, newPassword1Error: "", newPassword2Error: "" })
        if (event.target.name == "newPassword1") {

            const allowedSpecialChars =/[@#$%^&*\-_+=[\]{}|\\:',?/`~"();!]/;

            this.setState({
                passwordLength: event.target.value.length >= 8 && event.target.value.length <= 16,
                passwordLowercase: /[a-z]/.test(event.target.value),
                passwordUppercase: /[A-Z]/.test(event.target.value),
                passwordNumber: /\d/.test(event.target.value),
                passwordSpecial: allowedSpecialChars.test(event.target.value) && /^[A-Za-z\d@#$%^&*\-_+=[\]{}|\\:',?/`~"();!]*$/.test(event.target.value)
            })
        }
    }

    goReset() {
        var self = this;
        var errored = false;
        var newPassword1Error = "";
        var newPassword2Error = "";
        var newFirstNameError = "";
        var newLastNameError = "";
        if (this.state.newPassword1.length < 6) {
            errored = true;
            newPassword1Error = "Please enter a longer password";
        }
        if (!errored && this.state.newPassword1 != this.state.newPassword2) {
            errored = true;
            newPassword2Error = "Passwords must match"
        }

        const password = this.state.newPassword1;
        const errors = [];

        // Check password length
        if (password.length < 8 || password.length > 16) {
            errors.push("Password must be between 8 and 16 characters.");
        }

        // Check for lowercase letters
        if (!/[a-z]/.test(password)) {
            errors.push("Password must include at least one lowercase letter.");
        }

        // Check for uppercase letters
        if (!/[A-Z]/.test(password)) {
            errors.push("Password must include at least one uppercase letter.");
        }

        // Check for digits
        if (!/\d/.test(password)) {
            errors.push("Password must include at least one digit.");
        }

        // Check for special characters
        if (!/[^A-Za-z0-9]/.test(password)) {
            errors.push("Password must include at least one special character.");
        }

        if (errors.length > 0) {
            newPassword1Error = errors.join(" ");
            errored = true;
        }



        if (this.state.needsName) {
            if (this.state.newFirstName.length < 2) {
                errored = true;
                newFirstNameError = "Please enter first name";
            }
            if (this.state.newLastName.length < 2) {
                errored = true;
                newLastNameError = "Please enter last name";
            }
        }

        this.setState({ newPassword1Error, newPassword2Error, newFirstNameError, newLastNameError, submitting: errored ? false : true });
        if (errored) return;

        Axios.post(Connections.apiUrl + "/passwordupdate",
            {
                authCode: this.state.authCode,
                password: this.state.newPassword1,
                firstName: this.state.newFirstName,
                lastName: this.state.newLastName
            }
        ).then((response) => {

            self.setState({ submitting: false, allDone: true })

        }).catch(err => {
            var newPassword1Error = "There was a problem, please try again";
            if (err && err.response && err.response.data && err.response.data.errMessage) {
                newPassword1Error = err.response.data.errMessage;
            }
            self.setState({ newPassword1Error, submitting: false, allDone: false })
        })
    }

    render() {

        if (this.state.submitting || this.state.invalidToken == 0) return <div style={{ textAlign: "center" }}><UILIB.LoadingIcon iconType="2" /></div>

        if (this.state.invalidToken == 1) {
            return <div>
                <h3>Oops</h3>
                <p className="mar-b20">This activate link has been previously used or has expired.</p>
                <UILIB.Button value="Agilico Portal Log In" onClick={() => { this.props.history.push("/") }} />
            </div>
        }

        if (this.state.allDone) {
            return <div>
                <h3>Password has been Reset</h3>
                <p className="mar-b20">Thanks for resetting your password. You can now proceed to login...</p>
                <UILIB.Button value="Login" onClick={() => { this.props.history.push("/") }} />
            </div>
        }

        var firstName = this.state.needsName ? "there" : this.state.firstName;
        return <div>
            {(this.state.needsName) && <h3>Welcome to your Portal</h3>}
            {(!this.state.needsName) && <h3>Reset your Password</h3>}
            {(this.state.needsName) && <p className="mar-b20">Hi there. Welcome to your Print Portal. Please enter new password along with your name in the boxes below to get started.</p>}
            {(!this.state.needsName) && <p className="mar-b20">Hi {firstName}. Please enter a new password in the boxes below in order to reset your password.</p>}

            <div className="mar-b20">

                {(this.state.needsName) && <Row className="mar-b0">
                    <Col xs={12} sm={6}>
                        <UILIB.Input name="newFirstName" type="text" placeholder="First Name" value={this.state.newFirstName} onChange={this.updateInput} error={this.state.newFirstNameError} />
                    </Col>
                    <Col xs={12} sm={6}>
                        <UILIB.Input name="newLastName" type="text" placeholder="Last Name" value={this.state.newLastName} onChange={this.updateInput} error={this.state.newLastNameError} />
                    </Col>
                </Row>
                }
                <UILIB.Input name="newPassword1" type="password" placeholder="Enter a new Password" value={this.state.newPassword1} onChange={this.updateInput} error={this.state.newPassword1Error} />
                <UILIB.Input name="newPassword2" type="password" placeholder="Enter a new Password" value={this.state.newPassword2} onChange={this.updateInput} error={this.state.newPassword2Error} />

                <Row className="mar-b20">
                    <Col xs={12} sm={6}>
                        <UILIB.Button value="Update Password" onClick={this.goReset} />
                    </Col>
                    <Col xs={12} sm={6} align="right" justify="center">
                    </Col>
                </Row>
                <Row>
                    {/* Add list of password requirments and tick them as their state is true */}
                    <Col xs={12}>
                        <h3>Password Requirements</h3>
                        <ul>
                            <li style={{ color: this.state.passwordUppercase ? "green" : "red" }}>At least one uppercase letter</li>
                            <li style={{ color: this.state.passwordLowercase ? "green" : "red" }}>At least one lowercase letter</li>
                            <li style={{ color: this.state.passwordNumber ? "green" : "red" }}>At least one numeric character</li>
                            <li style={{ color: this.state.passwordSpecial ? "green" : "red" }}>At least one valid special character</li>
                            <li style={{ color: this.state.passwordLength ? "green" : "red" }}>8 to 16 characters long</li>
                        </ul>
                    </Col>
                </Row>
            </div>



        </div >
    }
}
