import React from 'react'

import { Container, Row, Col, Visible, Hidden } from 'react-grid-system';
import UILIB from '../../../common-objects/Lib'
import bgImage from '../../../assetts/images/frontPage/bg6.jpg'

import ConsumablesIndex from './consumables/consumables'
import ConsumablesOnOrderIndex from './consumablesOnOrder/consumablesOnOrder'
import OpenServiceCallsIndex from './openServiceCalls/openServiceCalls'
import ServiceCallHistoryIndex from './serviceCallHistory/serviceCallHistory'
import InvoicesIndex from './invoices/invoices'

export default class Dashboard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currTab: "Consumables",
        }
        this.changeTab = this.changeTab.bind(this);

    }

    componentWillMount() {
    }



    changeTab(newTab) {
        this.setState({ currTab: newTab })
    }

    render() {

        var dateNow = new Date();
        return <div>
            <Row className="mar-t15">
                <Col xs={12} sm={12} md={12} lg={12} style={{ marginBottom: "20px" }}>
                    <div className="overViewHeader" style={{ backgroundImage: `url(${bgImage})` }}>
                        <div className="overViewHeaderText">
                            <h1>Service History - Last 90 Days</h1>
                            View your Agilico Group service history, find out when engineers came, when consumables were ordered and much more here...
                    </div>
                    </div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} className="mar-b20">
                    <Row>

                        <Col>
                            <div className={"dashboard_headerNav" + (this.state.currTab == "Consumables" ? " active" : "")} onClick={() => { this.changeTab("Consumables") }}>Consumables</div>
                        </Col>
                        <Col>
                            <div className={"dashboard_headerNav" + (this.state.currTab == "ConsumablesOnOrder" ? " active" : "")} onClick={() => { this.changeTab("ConsumablesOnOrder") }}>Consumables On Order</div>
                        </Col>
                        <Col>
                            <div className={"dashboard_headerNav" + (this.state.currTab == "OpenServiceCalls" ? " active" : "")} onClick={() => { this.changeTab("OpenServiceCalls") }}>Open Service Calls</div>
                        </Col>
                        <Col>
                            <div className={"dashboard_headerNav" + (this.state.currTab == "ServiceCallHistory" ? " active" : "")} onClick={() => { this.changeTab("ServiceCallHistory") }}>Service Call History</div>
                        </Col>
                        <Col>
                            <div className={"dashboard_headerNav" + (this.state.currTab == "Invoices" ? " active" : "")} onClick={() => { this.changeTab("Invoices") }}>Invoices</div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            {this.state.currTab == "Consumables" && <ConsumablesIndex {...this.props} />}
            {this.state.currTab == "ConsumablesOnOrder" && <ConsumablesOnOrderIndex {...this.props} />}
            {this.state.currTab == "OpenServiceCalls" && <OpenServiceCallsIndex {...this.props} />}
            {this.state.currTab == "ServiceCallHistory" && <ServiceCallHistoryIndex {...this.props} />}
            {this.state.currTab == "Invoices" && <InvoicesIndex {...this.props} />}
        </div>
    }
}
