import React from 'react'
import { Container, Row, Col, Visible, Hidden } from 'react-grid-system';
import UILIB from '../../common-objects/Lib'
import axiosInstance from '../../includes/axios';
import ViewContent from '../guides/viewContent'
export default class KpaxTraining extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pageLoaded: false,
            content: [],
            viewingContent: false,
            viewingContentItem: {}
        }
        this.getContent = this.getContent.bind(this);
        this.viewContent = this.viewContent.bind(this);
        this.closeViewContent = this.closeViewContent.bind(this);
    }

    componentDidMount() {
        this.getContent();
    }

    getContent() {
        var self = this;
        axiosInstance.get(`/content?manufacturer=kpax`).then((_res) => {

            self.setState({
                pageLoaded: true,
                content: _res.data.content
            })
        }).catch(err => {
            this.setState({ pageLoaded: true })
        })
    }
    viewContent(contentItem) {
        this.setState({ viewingContentItem: contentItem, viewingContent: true })
    }
    closeViewContent() {
        this.setState({ viewingContent: false, viewingContentItem: {} });
    }

    render() {

        if (!this.state.pageLoaded) return <div style={{ textAlign: "center" }}><UILIB.LoadingIcon /></div>

        if (this.state.viewingContent) {
            return <Row className="mar-t15">
                <Col xs={12} sm={12} md={12} lg={12}>
                    <ViewContent contentItem={this.state.viewingContentItem} closeViewContent={this.closeViewContent} />
                </Col>
            </Row>
        }

        return <Row>
            <Col xs={12} sm={4}>
                <div className="paper primary">
                    <h2>Training and Guides</h2>
                    <p>Need to know a bit more about Kpax?</p>
                    <p>Please use the guides and training documents located to the right to find out about Kpax, how it works, and more.</p>
                </div>
            </Col>
            {(!this.state.content.length) && <Col xs={12} sm={12} md={12} lg={12} style={{ textAlign: "center" }}>
                No Guides Found. Please check later.
            </Col>}
            <Col xs={12} sm={8}>
                {(this.state.content.length > 0) && <Row>

                    {this.state.content.map((guide, index) => {
                        var thisIcon = "icon-document2";
                        var typeBg = "orange";
                        switch (guide.fileType) {
                            case "video":
                                thisIcon = "icon-file-video";
                                break;
                            case "image":
                                thisIcon = "icon-file-image";
                                break;
                        }
                        switch (guide.category) {
                            case "copying":
                                typeBg = "orange"
                                break;
                            case "printing":
                                typeBg = "purple"
                                break;
                            case "scanning":
                                typeBg = "green"
                                break;
                            case "manuals":
                                typeBg = "blue"
                                break;
                        }


                        return <Col xs={12} sm={12} md={4} lg={3} key={"sGuide_" + index} onClick={() => { this.changePage(guide.link) }}>
                            <div className="paper guides_standardGuide">
                                <div className={"icon " + thisIcon} />
                                <div style={{ flex: "1" }}>
                                    <h2>{guide.shortDesc}</h2>
                                    <div className="guide_type" style={{ backgroundColor: typeBg }}>{guide.category}</div>
                                    <div className="guide_content">{guide.longDesc}</div>
                                    <div className="guide_button"><UILIB.Button className="button-small outlined" value={"View " + guide.fileType} onClick={() => { this.viewContent(guide) }} /></div>
                                </div>
                            </div>
                        </Col>
                    })}
                </Row>}

                {(!this.state.content.length) && <Row>
                    <Col xs={12} style={{ textAlign: "center" }}>
                        <h1>No Documents Found</h1>
                        <p>Please change your search filters</p>
                    </Col>
                </Row>}
            </Col>

        </Row>

    }
}
