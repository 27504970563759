import React from 'react'

export default class Link extends React.Component {
    constructor(props) {
        super(props);

        this.hRef = props.hRef;
        this.target = (props.target) ? props.target : "_blank";
        this.rel = (props.rel) ? props.rel : "noreferrer";
        this.ul = (props.ul) ? props.ul : true;
        this.children = props.children;
    }

    componentDidMount() { 

    }

    render() {
        return <a href={this.hRef} rel={this.rel} target={this.target}>
          {this.ul && <u>{this.children}</u>}
          {!this.ul && this.children}
        </a>
    }
}
