import React from 'react'
import { Container, Row, Col, Visible, Hidden } from 'react-grid-system';
import UILIB from '../../common-objects/Lib'
import Axios from '../../includes/axios';

export default class NewsWidget extends React.Component {
    constructor(props) {
        super(props);

        let timer;
        this.state = {
            contentItems: [],
            currentItem: 0
        }
        this.getContent = this.getContent.bind(this);
        this.changeItem = this.changeItem.bind(this);
    }

    componentWillMount() {
        this.getContent();
    }

    componentWillUnmount() {
        clearTimeout(this.timer)
    }

    getContent() {
        var self = this;
        Axios.get(`/utilities/rssFeed`).then((_res) => {

            self.setState({
                pageLoaded: true,
                contentItems: _res.data
            }, () => {
                this.changeItem(0, true);
            })
        }).catch((err) => {
            this.setState({ pageLoaded: true })
        })
    }

    changeItem(index, increment) {

        clearTimeout(this.timer);
        this.setState({ currentItem: index }, () => {
            if (increment) {
                index += 1;
                if (index > this.state.contentItems.length - 1) index = 0
            }
            this.timer = setTimeout(() => {
                this.changeItem(index, true)
            }, 10000)
        })
    }

    render() {
        if (!this.state.pageLoaded) {
            return <div style={{ textAlign: "center" }}><UILIB.LoadingIcon /></div>
        }

        if (!this.state.contentItems.length) return <div></div>

        var contentItem = this.state.contentItems[this.state.currentItem];
        var imageUrl = "";
        if (contentItem.content && contentItem.content.indexOf("src=") > -1) {
            imageUrl = contentItem.content.split(/src\=\"/g)[1].split(/\"/g)[0];
        }
        var contentSnippet = contentItem.contentSnippet;
        if (contentItem.contentSnippet && contentItem.contentSnippet.indexOf(".") > -1) {
            contentSnippet = contentSnippet.split(".")[0];
        }
        if (contentSnippet.length > 100) contentSnippet = contentSnippet.substr(0, 100) + "..."
        return <div>
            <div style={{ borderRadius: "5px", overflow: "hidden", backgroundColor: "white", marginBottom: "20px", transition: "all .25s ease-in-out" }} >
                <table width="100%" cellPadding="0" cellSpacing="0">
                    <tr>
                        <td align="left" valign="top" style={{ height: "200px", width: "200px", backgroundImage: `url(${imageUrl})`, backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center center" }}></td>
                        <td align="left" valign="top" style={{ padding: "15px" }}>
                            <a href={contentItem.link} target="_blank" style={{ fontSize: "18px" }}>{contentItem.title}</a>
                            <div style={{ fontSize: "14px", marginTop: "10px", marginBottom: "10px" }}>{contentSnippet}</div>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                <div>
                                    <UILIB.Button value="View Article" onClick={() => { window.open(contentItem.link, '_blank') }} />
                                </div>
                                <div>
                                    {this.state.contentItems.map((cItem, index) => {
                                        var bgColor = "#CCCCCC";
                                        if (index == this.state.currentItem) bgColor = "#000033";
                                        return <div style={{ width: "10px", height: "10px", backgroundColor: bgColor, borderRadius: "100px", display: "inline-block", marginRight: "5px", cursor: "pointer" }} onClick={() => { this.changeItem(index, false) }} />
                                    })}
                                </div>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
        </div >
    }
}
