export function getFormData(props) {
  return {
    pageLoaded: false,
    viewAllToggle: true,
    submittingForm: false,
    submittedForm: false,
    subSites: [],
    subsiteFilter: -1,
    equipment: [],
    formData: {
        yourName: props.account.FirstName && props.account.LastName ? props.account.FirstName + " " + props.account.LastName : "",
        emailAddress: props.account.EmailAddress ? props.account.EmailAddress : "",
        contactNumber: props.account.phone ? props.account.phone : "",
        companyName: props.currCustomer.Name,
        department: "",
        poNumber: "",
        notes: "",
        urgent: false,
        consumablesOrdered: {},
        urgentDeliveryType: 0,
        urgentDeliverySignature: ""
    },
    errorData: {
        yourName: "",
        emailAddress: "",
        contactNumber: "",
        companyName: "",
        department: "",
        poNumber: "",
        notes: "",
        generalError: "",
        urgentDeliverySignature: ""
    },
    drawer: "",
    staplePrices: []
  }
}