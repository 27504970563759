import React from 'react';
import { Container, Row, Col, Visible, Hidden } from 'react-grid-system';
import WhiteLogo from '../../assetts/images/logos/logoWhite.png'
import UILIB from '../../common-objects/Lib'
import SessionHelpers from '../../session/index'
import Axios from '../../includes/axios';
import Config from '../../config/connections'

import LogoCondor from '../../assetts/images/logos/condor.png'
import LogoCslGroup from '../../assetts/images/logos/cslGroup.png'
import LogoDmc from '../../assetts/images/logos/dmc.png'
import LogoLeemic from '../../assetts/images/logos/leemic.png'
import LogoSystemsTech from '../../assetts/images/logos/systemsTechnology.png'
import LogoUnitedCarton from '../../assetts/images/logos/unitedCarlton.png'
import CbcLogo from '../../assetts/images/logos/cbcLogo.png'

export default class Drawer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        }

    }

    componentDidMount() {

    }

    render() {


        return <div className="drawer" style={this.props.style}>
            <div alt="Close" title="Close" className="close icon-cross" style={{ fontSize: "20px", marginBottom: "15px", cursor: "pointer" }} onClick={this.props.close} />
            <h1>{this.props.title}</h1>
            {this.props.children}
        </div >
    }
}